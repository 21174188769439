import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { Auth } from 'aws-amplify';
// import Audit from './Audit.Reporting';
// import Leaderboard from './Leaderboard.Reporting';
// import TrackPopularity from './Presentations/Track.Reporting';
// import PresentationPopularity from './Presentations/Presentation.Reporting';
// import TopicPopularity from './Posters/Topic.Reporting';
// import PosterPopularity from './Posters/Poster.Reporting';
// import ExhibitorPopularity from './Exhibitors/Exhibitor.Reporting';
// import Accounts from './Accounts';
import TopMetrics from '../Leads/TopMetrics';
// import Rooms from './Rooms';
// import Location from './Location';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
    // padding: theme.spacing(2),
  },
}));

export default function Reporting() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.center} variant="h4">
              Reporting
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TopMetrics />
          </Grid>
          {/* <Grid item xs={12} sm={4} >
            <Accounts
              loading={loading}
              report={roomView}
            />
        </Grid>
        <Grid item xs={12} sm={4} >
            <Rooms
              loading={loading}
              report={roomView}
            />
        </Grid>
        <Grid item xs={12} sm={4} >
            <Location
              loading={loading}
              report={wholeReport}
            />
        </Grid> */}
        </Grid>
      </Paper>

      {/* <Grid item xs={12} sm={12} >
            <Audit times={times} timesDaily={timesDaily} report={wholeReport} />
        </Grid> */}

      {/* 

        <Paper className={classes.paper}>
        <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography className={classes.center} variant='h5' >
              Presentations
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TrackPopularity
              loading={loading}
              report={presentations}
              />
        </Grid>
        <Grid item xs={12} sm={6}>
            <PresentationPopularity
              loading={loading}
              report={presentations}
            />
        </Grid>
        </Grid>
        </Paper> */}

      {/* 
        <Paper className={classes.paper}>
        <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography className={classes.center} variant='h5' >
              Posters
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TopicPopularity
              loading={loading}
              report={posters}
              />
        </Grid>
        <Grid item xs={12} sm={6}>
            <PosterPopularity
              loading={loading}
              report={posters}
            />
        </Grid>
        </Grid>
        </Paper> */}
    </div>
  );
}
