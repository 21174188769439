import { getUser, setConversation, storeParticipant } from '../Reducers/twilio';

export default function twilioParticipantUpdated(participant) {
  return (dispatch, getState) => {
    const twilioUser = getUser(getState());
    const conversation =
      getState().twilio.conversations?.[participant.conversation.sid];
    if (conversation) {
      const newConversation = { ...conversation };
      const newParticipants = { ...conversation.participants };
      newParticipants[participant.identity] = storeParticipant(participant);
      newConversation.participants = newParticipants;
      if (twilioUser.identity === participant.identity) {
        newConversation.myAttributes = { ...participant.attributes };
      }
      dispatch(setConversation(newConversation));
    }
  };
}
