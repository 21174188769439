import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import {
  setOpenConversation,
  setOpenProfile,
  setEngagement,
  setChatWindow,
} from '../Store/Reducers/twilio';
import ChatProfile from './ChatProfile';
import { colors, eventId } from '../config';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import { useGetAttendeeDirectoryQuery } from '../Services/queryApi';

// Redux

const useStyles = (theme) => ({});

const ChatDirectory = ({ page }) => {
  const dispatch = useDispatch();

  const event = useSelector((state) => state.event.event);
  let syncServiceSid = event.twilio_sync_sid;

  const me = useSelector((state) => state.user.user);

  const userTwilio = useSelector((state) => state.twilio.user?.client);

  const { data, isFetching, isLoading } = useGetAttendeeDirectoryQuery({
    eventId: eventId,
    syncServiceSid: syncServiceSid,
  });

  const [text, setText] = useState('');
  const [directory, setDirectory] = useState([]);

  useEffect(() => {
    if (data) {
      let attendeesList = [];

      data.filter(function (item) {
        var i = attendeesList.findIndex((x) => x.email === item.email);
        if (i <= -1) {
          if (item.email !== userTwilio?.identity) {
            attendeesList.push(item);
          }
        }
        return null;
      });
      attendeesList.sort((a, b) =>
        a.last_name > b.last_name
          ? 1
          : b.last_name > a.last_name
          ? -1
          : a.first_name > b.first_name
          ? 1
          : b.first_name > a.first_name
          ? -1
          : 0
      );
      setDirectory(attendeesList);
    }
  }, [data]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const data = await Auth.currentSession();

  //       const response = await axios({
  //         method: 'get',
  //         url: `/show-attendees/directory?exhibitionId=${eventId}&syncServiceSid=${syncServiceSid}`,
  //         headers: { idtoken: data.idToken.jwtToken },
  //       });
  //       console.log(
  //         '🚀 ~ file: ChatDirectory.js ~ line 39 ~ response',
  //         response
  //       );
  //       if (response.data.error) {
  //         throw new Error(response.data.error);
  //       }
  //       if (!response.data) {
  //         throw new Error('no directory');
  //       }

  //       let attendeesList = [];
  //       response.data.filter(function (item) {
  //         var i = attendeesList.findIndex((x) => x.email === item.email);
  //         if (i <= -1) {
  //           if (item.email !== userTwilio?.identity) {
  //             attendeesList.push(item);
  //           }
  //         }
  //         return null;
  //       });
  //       attendeesList.sort((a, b) =>
  //         a.last_name > b.last_name
  //           ? 1
  //           : b.last_name > a.last_name
  //           ? -1
  //           : a.first_name > b.first_name
  //           ? 1
  //           : b.first_name > a.first_name
  //           ? -1
  //           : 0
  //       );
  //       setDirectory(attendeesList);
  //       // console.log(
  //       //   '🚀 ~ file: ChatDirectory.js ~ line 41 ~ response',
  //       //   response
  //       // );

  //       // console.log(
  //       //   '🚀 ~ file: ChatDirectory.js ~ line 25 ~ ChatDirectory ~ directory',
  //       //   directory
  //       // );
  //     } catch (e) {
  //       console.log('🚀 ~ file: ChatDirectory.js ~ line 36 ~ e', e);
  //     }
  //   })();
  // }, []);

  const lowerCaseText = text?.toLowerCase().trim();
  const filteredDirectory = text
    ? directory?.filter(
        (contact) =>
          me.email !== contact.email &&
          (contact?.first_name?.toLowerCase()?.indexOf(lowerCaseText) !== -1 ||
            contact?.last_name?.toLowerCase()?.indexOf(lowerCaseText) !== -1)
        //  || contact.email.toLowerCase().indexOf(lowerCaseText) !== -1
      )
    : directory?.filter((contact) => me.email !== contact.email);

  const bgColor = (attendee) => {
    // let roles = user?.attributes.roles?.split(',')?.at(-1).toLowerCase();
    let roles = attendee?.roles?.toLowerCase();

    if (roles) {
      if (roles.includes('attendee')) {
        return '#c0f0c5';
      } else {
        return '#f5afae';
      }
    }
    return '';
  };

  if (!userTwilio) {
    return null;
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          position: 'relative',
          padding: '10px 0px ',
          backgroundColor: 'white',
          boxShadow: '0 4px 15px -7px #000',
          // zIndex: 100000,
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              // tell the client the user is typing
              // it is debounded within the client
              // conversation.typing();
              // updateText(e.target.value);
              setText(e.target.value);
            }}
            value={text}
            style={{
              width: '90%',
              padding: 10,
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              fontSize: 16,
              border: '1px solid grey',
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: '100%',
          height: '88%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {filteredDirectory.map((attendee) => {
          // return <div>{attendee.email}</div>;
          const isMe =
            attendee && me && getTwilioUserIdentity(me) === attendee.identity;

          const handleOpenProfile = async (e) => {
            if (e) e.stopPropagation();
            try {
              // await dispatch(
              //   twilioCreateJoinOneOnOneConversation(attendee.email)
              // );

              // dispatch(setChatWindow(true));
              if (!isMe) {
                dispatch(setOpenConversation({ page, conversation: null }));
                dispatch(
                  setOpenProfile({
                    identity: attendee.identity,
                    attributes: attendee,
                  })
                );
                dispatch(setEngagement('profile'));
                dispatch(setChatWindow(true));
              }
            } catch (err) {
              console.log(
                '🚀 ~ file: ChatDirectory.js ~ line 107 ~ onClick={ ~ err',
                err
              );
            }
          };

          return (
            //   <ChatProfileRow
            //
            //     identity={attendee.email}
            //     page={'chat'}
            //   />

            <div
              key={`directory_${attendee.attendee_id}`}
              style={{
                ...styles.row,
                backgroundColor: bgColor(attendee),
              }}
              onClick={handleOpenProfile}
            >
              <ChatProfile
                identity={attendee.email}
                size={50}
                photo_url={attendee.photo_url}
                directory
                onClick={handleOpenProfile}
              />
              {/* <a title={onHover}> */}
              <a
                title={
                  attendee?.first_name +
                  ' ' +
                  attendee?.last_name +
                  '\n' +
                  attendee?.title +
                  '\n' +
                  attendee?.account_name
                }
                style={{ padding: 10, textAlign: 'left', width: '85%' }}
              >
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: colors.main,
                  }}
                >
                  {attendee?.first_name} {attendee?.last_name}
                </div>
                {/* <a title={user?.attributes.title}> */}
                <div
                  style={{
                    fontSize: 14,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    lineHeight: '15px',
                  }}
                >
                  {attendee?.title}
                </div>

                {/* {user &&
            user?.attributes.title !== '' &&
            user?.attributes.account_name !== '' &&
            ', '}{' '}
          {user?.attributes.account_name} */}
                {/* </a> */}
                {/* <a title={user?.attributes.account_name}> */}
                <div
                  style={{
                    fontSize: 14,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    lineHeight: '15px',
                  }}
                >
                  {attendee?.account_name}
                </div>
                {/* </a> */}
                {/* </div> */}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },

  circle: {
    borderRadius: 50,
    width: 50,
    height: 50,
    backgroundColor: '#b0b0b0',
    // border: '2px solid pink',
  },
};

export default ChatDirectory;
