import { setTriggerNotification } from '../Reducers/twilio';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
/**
 *
 * @param {*} message - twilio message
 * @returns
 */
export default function twilioShouldPlayNotification(message) {
  return async (dispatch, getState) => {
    // Search Conversations to see if we already have a one on one conversation going

    const user = getState().user.user;
    const openConversations = getState().twilio.openConversations || {};
    const userIdentity = getTwilioUserIdentity(user);
    const lastReadMessageIndex =
      message.conversation.lastReadMessageIndex === null
        ? -1
        : message.conversation.lastReadMessageIndex;
    const messageIndex = message.index;

    // Don't play sounds for my messages
    if (message.author === userIdentity) return false;
    // Don't play notification if the user has this chat open
    for (let i = 0; i < Object.keys(openConversations).length; i++) {
      if (
        openConversations[Object.keys(openConversations)[i]].sid ===
        message.conversation.sid
      )
        return false;
    }

    // Check this user's notification state
    let notify = 'FIRST'; // default
    message.conversation.participants.forEach((participant) => {
      if (
        participant.identity === userIdentity &&
        participant.attributes?.notify
      ) {
        notify = participant.attributes?.notify;
      }
    });

    const unreadCount = messageIndex - lastReadMessageIndex;

    if (notify === 'ALL') {
      dispatch(setTriggerNotification());
      return true;
    }
    if (notify === 'FIRST' && unreadCount === 1) {
      dispatch(setTriggerNotification());
      return true;
    }

    return false;
  };
}
