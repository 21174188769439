import { SyncClient, SyncDocument } from 'twilio-sync';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import { getSyncClient } from '../Reducers/twilio';

export default function twilioAddHereNow(uniqueName) {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    const syncClient: SyncClient = getSyncClient(getState());
    const hereNowClient: SyncDocument = await syncClient.document(
      `HERE_NOW_${uniqueName}`
    );

    const hereNow = hereNowClient.data || {};

    if (user?.email && hereNowClient) {
      const newHereNow = { ...hereNow };
      // newHereNowRoom[user.email] = true; // possibly to save data space
      newHereNow[user.email] = getTwilioUserIdentity(user);
      console.log('ADDED newHereNowRoom', newHereNow);
      await hereNowClient.set(newHereNow);
    }
  };
}
