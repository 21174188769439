import React from 'react';
import { ListItem } from '@material-ui/core';
import moment from 'moment';
import ChatProfile from '../Components/ChatProfile';

class ChatItem extends React.Component {
  render() {
    const { message, email, time } = this.props;

    const isOwnMessage = message.author === email;
    if (!isOwnMessage) {
      return (
        <ListItem
          style={{
            ...styles.listItem(isOwnMessage),
            marginTop: time ? 0 : -12,
            marginLeft: -5,
          }}
        >
          <a id={message.sid} />
          <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            {time ? (
              <div style={styles.author}>
                {/* {message.author} */}
                <ChatProfile identity={message.author} size={20} />
              </div>
            ) : (
              <div style={{ width: 25 }}>
                {/* {message.author} */}
                {/* <ChatProfile identity={message.author} size={20} /> */}
              </div>
            )}

            <div style={styles.container(isOwnMessage)}>
              {time && (
                <div style={styles.timestamp(isOwnMessage)}>
                  {/* {new Date(message.dateCreated.toISOString()).toLocaleString()} */}
                  {moment(message.dateCreated).format('h:mm a')}
                </div>
              )}

              {message.body}
            </div>
          </div>
        </ListItem>
      );
    }
    return (
      <ListItem
        style={{ ...styles.listItem(isOwnMessage), marginTop: time ? 0 : -12 }}
      >
        <a id={message.sid} />

        {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
        {!isOwnMessage && time && (
          <div style={styles.author}>
            {/* {message.author} */}
            <ChatProfile identity={message.author} size={20} />
          </div>
        )}

        <div style={styles.container(isOwnMessage)}>
          {time && (
            <div style={styles.timestamp(isOwnMessage)}>
              {/* {new Date(message.dateCreated.toISOString()).toLocaleString()} */}
              {moment(message.dateCreated).format('h:mm a')}
            </div>
          )}

          {message.body}
        </div>
        {/* </div> */}
      </ListItem>
    );
  }
}

const styles = {
  listItem: (isOwnMessage) => ({
    flexDirection: 'column',
    alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
  }),
  container: (isOwnMessage) => ({
    width: '60%',
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    // color: 'white',
    fontSize: 16,
    backgroundColor: isOwnMessage ? '#DDE8FF' : '#FFFFFF',
    textAlign: isOwnMessage ? 'right' : 'left',
    overflowWrap: 'break-word',
    // flexWrap: 'wrap',
    // display: 'flex',
  }),
  author: { fontSize: 10, color: 'gray' },
  timestamp: (isOwnMessage) => ({
    fontSize: 8,
    color: 'gray',
    textAlign: isOwnMessage ? 'right' : 'left',
    paddingTop: 4,
  }),
};

export default ChatItem;
