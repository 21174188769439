import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import enterListener from '../../Utilities/listeners';
import { aiEnabled } from '../../config';

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    overflow: 'hidden',
  },
  filter: {
    margin: 0,
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  radio: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
});

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: this.props.filterString,
      filterCategory: this.props.filterCategory,
      filtering: this.props.filtering,
      filterAISuggested: this.props.aiFiltering,
    };
  }

  listener = (event) => {
    enterListener(event, this.handleFilterClick);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.listener);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listener);
  }

  onFilterInputChange = (event) => {
    if (event.target.value) {
      this.setState({ filterString: event.target.value });
    } else {
      this.setState({ filterString: '' });
    }
  };

  handleFilterCategorySelect = (event) => {
    this.setState({
      filterCategory: event.target.value,
    });
  };

  handleFilterClick = () => {
    this.props.handleFilterClick(
      this.state.filterString,
      this.state.filterCategory
    );
    this.setState({
      filtering: true,
    });
  };

  handleRemoveFilter = () => {
    this.props.handleRemoveFilter();
    this.setState({
      filterString: '',
      filtering: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        xs={12}
        className={classes.root}
      >
        <Grid item sm={12} md={6}>
          {!this.props.aiFiltering ? (
            <>
              <TextField
                id="filterInput"
                className={classes.filter}
                placeholder="Filter"
                value={this.state.filterString ? this.state.filterString : ''}
                margin="normal"
                variant="outlined"
                color="primary"
                onChange={this.onFilterInputChange}
              />
              <FormControl className={classes.radio}>
                <InputLabel htmlFor="filter-subject-native-helper">
                  Category
                </InputLabel>
                <NativeSelect
                  value={this.state.filterCategory}
                  onChange={this.handleFilterCategorySelect}
                  inputProps={{
                    name: 'filter-subject',
                    id: 'filled-filter-subject-native-helper',
                  }}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={'account_name'}>Company</option>
                  <option value={'main_text'}>Overview</option>
                  <option value={'linked_in_tags'}>Keyword</option>
                </NativeSelect>
              </FormControl>
              {this.state.filtering ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={this.handleRemoveFilter}
                  className={classes.button}
                >
                  Remove Filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  onClick={this.handleFilterClick}
                  className={classes.button}
                >
                  Filter
                </Button>
              )}
            </>
          ) : null}
        </Grid>
        {this.props.aiSuggestedExhibits.length ? (
          <Grid item align="right" sm={12} md={3}>
            <Button onClick={this.props.handleAIFilter}>
              All
              <Switch
                checked={this.props.aiFiltering}
                onChange={this.props.handleAIFilter}
                color="primary"
                name="listFormatSwitch"
                inputProps={{ 'aria-label': 'listFormatSwitch' }}
              />
              AI Suggested
            </Button>
          </Grid>
        ) : null}
        <Grid
          item
          align="right"
          md={this.props.aiSuggestedExhibits.length ? 3 : 6}
        >
          <Hidden smDown>
            <Button onClick={this.props.handleGridListToggle}>
              Grid
              <Switch
                checked={this.props.listFormat}
                onChange={this.props.handleGridListToggle}
                color="primary"
                name="listFormatSwitch"
                inputProps={{ 'aria-label': 'listFormatSwitch' }}
              />
              List
            </Button>
          </Hidden>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Filter);
