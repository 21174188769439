import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { useEffect, useState } from 'react';

import { jobTitles } from './LookUpFields';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const JobTitleLookupField = ({
  value = '',
  label,
  onSelect,
  error,
  errorMessage,
  required,
}) => {
  const classes = useStyles();
  const [data] = useState(jobTitles);
  const [term, setTerm] = useState(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onSelect(term);
  }, [term]);

  return (
    <Autocomplete
      className={classes.root}
      autoComplete={true}
      id="data"
      freeSolo={true}
      getOptionLabel={(data) => data}
      options={data}
      inputValue={term}
      open={open}
      onOpen={() => {
        if (term) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(e, value) => {
        // setValue(name, value);
        setTerm(value);

        // only open when inputValue is not empty after the user typed something
        if (!value) {
          setOpen(false);
        }
      }}
      noOptionsText={'No Data'}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="filled"
            label={label}
            error={error}
            helperText={errorMessage}
            required={required}
          />
        );
      }}
    />
  );
};

export default JobTitleLookupField;
