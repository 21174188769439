import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatProfile from './ChatProfile';
import {
  setOpenConversation,
  setOpenProfile,
  setEngagement,
} from '../Store/Reducers/twilio';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import chat from '../Assets/Icons/chatRed.png';
import { colors } from '../config';
import ActiveUsers from './ActiveUsers';

// export const getUnreadCount = (lastMessageIndex, lastReadMessageIndex) =>
//   // There is 1 message, but the user hasn't read any
//   lastMessageIndex >= 0 && lastReadMessageIndex === null
//     ? lastMessageIndex + 1
//     : lastMessageIndex - lastReadMessageIndex;

export default function SidebarConversation({
  conversation,
  page,
  isDirectMessage,
  onClick,
}) {
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 22 ~ SidebarConversation ~ conversation',
  //   conversation
  // );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const openConversation = useSelector(
    (state) => state.twilio.openConversations?.[page]
  );
  const messages = useSelector(
    (state) => state.twilio.conversationsMessages?.[conversation?.client?.sid]
  );

  const engagement = useSelector((state) => state.twilio?.engagement);
  const users = useSelector((state) => state.twilio?.users);

  const isSelected =
    openConversation !== null &&
    engagement === 'chat' &&
    openConversation?.conversation?.sid === conversation?.client?.sid;

  // const unreadCount =
  //   useSelector((state) => state.twilio?.unread?.[conversation.sid]) || 0;
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 48 ~ unreadCount',
  //   unreadCount
  // );
  const unreadCount = conversation.unread;

  // const notification = useSelector((state) => state.twilio?.notification);
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 50 ~ notification',
  //   notification
  // );

  // const client = useSelector(getConversationsClient);
  // let scrollDiv = useRef(null);

  // const [loading, setLoading] = useState(false);
  // const [unreadCount, setUnreadCount] = useState(null);
  // const [lastMessage, setLastMessage] = useState(null);
  // const [lastMessageText, setLastMessageText] = useState('');

  const bgColor = (identity) => {
    // let roles = user?.attributes.roles?.split(',')?.at(-1).toLowerCase();
    let roles = users?.[identity]?.attributes.roles?.toLowerCase();
    if (roles) {
      if (roles.includes('attendee')) {
        return '#c0f0c5';
      } else {
        return '#f5afae';
      }
    }
    return '';
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       // const conversation = await client.getConversationBySid(conversationSid);
  //       // console.log('existing conversation', conversationSid, conversation);
  //       // joinChannel(conversation);
  //       // setConversation(conversation);
  //       setUniqueName(conversation.client.uniqueName);
  //       // console.log(
  //       //   '🚀 ~ file: SidebarConversation.js ~ line 144 ~ conversation.client',
  //       //   conversation.client
  //       // );
  //       setFriendlyName(conversation.client.friendlyName);
  //       // console.log(
  //       //   '🚀 ~ file: SidebarConversation.js ~ line 149 ~ conversation.client.friendlyName)',
  //       //   conversation.client.friendlyName
  //       // );
  //       try {
  //         // loading message fails after creating a new conversation so wrapping this in try/catch
  //         const loadedMessages = await conversation.client.getMessages();
  //         // console.log(
  //         //   '🚀 ~ file: SidebarConversation.js ~ line 60 ~ loadedMessages',
  //         //   loadedMessages
  //         // );
  //         setMessages(loadedMessages.items);
  //         setLastMessageText(loadedMessages.items.pop()?.body || '');
  //       } catch (e) {
  //         console.log('loadedMessages error: ', e);
  //       }
  //       setLastMessage(conversation?.client?.lastMessage);
  //       setLastReadMessageIndex(conversation.client?.lastReadMessageIndex);
  //       // setUnreadCount(
  //       //   getUnreadCount(
  //       //     conversation?.lastMessage?.index,
  //       //     conversation?.lastReadMessageIndex
  //       //   )
  //       // );
  //       // dispatch(
  //       //   setUnread({
  //       //     sid: conversation.sid,
  //       //     count: getUnreadCount(
  //       //       conversation?.lastMessage?.index,
  //       //       conversation?.lastReadMessageIndex
  //       //     ),
  //       //   })
  //       // );
  //       // toggle(
  //       //   getUnreadCount(
  //       //     conversation?.lastMessage?.index,
  //       //     conversation?.lastReadMessageIndex
  //       //   )
  //       // );
  //       conversation.client.on('participantJoined', function (participant) {
  //         setParticipantsAttributes((participantsAttributes) => ({
  //           ...participantsAttributes,
  //           [participant.identity]: participant.attributes,
  //         }));
  //       });
  //       conversation.client.on(
  //         'participantUpdated',
  //         function ({ participant, updateReasons }) {
  //           setParticipantsAttributes((participantsAttributes) => ({
  //             ...participantsAttributes,
  //             [participant.identity]: participant.attributes,
  //           }));
  //         }
  //       );
  //       conversation.client.on('participantLeft', function (participant) {
  //         console.log(`removing ${participant.identity}`);
  //         setParticipantsAttributes((participantsAttributes) => {
  //           const newParticipantsAttributes = { ...participantsAttributes };
  //           console.log(
  //             '🚀 ~ file: SidebarConversation.js ~ line 96 ~ setParticipantsAttributes ~ newParticipantsAttributes',
  //             newParticipantsAttributes
  //           );
  //           delete newParticipantsAttributes[participant.identity];
  //           console.log(
  //             '🚀 ~ file: SidebarConversation.js ~ line 98 ~ setParticipantsAttributes ~ newParticipantsAttributes',
  //             newParticipantsAttributes
  //           );
  //           return newParticipantsAttributes;
  //           // return {
  //           //   ...participantsAttributes,
  //           //   [participant.identity]: undefined,
  //           // };
  //         });
  //       });

  //       const loadedParticipants = await conversation.client.getParticipants();
  //       const newParticipantsAttributes = {};
  //       loadedParticipants.forEach((participant) => {
  //         newParticipantsAttributes[participant.identity] =
  //           participant.attributes;
  //       });
  //       setParticipantsAttributes(newParticipantsAttributes);

  //       conversation.client.on('updated', async function (data) {
  //         // console.log(
  //         //   '🚀 ~ file: SidebarConversation.js ~ line 229 ~ data',
  //         //   data
  //         // );
  //         // console.log('line 101: updated', data.updateReasons);
  //         if (data.updateReasons.includes('friendlyName')) {
  //           setFriendlyName(conversation.client.friendlyName);
  //         }
  //         if (data.updateReasons.includes('lastMessage')) {
  //           const newMessages = await conversation.client.getMessages();
  //           // console.log(
  //           //   '🚀 ~ file: SidebarConversation.js ~ line 119 ~ newMessages',
  //           //   newMessages
  //           // );

  //           setMessages(newMessages.items);
  //           setLastMessageText(newMessages.items.pop()?.body || '');
  //           // console.log('lastMessage', data.conversation?.lastMessage);
  //           // console.log(
  //           //   'lastMessage.index',
  //           //   data.conversation?.lastMessage?.index
  //           // );
  //           // console.log(
  //           //   'lastReadMessageIndex',
  //           //   data.conversation?.lastReadMessageIndex
  //           // );
  //           setLastMessage(data.conversation?.lastMessage);
  //           // setUnreadCount(
  //           //   getUnreadCount(
  //           //     data.conversation?.lastMessage?.index,
  //           //     data.conversation?.lastReadMessageIndex
  //           //   )
  //           // );
  //           // dispatch(
  //           //   setUnread({
  //           //     sid: conversation.client.sid,
  //           //     count: getUnreadCount(
  //           //       conversation?.lastMessage?.index,
  //           //       conversation?.lastReadMessageIndex
  //           //     ),
  //           //   })
  //           // );
  //           // toggle(
  //           //   getUnreadCount(
  //           //     conversation?.lastMessage?.index,
  //           //     conversation?.lastReadMessageIndex
  //           //   )
  //           // );

  //           // console.log(
  //           //   '🚀 ~ file: SidebarConversation.js ~ line 241 ~ unreadCount',
  //           //   unreadCount
  //           // );
  //         }
  //         if (data.updateReasons.includes('lastReadMessageIndex')) {
  //           setLastReadMessageIndex(data.conversation.lastReadMessageIndex);
  //           // setUnreadCount(
  //           //   getUnreadCount(
  //           //     data.conversation?.lastMessage?.index,
  //           //     data.conversation?.lastReadMessageIndex
  //           //   )
  //           // );
  //           // dispatch(
  //           //   setUnread({
  //           //     sid: conversation.sid,
  //           //     count: getUnreadCount(
  //           //       conversation?.lastMessage?.index,
  //           //       conversation?.lastReadMessageIndex
  //           //     ),
  //           //   })
  //           // );
  //           // toggle(
  //           //   getUnreadCount(
  //           //     conversation?.lastMessage?.index,
  //           //     conversation?.lastReadMessageIndex
  //           //   )
  //           // );

  //           // console.log(
  //           //   '🚀 ~ file: SidebarConversation.js ~ line 265 ~ unreadCount',
  //           //   unreadCount
  //           // );
  //         }
  //         // toggle();
  //       });

  //       conversation.client.on('removed', function (data) {
  //         console.log('removed', data);
  //       });
  //     } catch (err) {
  //       console.log(
  //         '🚀 ~ file: SidebarConversation.client.js ~ line 42 ~ err',
  //         err.message
  //       );

  //     }
  //   })();
  // }, [conversation]);

  const isGroup = conversation?.client?.uniqueName?.startsWith('GROUP_');
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 306 ~ conversation?.uniqueName',
  //   conversation?.client?.uniqueName
  // );
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 306 ~ isGroup',
  //   isGroup
  // );

  if (
    // If this is not a group conversation
    !isGroup &&
    // and there are no messages
    !conversation.client.lastMessage &&
    // and I am not the owner
    getTwilioUserIdentity(user) !== conversation.client.createdBy
  ) {
    return null;
  }

  const lastMessage =
    messages && messages.messages[messages.messages.length - 1];
  if (!isGroup) {
    return (
      <div
        style={{
          ...styles.row,
          // borderBottom: '0px solid',
          // paddingLeft: 15,
          // paddingRight: 15,
          // backgroundColor: isSelected
          //   ? colors.disabledGray
          //   : 'rgba(255,255,255,0)',

          // borderLeft: isSelected ? `3px solid #122857` : `0px solid white`,
        }}
      >
        {Object.keys(conversation.participants)?.map(
          (identity) => {
            if (getTwilioUserIdentity(user) !== identity) {
              // console.log(
              //   '🚀 ~ file: SidebarConversation.js ~ line 469 ~ identity',
              //   identity
              // );
              return (
                <div
                  key={`sidebar_conversation_${identity}`}
                  style={{
                    // ...styles.row,
                    width: '100%',

                    // backgroundColor: bgColor(identity),
                    backgroundColor: bgColor(identity),
                  }}
                >
                  <div
                    key={`sidebar_conversation_${identity}`}
                    style={{
                      ...styles.row,
                      borderBottom: '0px solid',
                      paddingLeft: 15,
                      paddingRight: 15,
                      // backgroundColor: bgColor(identity),
                      backgroundColor: isSelected
                        ? `rgba(224, 224, 224, 0.7)`
                        : 'rgba(255,255,255,0)',

                      borderLeft: isSelected
                        ? `3px solid #122857`
                        : `3px solid ${bgColor(identity)}`,
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <ChatProfile
                        identity={identity}
                        size={50}
                        photo_url={undefined}
                        directory={undefined}
                        onClick={undefined}
                      />
                    </div>
                    <div
                      style={{
                        ...styles.row,
                        cursor: 'pointer',
                        borderBottom: '0px solid',
                        width: '85%',
                      }}
                      // onClick={onClick}
                      onClick={() => {
                        console.log('conversation clicked');
                        dispatch(setOpenProfile(null));
                        dispatch(setEngagement('chat'));
                        dispatch(
                          setOpenConversation({
                            page,
                            conversation: { sid: conversation.client.sid },
                          })
                        );
                        // dispatch(setEngagement('chat'));
                      }}

                      // onClick={() => dispatch(setOpenConversation(conversation))}
                    >
                      <div style={{ padding: 10, flex: 1, width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <a
                            title={
                              users?.[identity]?.attributes.first_name +
                              ' ' +
                              users?.[identity]?.attributes.last_name
                            }
                            style={{
                              fontSize: 18,
                              overflow: 'hidden',
                              maxHeight: 60,
                              maxWidth: '100%',
                              fontWeight: unreadCount ? 'bold' : 'normal',
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              flexDirection: 'row',
                              color: colors.main,
                              // border: '1px solid red',
                            }}
                            // onClick={() => dispatch(setOpenConversation(conversation))}
                          >
                            {users?.[identity]?.attributes.first_name}{' '}
                            {users?.[identity]?.attributes.last_name}
                            {/* {contact?.[identity]?.friendlyName} */}
                            {/* {conversation?.friendlyName} */}
                          </a>
                          <div>
                            {unreadCount > 0 && (
                              <div
                                style={{ position: 'relative', marginLeft: 5 }}
                              >
                                <img
                                  src={chat}
                                  style={{ width: 20, height: 20 }}
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: 20,
                                    height: 15,

                                    // border: '1px solid red',
                                    color: 'white',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: 12,
                                  }}
                                >
                                  {unreadCount}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            maxHeight: 60,
                            maxWidth: 120,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            // flexDirection: 'row',
                            overflow: 'hidden',
                          }}
                        >
                          {lastMessage?.body}
                        </div>
                      </div>
                      {/* <div>
                      {lastMessage?.dateCreated &&
                        moment(lastMessage.dateCreated).format('h:mm a')}
                    </div> */}
                    </div>
                  </div>
                </div>
              );
            }
          }
          // <div>Particients: {participant?.attributes.photo_url}</div>
        )}
      </div>
    );
  }

  const isFollowing = conversation.myAttributes?.following;
  // console.log(
  //   '🚀 ~ file: SidebarConversation.js ~ line 487 ~ conversation',
  //   conversation
  // );
  // Don't show group conversations in the sidebar if we are not following
  if (!isFollowing) return null;
  return (
    <div
      style={{
        ...styles.row,
        // borderBottom: '0px solid',
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: isSelected
          ? colors.disabledGray
          : 'rgba(255,255,255,0)',

        borderLeft: isSelected ? `3px solid #122857` : `3px solid white`,
      }}
    >
      {/* <div style={styles.row}> */}
      <div style={{ position: 'relative' }}>
        <div
          style={{
            ...styles.circle,
            borderRadius: isDirectMessage ? 50 : 10,
            backgroundImage: `url(${conversation?.attributes?.logo})`,
            backgroundSize: conversation.attributes.abstractId
              ? 'cover'
              : 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundPosition: 'center',
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          {/* <img src={conversation?.attributes?.logo} style={styles.circle} /> */}
        </div>
        {unreadCount > 0 && (
          <div
            style={{
              minWidth: 17,
              height: 17,
              backgroundColor: 'red',
              borderRadius: 20,
              position: 'absolute',
              right: -5,
              bottom: -2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
              color: 'white',
              fontSize: 12,
              fontWeight: 'bold',
              border: '1px solid #fff',
            }}
          >
            {unreadCount}
          </div>
        )}
      </div>
      {/* <div style={styles.circle}></div> */}
      <div
        style={{
          ...styles.row,
          cursor: 'pointer',
          borderBottom: '0px solid #fff',
          width: '85%',
        }}
        // onClick={onClick}
        onClick={() => {
          try {
            // dispatch(twilioLoadOpenConversation(conversation.client.sid));

            dispatch(setOpenProfile(null));
            dispatch(setEngagement('chat'));
            dispatch(
              setOpenConversation({
                page,
                conversation: { sid: conversation.client.sid },
              })
            );
            // dispatch(setEngagement('chat'));
          } catch (err) {
            console.log('error loading conversation', err.message);
          }
        }}

        // onClick={() => dispatch(setOpenConversation(conversation))}
      >
        <a style={{ flex: 1, width: '100%' }}>
          <div style={{ padding: 10, flex: 1, width: '100%' }}>
            {/* <Tooltip title={'conversation?.friendlyName'}> */}
            <div
              title={conversation?.friendlyName}
              style={{
                fontSize: 18,
                overflow: 'hidden',
                maxHeight: 60,
                maxWidth: '100%',
                fontWeight: unreadCount ? 'bold' : 'normal',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                // border: '1px solid red',
              }}
              // onClick={(e) => {
              //   e.preventDefault();
              //   try {
              //   dispatch(twilioLoadOpenConversation(conversation.client.sid));
              //   } catch (err) {
              //     console.log(`load conversation error ${err.message}`)
              //   }

              //   // dispatch(setOpenConversation(conversation));
              // }}
            >
              {conversation?.friendlyName}
            </div>
            {/* </Tooltip> */}
            {/* <div>
          {unreadCount ? `(${unreadCount} unread messages)` : `all caught up`}
        </div> */}
            {/* {participants?.map((participant) => (
          <ChatProfile identity={participant.identity} />
          // <div>Particients: {participant?.attributes.photo_url}</div>
        ))} */}
            {isDirectMessage ? (
              <div>{lastMessage?.body}</div>
            ) : (
              // <div>{Object.keys(participantsAttributes).length} members</div>
              <ActiveUsers
                uniqueName={conversation?.client?.uniqueName}
                embedded={false}
                onClick={undefined}
              />
            )}
            {/* <div>{participants.length} members</div>
        <div>{lastMessageText}</div> */}
          </div>
        </a>
        {/* <div>
          {lastMessage?.dateCreated &&
            moment(lastMessage.dateCreated).format('h:mm a')}
        </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}
// console.log('🚀 ~ file: SidebarConversation.js ~ line 652 ~ isGroup', isGroup);
// console.log('🚀 ~ file: SidebarConversation.js ~ line 652 ~ isGroup', isGroup);

const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    justifyContent: 'space-between',
  },

  circle: {
    borderRadius: 10,
    width: 50,
    height: 50,
    backgroundColor: '#b0b0b0',
  },
};
