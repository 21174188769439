function EmailTemplate(body) {
  return `<html><img src="${body.banner}" alt="banner" width="800"><h3>${body.title}</h3><p>${body.body}</p></html>`;
}

function FormatObject(object) {
  let output = '';
  for (const [key, value] of Object.entries(object)) {
    let finalValue = value;
    if (typeof value == 'boolean') {
      if (value === true) {
        finalValue = 'Yes';
      } else {
        finalValue = 'No';
      }
    }
    if (key === 'keywords') {
      if (value.length > 0) {
        let items = value
          .split(',')
          .map((item, index) => `<li key={${index}}>${item}</li>`);
        let itemsAsString = '';
        items.forEach((item) => (itemsAsString = itemsAsString + item));
        finalValue = `<ul>${itemsAsString}</ul>`;
      } else {
        finalValue = '';
      }
    }
    if (Array.isArray(value)) {
      if (value.length > 0) {
        let items = value.map(
          (item, index) => `<li key={${index}}>${item}</li>`
        );
        let itemsAsString = '';
        items.forEach((item) => (itemsAsString = itemsAsString + item));
        finalValue = `<ul>${itemsAsString}</ul>`;
      } else {
        finalValue = '';
      }
    }
    if (key.includes('_')) {
      output =
        output + `<p><b>${snakeCaseToNormalText(key)}:</b> ${finalValue}</p>`;
    } else {
      output =
        output +
        `<p><b>${capitalizeFirstLetter(
          camelCaseToNormalText(key)
        )}:</b> ${finalValue}</p>`;
    }
  }
  return output;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function camelCaseToNormalText(string) {
  return string.replace(/([A-Z])/g, ' $1');
}

function snakeCaseToNormalText(string) {
  return string
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_;
}

export { EmailTemplate, FormatObject };
