import { useDispatch, useSelector } from 'react-redux';
import isOnline from '../Utilities/isOnline';
import trackAction from '../Store/Actions/tracker';
import { getTaskQueue, resetTaskQueue } from '../Store/Reducers/taskQueue';
import { useEffect } from 'react';
import actionScanAttendee from '../Store/Actions/actionScanAttendee';

const TaskQueueSubmit = () => {
  const dispatch = useDispatch();
  const online = useSelector(isOnline);
  const queue = useSelector(getTaskQueue);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const taskQueueAction = async () => {
      if (online) {
        for (let i = 0; i < queue.length; i++) {
          await sleep(Math.floor(Math.random() * 1000));

          if (queue[i].task === 'trackAction') {
            await dispatch(trackAction(queue[i].payload.data));
          } else if (queue[i].task === 'Add Attendee') {
            await dispatch(actionScanAttendee(queue[i].payload.badgeKey));
          }
        }
        dispatch(resetTaskQueue([]));
      }
    };
    taskQueueAction();
  }, [online]);

  return null;
};

export default TaskQueueSubmit;
