import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import RootScreen from '../Containers/RootScreen';
import { useGetEventQuery } from '../Services/queryApi';
import { eventId } from '../config';

export default function EventLoader() {
  const { data, error, isLoading } = useGetEventQuery(eventId);

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Loading Event Error: {error?.error}</div>;
  if (!data) return <div>Loading Event Error: unknown error</div>;

  return <RootScreen />;
}
