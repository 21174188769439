import { createSlice } from '@reduxjs/toolkit';

interface UserStoreProps {
  user: any;
  loginRedirect: any;
  anonymous?: { firstName: string; lastName: string; email: string };
  clearCacheAfter?: number;
}

const initialState: UserStoreProps = {
  user: null,
  loginRedirect: null,
  anonymous: undefined,
  clearCacheAfter: undefined,
};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthenticatedUserState: (state, action) => {
      state.user = action.payload;
      state.anonymous = undefined;
    },
    setLoginRedirect: (state, action) => {
      state.loginRedirect = action.payload;
    },
    resetAuthenticatedUser: (state, action) => {
      state.user = null;
      state.anonymous = undefined;
    },
    setAnonymous: (state, action) => {
      state.anonymous = action.payload;
    },
    setClearCacheAfter: (state, action) => {
      state.clearCacheAfter = action.payload;
    },
  },
});

// Selectors
export const getUser = (state) => state.user.user;
export const getLoginRedirect = (state) => state.user.loginRedirect;
export const getAnonymous = (state) => state.user.anonymous;
export const getClearCacheAfter = (state) => state.user.clearCacheAfter;

// each case under reducers becomes an action
export const {
  setAuthenticatedUserState,
  setLoginRedirect,
  resetAuthenticatedUser,
  setAnonymous,
  setClearCacheAfter,
} = userSlice.actions;

export default userSlice.reducer;
