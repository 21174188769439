/**
 *
 *
 * @param {*} room
 *
 *  posters have abstract_id as unique id
 *  exhibits have exhibition_id as unique id
 *
 * @returns the twilio conversation unique name
 */
const getTwilioGroupConversationUniqueName = (room) => {
  if (room.exhibition_id) return `GROUP_${room.show_id}_${room.exhibition_id}`;
  if (room.abstract_id) return `GROUP_${room.show_id}_${room.abstract_id}`;
  throw new Error('could not get twilio group unique id');
};

export default getTwilioGroupConversationUniqueName;
