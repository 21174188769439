import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import { eventId } from '../config';
import isOnline from '../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';

// redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optOut: false,
    };
  }

  componentDidMount() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            if (response.data.length) {
              if (!response.data[0].opted_out) {
                this.setState({
                  optOut: false,
                });
              } else {
                this.setState({
                  optOut: true,
                });
              }
            } else {
              this.setState({
                optOut: false,
              });
            }
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not process your submission. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`);
        }
      });
  }

  optOut() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            opted_out: true,
          },
        })
          .then((response) => {
            this.setState({
              optOut: true,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not process your submission. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`);
        }
      });
  }

  optIn() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            opted_out: false,
          },
        })
          .then((response) => {
            this.setState({
              optOut: false,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not process your submission. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`);
        }
      });
  }

  handleChange = () => {
    if (this.props.online) {
      if (this.state.optOut) {
        this.optIn();
      } else {
        this.optOut();
      }
    }
  };

  render() {
    const { classes } = this.props;
    const online = this.props.online;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/settings') {
        pageTitle = route.title;
      }
    });
    return (
      <Grid container layout={'row'} spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              align="center"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Exhibitor Lead Opt-Out
              </Typography>
              <Typography variant="body1" align="justify">
                <b>If your Event has Supplier Exhibitors:</b>
                <br />
                Supplier Exhibitors and Sponsors help us make events successful!
                Exhibitors will be provided with your lead information, if you
                show interest in that Company or their products/services. Please
                check the privacy page for more information. If you would like
                your contact information to not be disseminated to exhibitors,
                you may opt-out below. You may change your status at any time on
                this page.
              </Typography>
              <br />
              <Tooltip
                title={
                  !online && (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
              >
                <Button onClick={() => this.handleChange()}>
                  <Typography variant="body1" align="justify">
                    Opt In
                  </Typography>
                  <Switch
                    checked={this.state.optOut}
                    onChange={this.handleChange}
                    color="primary"
                  />
                  <Typography variant="body1" align="justify">
                    Opt Out
                  </Typography>
                </Button>
              </Tooltip>
              {this.state.optOut ? (
                <Typography variant="body1" align="justify">
                  You have opted out
                </Typography>
              ) : (
                <Typography variant="body1" align="justify"></Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    event: state.event,
    online: isOnline(state),
  };
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(Settings));
