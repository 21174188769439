import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import AgendaRowItem from './AgendaRowItem';
import AgendaRowItemMobile from './AgendaRowItemMobile';
import { Typography, Grid, Paper } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '../../Components/DialogWrapper';
import AgendaFull from './AgendaFull';
import moment from 'moment';
import momentTimezone from 'moment-timezone'; //eslint-disable-line
import { fallbackImages, eventId } from '../../config';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import trackAction from '../../Store/Actions/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';
import { queryApi } from '../../Services/queryApi';
import { getAgendas } from '../../Store/Reducers/agenda';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      2
    )}px ${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class MyPersonalAgenda extends Component {
  briefcaseSubscription;
  agendasSubscription;
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      days: [],
      rooms: [],
      selectedDate: '',
      topicMap: null,
      currentAgendaItem: null,
      agendaSelected: false,
      briefcaseElements: [],
      agendaLoaded: false,
    };
  }
  componentDidMount() {
    this.briefcaseSubscription = this.props.getBriefcase(eventId);

    this.agendasSubscription = this.props.getAgendas();
    // .then((response) => {
    //   let abstracts = [];
    //   let days = [];
    //   let rooms = [];

    //   const allAbstracts = response?.data?.data.map((abstract) => ({
    //     ...abstract,
    //   }));

    //   for (let x = 0; x < allAbstracts.length; x++) {
    //     let abstract = allAbstracts[x];

    //     abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

    //     if (abstract.start_time) {
    //       abstracts.push(abstract);
    //     }

    //     if (
    //       abstract.date &&
    //       !days.includes(abstract.date) &&
    //       abstract.start_time
    //     ) {
    //       days.push(abstract.date);
    //     }

    //     if (
    //       abstract.room &&
    //       !rooms.includes(abstract.room) &&
    //       abstract.start_time
    //     ) {
    //       if (abstract.room === 'FEATURED') {
    //         rooms.unshift(abstract.room);
    //       } else {
    //         rooms.push(abstract.room);
    //       }
    //     }
    //   }

    //   abstracts.sort((a, b) => {
    //     return (
    //       moment.duration(a.start_time).asMinutes() -
    //       moment.duration(b.start_time).asMinutes()
    //     );
    //   });

    //   let idArray = this.props.briefcase.briefcase
    //     .filter((item) => {
    //       if (
    //         item.resource_type === 'Presentation' ||
    //         item.resource_type === 'Workshop'
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     })
    //     .map((item) => item.resource_id);

    //   let briefcaseElements = abstracts.filter((abstract) => {
    //     let briefcaseElement = null;
    //     idArray.map((id) => {
    //       if (id === abstract.abstract_id) {
    //         briefcaseElement = abstract;
    //         return abstract;
    //       }
    //       return abstract;
    //     });
    //     if (briefcaseElement) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });

    //   days.sort();

    //   days = days.filter((day) => {
    //     return briefcaseElements.some((e) => day === e.date);
    //   });

    //   const currentDayIndex = days.findIndex(
    //     (day) => day.substring(0, 10) === moment().format('YYYY-MM-DD')
    //   );

    //   let currentDay;
    //   if (currentDayIndex > -1) {
    //     currentDay = days[currentDayIndex];
    //   } else {
    //     currentDay = days[0];
    //   }

    //   this.setState({
    //     abstracts,
    //     days,
    //     rooms,
    //     selectedDate: currentDay,
    //     briefcaseElements,
    //     topicMap: JSON.parse(this.props.event.event.topic_map),
    //     agendaLoaded: true,
    //   });
    // })
    // .catch((error) => {
    //   if (this.props.debug.debug) {
    //     alert(`Error: Could not display My Personal Agenda. ${error}`);
    //   }
    // });
    this.setState({
      topicMap: JSON.parse(this.props.event.event.topic_map),
      agendaLoaded: true,
    });
    const event = {
      action: 'Viewed',
      resource_type: 'PersonalAgenda',
      resource_id: null,
      url_visited: '/mypersonalagenda',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  componentWillUnmount() {
    if (this.briefcaseSubscription) this.briefcaseSubscription.unsubscribe();
    if (this.agendasSubscription) this.agendasSubscription.unsubscribe();
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  toggleAgendaFullscreenOn = (event, id) => {
    this.setState({
      agendaSelected: true,
      currentAgendaItem: id,
    });
  };

  toggleAgendaFullscreenOff = () => {
    this.setState({
      agendaSelected: false,
      currentAgendaItem: null,
    });
  };

  render() {
    const { classes } = this.props;

    let abstracts = [];
    let days = [];
    let rooms = [];

    const allAbstracts = this.props.agendas?.data?.data?.map((abstract) => ({
      ...abstract,
    }));

    for (let x = 0; x < allAbstracts?.length; x++) {
      let abstract = allAbstracts[x];

      abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

      if (abstract.start_time) {
        abstracts.push(abstract);
      }

      if (
        abstract.date &&
        !days.includes(abstract.date) &&
        abstract.start_time
      ) {
        days.push(abstract.date);
      }

      if (
        abstract.room &&
        !rooms.includes(abstract.room) &&
        abstract.start_time
      ) {
        if (abstract.room === 'FEATURED') {
          rooms.unshift(abstract.room);
        } else {
          rooms.push(abstract.room);
        }
      }
    }

    abstracts.sort((a, b) => {
      return (
        moment.duration(a.start_time).asMinutes() -
        moment.duration(b.start_time).asMinutes()
      );
    });

    let idArray =
      this.props.briefcase?.data
        ?.filter((item) => {
          if (
            item.resource_type === 'Presentation' ||
            item.resource_type === 'Workshop'
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((item) => item.resource_id) || [];

    let briefcaseElements = abstracts.filter((abstract) => {
      let briefcaseElement = null;
      idArray.map((id) => {
        if (id === abstract.abstract_id) {
          briefcaseElement = abstract;

          return abstract;
        }
        return abstract;
      });

      if (briefcaseElement) {
        return true;
      } else {
        return false;
      }
    });

    days.sort();

    days = days.filter((day) => {
      return briefcaseElements.some((e) => day === e.date);
    });

    const currentDayIndex = days.findIndex(
      (day) => day.substring(0, 10) === moment().format('YYYY-MM-DD')
    );

    let currentDay;
    if (currentDayIndex > -1) {
      currentDay = days[currentDayIndex];
    } else {
      currentDay = days[0];
    }

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/mypersonalagenda') {
        pageTitle = route.title;
      }
    });

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );

    let loadCompleteNoneFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h6" color="textPrimary">
          No Agenda items have been briefcased. Please briefcase an Agenda item
          to populate this page.
        </Typography>
      </Grid>
    );

    return (
      <>
        <Grid container layout={'row'} spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px' }}
                variant="h4"
                color="textPrimary"
              >
                Welcome to {pageTitle}
              </Typography>
              <Typography
                style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}
                variant="h6"
                color="textPrimary"
              >
                Presentations added to your briefcase will display here in
                chronological order.
              </Typography>
            </Paper>
          </Grid>
          {days.map((day, index) => {
            if (day === (this.state.selectedDate || currentDay)) {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper
                    className={classes.paper + ' ' + classes.day}
                    variant="elevation"
                    elevation={10}
                  >
                    <Typography variant="h5">
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper className={classes.paper}>
                    <Typography variant="h5">
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
        <Typography
          variant="h6"
          color="textSecondary"
          align="center"
          style={{ padding: '16px 0px 0px 0px' }}
        >
          All times displayed are in your local time zone. ({moment.tz.guess()})
        </Typography>
        {briefcaseElements.length <= 0 && !this.state.agendaLoaded
          ? loadingGraphic
          : null}
        {briefcaseElements.length <= 0 && this.state.agendaLoaded
          ? loadCompleteNoneFound
          : null}
        {briefcaseElements.map((abstract, index) => {
          if ((this.state.selectedDate || currentDay) === abstract.date) {
            let requiredFields = [
              'description',
              'duration',
              'endDatetime',
              'location',
              'startDatetime',
              'title',
            ];
            // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
            let calendarAbstract = prepareObject(
              abstract,
              requiredFields,
              'presentation'
            );

            let agendaListImage;
            if (abstract.supporting_file_url) {
              agendaListImage = abstract.supporting_file_url;
            } else if (this.state.topicMap?.[abstract?.room]) {
              agendaListImage = this.state.topicMap[abstract.room];
            } else if (this.state.topicMap?.[abstract?.topic]) {
              agendaListImage = this.state.topicMap[abstract.topic];
            } else {
              agendaListImage = fallbackImages.agendaCard;
            }

            return (
              <div key={index}>
                <Hidden smDown>
                  <AgendaRowItem
                    key={index}
                    abstract={abstract}
                    topicMap={this.state.topicMap}
                    calendarAbstract={calendarAbstract}
                    roomImage={agendaListImage}
                    selectedDate={this.state.selectedDate || currentDay}
                    toggleFullscreenHandlerOn={this.toggleAgendaFullscreenOn.bind(
                      this
                    )}
                  />
                </Hidden>
                <Hidden mdUp>
                  <AgendaRowItemMobile
                    key={index}
                    abstract={abstract}
                    topicMap={this.state.topicMap}
                    calendarAbstract={calendarAbstract}
                    roomImage={agendaListImage}
                    selectedDate={this.state.selectedDate || currentDay}
                    toggleFullscreenHandlerOn={this.toggleAgendaFullscreenOn.bind(
                      this
                    )}
                  />
                </Hidden>
              </div>
            );
          } else {
            return null;
          }
        })}
        <Dialog
          open={this.state.agendaSelected}
          handleClose={this.toggleAgendaFullscreenOff.bind(this)}
          scroll="body"
        >
          <AgendaFull
            agenda={this.state.currentAgendaItem}
            closeAgenda={this.toggleAgendaFullscreenOff.bind(this)}
            // handleUpvote={() => this.handleUpvotePosterFull(this.state.agendaId)}
          />
        </Dialog>
        <div style={{ marginBottom: '16px' }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    debug: state.debug,
    event: state.event,
    agendas: queryApi.endpoints.getAgendas.select({
      eventId: eventId,
      params: {
        order_by: 'start_time',
        order_dir: 'asc',
      },
    })(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      getAgendas: () =>
        queryApi.endpoints.getAgendas.initiate({
          eventId: eventId,
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        }),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(MyPersonalAgenda));
