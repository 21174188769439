import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import ExhibitorBanner from '../../Assets/LandingPageImages/ExhibitorBanner.png';
import ExhibitHall from '../../Assets/LandingPageImages/ExhibitHall.png';

const useStyles = makeStyles((theme) => ({}));

const Security = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        style={{ marginTop: '60px' }}
      >
        <Grid item xs={12}>
          <img
            src={ExhibitorBanner}
            alt="Science Shared"
            style={{
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              position: 'relative',
              padding: 0,
              margin: 0,
              // top: '-4px',
            }}
          />
          <br />
          <br />
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{ marginTop: '5vw', marginBottom: '5vw' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            style={{
              paddingLeft: '2vw',
              paddingRight: '2vw',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              style={{ color: '#122857' }}
            >
              <b>An Interactive Exhibit Hall for Your Supplier Partners</b>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align="justify"
              style={{ color: '#122857', paddingRight: '40px' }}
            >
              <b>
                <ul>
                  <li>
                    Give thought leaders, suppliers, and internal teams a
                    dedicated space
                  </li>
                  <li>
                    Allow for robust exploration of your event by connecting
                    talks and posters to booths
                  </li>
                  <li>
                    Bring attendees together for direct discussion with key
                    partners
                  </li>
                  <li>
                    Showcase internal and external groups with specific
                    interests
                  </li>
                  <li>
                    See relevant products and services from a variety of
                    suppliers
                  </li>
                </ul>
              </b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
          >
            <img
              src={ExhibitHall}
              alt="Exhibit Hall"
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: '0px',
                display: 'block',
                position: 'relative',
                padding: 0,
                margin: 0,
                // top: '-4px',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          // style={{
          //   marginTop: '10vw',
          //   marginBottom: '5vw',
          //   backgroundColor: '#122857',
          // }}
        >
          <Grid
            item
            xs={12}
            // style={{ padding: '7vw 0vw' }}
          >
            <Container maxWidth="xl">
              <Typography
                variant="h4"
                align="center"
                style={{ color: '#122857' }}
              >
                <b>
                  "Our experience working with PlanetConnect for our virtual
                  user meeting was outstanding!"
                </b>
              </Typography>
              <br />
              <br />
              <Typography
                variant="h6"
                align="center"
                style={{ color: '#122857' }}
              >
                "[PlanetConnect] understood our needs and our user’s needs
                perfectly, kept up with a rapidly evolving agenda, and produced
                a virtual meeting environment that our customers really enjoyed.
                The virtual interface provided by PlanetConnect was interactive
                and graphically interesting, and was easy and fun to use. We saw
                the highest ever attendance on any of our user meetings compared
                to both in person and virtual. Customer participation was very
                high so our virtual user meeting was a huge success.”
              </Typography>
              <br />
              <br />
              <Typography
                variant="h5"
                align="center"
                style={{ color: '#122857' }}
              >
                <b>- Patty A.</b> <br />
                Field Marketing Manager
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Security;
