/**
 *
 * This function takes a conversation and the current twilio user's identity
 * and returns the other particiapnt *only* if it is a
 * one-on-one conversation.
 *
 */

const getOtherChatParticipant = (conversation, myIdentity) => {
  const conv = conversation.client || conversation;
  console.log(
    '🚀 ~ file: getOtherChatParticipant.js ~ line 11 ~ getOtherChatParticipant ~ conv',
    conv
  );

  // doesn't work with groups
  if (conv?.uniqueName?.startsWith('GROUP_')) return null;

  let otherParticipant = null;
  let participantCount = 0;
  // Loop over participants Map
  for (let [key, participant] of conversation.participants) {
    if (participant.identity !== myIdentity) {
      otherParticipant = participant;
      console.log(
        '🚀 ~ file: getOtherChatParticipant.js ~ line 22 ~ getOtherChatParticipant ~ participant',
        participant
      );
    }
    participantCount++;
  }
  // only works with one on one chats
  if (participantCount > 2) return null;
  console.log(
    '🚀 ~ file: getOtherChatParticipant.js ~ line 37 ~ getOtherChatParticipant ~ otherParticipant',
    otherParticipant
  );

  return otherParticipant;
};

export default getOtherChatParticipant;
