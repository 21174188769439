import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import Notification from '../../Components/Notifications';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PersonalChat from './PersonalChat';

class PrivateChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChannel: null,
      showAlert: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.chat.alert === nextProps.chat.alert) {
      if (this.state.showAlert === true) {
        this.setState({ showAlert: false });
      }
      return true;
    } else {
      this.setState({ showAlert: true });

      return true;
    }
  }

  render() {
    let notification;

    if (
      this.state.showAlert &&
      this.props.chat.alert.entry.email !== this.props.user.user.email
    ) {
      notification = (
        <Notification
          title={this.props.chat.alert.entry.text}
          body={this.props.chat.alert.entry.from}
        />
      );
    }

    return (
      <React.Fragment>
        {notification}
        <Divider />
        {this.props.chat.activePrivateChat ? (
          <PersonalChat channel={this.props.chat.activePrivateChat} />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user,
    sidebar: state.sidebar,
    chat: state.chat,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateChat));
