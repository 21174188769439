import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  userClicked: false,
};
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbarId: (state, action) => {
      state.id = action.payload;
    },
    removeSnackbarId: (state, action) => {
      state.id = action.payload;
      state.userClicked = true;
    },
  },
});

// Selectors
export const getsnackbar = (state) => state.snackbar.id;

// each case under reducers becomes an action
export const { setSnackbarId, removeSnackbarId } = snackbarSlice.actions;

export default snackbarSlice.reducer;
