import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
  },
}));

const DialogWrapperAgenda = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      open={true}
      onClose={(e) => {
        props.onRequestClose(e);
        // this.props.onMouseLeave()
      }}
    >
      <div className={classes.paper}>
        {props.header}
        <div
          onClick={(e) => {
            props.addAction(e);
          }}
        >
          {props.children}
        </div>
        <Button
          onClick={(e) => {
            props.onRequestClose(e);
            // this.props.onMouseLeave()
          }}
          variant="contained"
          color="primary"
          fullWidth
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};
export default DialogWrapperAgenda;
