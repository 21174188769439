import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenProfile,
  setEngagement,
  setOpenConversation,
} from '../Store/Reducers/twilio';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import backArrow from '../Assets/Icons/backArrow.png';
import email from '../Assets/Icons/mail.png';
import chatWhite from '../Assets/Icons/chatWhite.png';
import twilioCreateJoinOneOnOneConversation from '../Store/Actions/twilioCreateJoinOneOnOneConversation';
import twilioUnBlockUser from '../Store/Actions/twilioUnBlockUser';
import ChatProfile from '../Components/ChatProfile';
import { colors } from '../config';

// Redux

const useStyles = (theme) => ({});

const SidebarProfile = ({ openProfile, page }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const myUser = useSelector((state) => state.twilio.user?.client);
  const profileUser = useSelector(
    (state) => state.twilio.users?.[openProfile.identity]
  );

  // const profile = profileUser || openProfile;
  // const contacts = useSelector((state) => state.twilio?.contacts);

  const haveIBlockedThisUser =
    profileUser?.identity &&
    myUser?.attributes?.blockList?.[profileUser?.identity];

  const haveIBeenBlocked =
    profileUser?.attributes?.blockList?.[myUser.identity];

  //   const openProfile = useSelector(getOpenProfile);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: colors.backgroundGray,
        flex: 1,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        overflowY: 'auto',
        overflowX: 'hidden',
        // backgroundColor: 'green',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 50,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'row',
          position: 'relative',
          padding: '10px 0px ',
          backgroundColor: 'white',
          boxShadow: '0 2px 15px -7px #000',
          // zIndex: 100000,
        }}
      >
        {page === 'sidebar' && (
          <div
            onClick={() => dispatch(setOpenProfile(null))}
            style={{
              position: 'absolute',
              left: 15,
              cursor: 'pointer',
              alignSelf: 'center',
              display: 'flex',
            }}
          >
            <img src={backArrow} style={{ width: 12, height: 20 }} />
          </div>
        )}

        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
          }}
        >
          <ChatProfile
            identity={openProfile.identity}
            size={70}
            photo_url={openProfile?.attributes?.photo_url}
            onClick={() => {}}
          />
          {/* <div
            style={{
              ...styles.circle,
              width: 70,
              height: 70,
              backgroundColor: '#b0b0b0',
            }}
          >
            <img
              src={openProfile?.attributes?.photo_url}
              style={{ width: 70, height: 70, borderRadius: 50 }}
            />
          </div>
          <div
            style={{
              ...styles.circle,
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: 17,
              height: 17,
              backgroundColor: openProfile?.isOnline ? '#1DDD47' : 'white',
              border: openProfile?.isOnline
                ? '2px solid #fff'
                : '2px solid #757575',
            }}
          ></div> */}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          // height: 500,
          width: '100%',
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
          // backgroundColor: 'purple',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            marginTop: 40,
            padding: 5,
            // color: '#757575',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid red',
          }}
        >
          <div style={{ fontSize: 22, fontWeight: 'bold' }}>
            {openProfile.attributes.first_name}{' '}
            {openProfile.attributes.last_name}
          </div>
          <div style={{ fontSize: 18, textAlign: 'center' }}>
            {/* PSG-Solutions */}
            {openProfile.attributes.title}
          </div>
          <div style={{ fontSize: 18, textAlign: 'center' }}>
            {openProfile.attributes.email}
          </div>
          {!haveIBeenBlocked && !haveIBlockedThisUser && (
            <div
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: 50,
                marginTop: 10,
              }}
            >
              {openProfile?.identity && (
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    if (openProfile?.identity !== getTwilioUserIdentity(user)) {
                      dispatch(setOpenProfile(null));
                      dispatch(setEngagement('chat'));
                      const conv = await dispatch(
                        twilioCreateJoinOneOnOneConversation(
                          openProfile.identity
                        )
                      );
                      dispatch(
                        setOpenConversation({
                          page,
                          conversation: { sid: conv.sid },
                        })
                      );
                    }
                  }}
                  style={{
                    borderRadius: 20,
                    backgroundColor: '#122857',
                    color: 'white',
                    padding: '10px 0px ',
                    border: '0px solid grey',
                    margin: 5,
                    width: 40,
                    height: 40,
                    cursor: 'pointer',
                  }}
                >
                  <img src={chatWhite} style={{ width: 20, height: 20 }} />
                </button>
              )}

              <a
                href={`mailto:${openProfile.attributes.email}`}
                // onClick={sendMessage}
                style={{
                  borderRadius: 20,
                  backgroundColor: '#122857',
                  color: 'white',
                  // padding: '10px 0px ',
                  border: '0px solid grey',
                  margin: 5,
                  width: 40,
                  height: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={email} style={{ width: 20, height: 16 }} />
              </a>
            </div>
          )}
          {haveIBlockedThisUser && (
            <div>
              <p>You Have Blocked This User</p>
              <button
                onClick={async (e) => {
                  dispatch(twilioUnBlockUser(openProfile.identity));
                }}
                style={{
                  // borderRadius: 20,
                  backgroundColor: '#122857',
                  color: 'white',
                  padding: '10px 10px ',
                  border: '0px solid grey',
                  margin: 5,
                  // width: 40,
                  // height: 40,
                  cursor: 'pointer',
                }}
              >
                unblock this user
              </button>
            </div>
          )}
          {haveIBeenBlocked && <div>You Have Been Blocked By This User</div>}

          {/* TEMP: {openProfile.identity}
          <button
            onClick={() => {
              dispatch(twilioAddContact(openProfile.identity));
            }}
          >
            ADD
          </button> */}
          {/* <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      // border: '1px solid grey',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <img
                        src={breifcase}
                        style={{ width: 20, height: 19, margin: 5 }}
                      />
                    </div>
                    <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                      Your Items in their breifcase
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      width: '100%',
                      padding: 10,
                      // border: '1px solid grey',
                    }}
                  >
                    <div> - Emerging Technology Brochure</div>
                    <div> - Our Services Fact Sheet</div>
                    <div> - Pricing Catalougue</div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      // border: '1px solid grey',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      marginTop: 5,
                    }}
                  >
                    <div>
                      <img
                        src={notes}
                        style={{ width: 18, height: 18, margin: 5 }}
                      />
                    </div>
                    <div style={{ fontSize: 18, fontWeight: 'bold' }}>Notes </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Type Notes Here..."
                      onChange={(e) => {
                        // tell the client the user is typing
                        // it is debounded within the client
                        // conversation.typing();
                        // updateText(e.target.value);
                      }}
                      value={text}
                      style={{
                        width: '90%',
                        height: 200,
                        padding: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 5,
                        border: '1px solid gray',
                      }}
                    />
                  </div> */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    position: 'fixed',
    right: 10,
    bottom: 60,
    height: 50,
    width: 300,
    borderRadius: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#122857',
    color: 'white',
    padding: 10,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  chatRoot: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    height: '100%',
  },
  header: {
    width: '100%',
    height: 50,
    backgroundColor: '#122857',
    color: 'white',
    display: 'flex',
    // justifyContent: 'center',
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'relative',
  },
  tabs: {
    margin: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginRight: 15,
    marginBottom: 0,
    borderColor: '#122857',
    cursor: 'pointer',
  },
  roomBanner: {
    width: '100%',
    // backgroundColor: 'gray',
    // color: 'white',
    padding: 5,
    paddingLeft: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatRoom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0 0 15px -7px #000',
  },
  circle: {
    borderRadius: 50,
    width: 40,
    height: 40,
    backgroundColor: '#122857',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
};

export default SidebarProfile;
