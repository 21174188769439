import { eventId } from '../../config';
import { deleteBriefcaseEle } from '../Reducers/briefcase';
import { queryApi } from '../../Services/queryApi';

export default function deleteBriefcaseElement(resourceId) {
  return (dispatch, getState) => {
    const items = getState().debug;

    dispatch(
      queryApi.endpoints.deleteBriefcase.initiate({
        eventId: eventId,
        resourceId: resourceId,
      })
    )
      .then((response) => {
        // console.log(
        //   '🚀 ~ file: deleteBriefcaseElement.js ~ line 25 ~ .then ~ resourceId',
        //   resourceId
        // );

        dispatch(deleteBriefcaseEle(resourceId));
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not delete from briefcase. ${error}`);
        }
      });
  };
}
