import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import enterListener from '../../Utilities/listeners';

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    overflow: 'hidden',
  },
  filter: {
    margin: 0,
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  radio: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
});

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: this.props.filterString,
      filterCategory: this.props.filterCategory,
      filtering: this.props.filtering,
    };
  }

  listener = (event) => {
    enterListener(event, this.handleFilterClick);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.listener);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listener);
  }

  onFilterInputChange = (event) => {
    if (event.target.value) {
      this.setState({ filterString: event.target.value });
    } else {
      this.setState({ filterString: '' });
    }
  };

  handleFilterCategorySelect = (event) => {
    this.setState({
      filterCategory: event.target.value,
    });
  };

  handleFilterClick = () => {
    this.props.handleFilterClick(
      this.state.filterString,
      this.state.filterCategory
    );
    this.setState({
      filtering: true,
    });
  };

  handleRemoveFilter = () => {
    this.props.handleRemoveFilter();
    this.setState({
      filterString: '',
      filtering: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justify="space-between"
        xs={12}
        className={classes.root}
      >
        <Grid item xs={9}>
          <TextField
            id="filterInput"
            className={classes.filter}
            placeholder="Filter"
            value={this.state.filterString ? this.state.filterString : ''}
            margin="normal"
            variant="outlined"
            color="primary"
            onChange={this.onFilterInputChange}
          />
          <FormControl className={classes.radio} component="fieldset">
            <InputLabel htmlFor="filter-subject-native-helper">
              Category
            </InputLabel>
            <NativeSelect
              value={this.state.filterCategory}
              onChange={this.handleFilterCategorySelect}
              inputProps={{
                name: 'filter-subject',
                id: 'filled-filter-subject-native-helper',
              }}
            >
              {/* <option aria-label="None" value="" /> */}
              <option value={'presenter_first_name'}>First Name</option>
              <option value={'presenter_last_name'}>Last Name</option>
              <option value={'title'}>Poster Title</option>
              <option value={'presenter_title'}>Job Title</option>
              <option value={'presenter_company'}>Company</option>
              <option value={'topic'}>Topic</option>
            </NativeSelect>
          </FormControl>
          {this.state.filtering ? (
            <Button
              variant="contained"
              size="small"
              onClick={this.handleRemoveFilter}
              className={classes.button}
            >
              Remove Filter
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={this.handleFilterClick}
              className={classes.button}
            >
              Filter
            </Button>
          )}
        </Grid>
        <Hidden xsDown>
          <Grid item align="right" xs={3}>
            <Button onClick={this.props.handleGridListToggle}>
              Grid
              <Switch
                checked={this.props.listFormat}
                onChange={this.props.handleGridListToggle}
                color="primary"
                name="listFormatSwitch"
                inputProps={{ 'aria-label': 'listFormatSwitch' }}
              />
              List
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Filter);
