export const exhibitorGridOptions = [
  {
    id: 'account_name',
    numeric: false,
    disablePadding: false,
    label: 'Account Name',
  },
  {
    id: 'sponsorship_level',
    numeric: false,
    disablePadding: false,
    label: 'Sponsorship Level',
  },
  {
    id: 'booth_number',
    numeric: false,
    disablePadding: false,
    label: 'Booth Number',
  },
  {
    id: 'website',
    numeric: false,
    disablePadding: false,
    label: 'Website',
  },
  {
    id: 'linked_in_tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  },
];
