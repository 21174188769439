export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_CHATBAR = 'SET_CHATBAR';

export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const REMOVE_AUTHENTICATED_USER = 'REMOVE_AUTHENTICATED_USER';

export const SET_USER_ROLE = 'SET_USER_ROLE';
export const REMOVE_USER_ROLE = 'REMOVE_USER_ROLE';

export const SET_USER_ACCOUNT_ID = 'SET_USER_ACCOUNT_ID';
export const REMOVE_USER_ACCOUNT_ID = 'REMOVE_USER_ACCOUNT_ID';

export const SET_BRIEFCASE = 'SET_BRIEFCASE';
export const REMOVE_BRIEFCASE = 'REMOVE_BRIEFCASE';
export const ADD_BRIEFCASE_ELEMENT = 'ADD_BRIEFCASE_ELEMENT';
export const DELETE_BRIEFCASE_ELEMENT = 'DELETE_BRIEFCASE_ELEMENT';

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SET_SNACKBAR = 'SET_SNACKBAR';

// CHAT
export const SET_CHAT_MEMBERSHIP = 'SET_CHAT_MEMBERSHIP';
export const SET_CHAT_SUBSCRIPTION = 'SET_CHAT_SUBSCRIPTION';

export const ADD_CHAT_SUBSCRIPTION = 'ADD_CHAT_SUBSCRIPTION';
export const REMOVE_CHAT_SUBSCRIPTION = 'REMOVE_CHAT_SUBSCRIPTION';

export const NEW_CHAT_MEMBERSHIP = 'NEW_CHAT_MEMBERSHIP';
export const REMOVE_CHAT_MEMBERSHIP = 'REMOVE_CHAT_MEMBERSHIP';

export const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE';

export const SELECT_ACTIVE_PRIVATE_CHAT = 'SELECT_ACTIVE_PRIVATE_CHAT';
export const SELECT_ACTIVE_GROUP_CHAT = 'SELECT_ACTIVE_GROUP_CHAT';

export const GET_PRIVATE_CHAT_MESSAGES = 'GET_PRIVATE_CHAT_MESSAGES';
export const GET_GROUP_CHAT_MESSAGES = 'GET_GROUP_CHAT_MESSAGES';

export const VIEW_PRIVATE_CHAT = 'GET_GROUP_CHAT_MESSAGES';
export const REMOVE_ALL_CHAT = 'REMOVE_ALL_CHAT';

// DEBUG
export const SET_DEBUG = 'SET_DEBUG';

//EVENT
export const SET_EVENT_ELEMENTS = 'SET_EVENT_ELEMENTS';
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS';
export const REMOVE_EVENT_ELEMENTS = 'REMOVE_EVENT_ELEMENTS';

// STRAPI
export const SET_STRAPI_ELEMENTS = 'SET_STRAPI_ELEMENTS';
