import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import SecurityAgenda from '../../Assets/LandingPageImages/SecurityAgenda.png';
import PartnersBanner from '../../Assets/LandingPageImages/PartnersBanner.png';

const useStyles = makeStyles((theme) => ({}));

const Security = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        style={{ marginTop: '60px' }}
      >
        <Grid item xs={12}>
          <img
            src={PartnersBanner}
            alt="Partners Banner"
            style={{
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              position: 'relative',
              padding: 0,
              margin: 0,
              // top: '-4px',
            }}
          />
          <br />
          <br />
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{ marginTop: '5vw', marginBottom: '5vw' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            style={{
              paddingLeft: '2vw',
              paddingRight: '2vw',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              style={{ color: '#122857' }}
            >
              <b>
                Safely Share Your Proprietary, Confidential, and Sensitive Data
              </b>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align="justify"
              style={{ color: '#122857', paddingRight: '40px' }}
            >
              <b>
                <ul>
                  <li>
                    Public and private settings for every talk, exhibit, and
                    poster
                  </li>
                  <li>
                    Set attendees' ability to view public and private
                    information
                  </li>
                  <li>
                    SSO capabilities for fast registration and entry into the
                    virtual environment
                  </li>
                  <li>
                    Trusted with proprietary and sensitive information at
                    premier pharmaceutical companies
                  </li>
                </ul>
              </b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
          >
            <img
              src={SecurityAgenda}
              alt="SecurityAgenda"
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: '0px',
                display: 'block',
                position: 'relative',
                margin: 0,
                // top: '-4px',
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        // style={{
        //   marginTop: '10vw',
        //   marginBottom: '5vw',
        //   backgroundColor: '#122857',
        // }}
      >
        <Grid
          item
          xs={12}
          // style={{ padding: '7vw 0vw' }}
        >
          <Container maxWidth="xl">
            <Typography
              variant="h4"
              align="center"
              style={{ color: '#122857' }}
            >
              <b>
                "They Made it so Easy for us to Pivot to a Virtual Meeting..."
              </b>
            </Typography>
            <br />
            <br />
            <Typography
              variant="h6"
              align="center"
              style={{ color: '#122857' }}
            >
              "Once COVID made in-person meetings unfeasible we thought we’d
              have to cancel our symposium, but PlanetConnect introduced us to
              their virtual meeting platform and helped us make the transition.
              They made it so easy for us to pivot to a virtual meeting, which
              gave us the opportunity to expand participation to our other
              colleagues around the globe. Their virtual poster hall (secured
              access) and vendor areas allowed our scientists to showcase their
              work to colleagues and hear about new technologies and services
              from vendors. Thanks to the great team at PCI and because this
              event was so successful, we are already planning our 2022 meeting
              using this virtual platform.”
            </Typography>
            <br />
            <br />
            <Typography
              variant="h5"
              align="center"
              style={{ color: '#122857' }}
            >
              <b>- Rob P.</b> <br />
              Global Scientific Advisor
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Security;
