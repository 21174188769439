import { Auth } from 'aws-amplify';
import removeAuthenticatedUser from './removeUser';
import removeEventElements from './removeEventElements';
import removeChat from './removeChat';
import { queryApi } from '../../Services/queryApi';
import { resetTwilio } from '../Reducers/twilio';

export default function logoutUser() {
  return async (dispatch, getState) => {
    await dispatch(resetTwilio());
    await dispatch(removeAuthenticatedUser());
    await dispatch(removeEventElements());
    await dispatch(removeChat());
    await Auth.signOut();
    await dispatch(
      queryApi.util.invalidateTags('Event', 'Briefcase', 'Exhibit', 'Poster')
    );
  };
}
