import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Audit from './Audit.Reporting';
// import Leaderboard from './Leaderboard.Reporting';
// import TrackPopularity from './Presentations/Track.Reporting';
import PresentationPopularity from './Presentations/Presentation.Reporting';
// import TopicPopularity from './Posters/Topic.Reporting';
// import PosterPopularity from './Posters/Poster.Reporting';
import ProductPopularity from './Products/Products.Reporting';
import ExhibitorPopularity from './Exhibitors/Exhibitor.Reporting';
import Accounts from './Accounts';
import TopMetrics from './TopMetrics';
// import Rooms from './Rooms';
import Location from './Location';
// import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
    // padding: theme.spacing(2),
  },
}));

export default function Reporting(props) {
  const classes = useStyles();
  const event = useSelector((state) => state.event);
  // const userProp = useSelector((state) => state.user)
  // const [ presentations, setPresentations] = useState([])
  // const [ posters, setPosters] = useState([])
  // const [ exhibitors, setExhibitors] = useState([])
  // const [ roomView, setRoomView] = useState([])
  const [wholeReport, setWholeReport] = useState([]);
  const [times, setTimes] = useState([]);
  const [timesDaily, setTimesDaily] = useState([]);
  const [loading, setLoading] = useState([false]);
  const [presentationsObject, setPresentationsObject] = useState({});
  const [productsObject, setProductsObject] = useState({});
  const [postersObject, setPostersObject] = useState({});
  const [exhibitsObject, setExhibitsObject] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        //   const user = await Auth.currentSession()

        //   const report = await axios({
        //       method: "GET",
        //       url: `reporting/exhibition/${event.event.show_id}/${userProp.user.account_id}`,
        //       headers: {
        //           idtoken: user.idToken.jwtToken
        //       },
        //   });

        let presentations = [];
        let posters = [];
        let exhibitors = [];
        // let roomView = [];
        let times = [];
        let timesDaily = [];
        let products = [];
        // let resources = {};
        let presentationsObject = {};
        let postersObject = {};
        let exhibitsObject = {};
        let productsObject = {};

        const wholeReport = props.leadsReporting.map((datum, index) => {
          if (datum.resource_type === 'Presentation') {
            if (!datum.track) {
              datum.track = 'No Track';
            }
            presentations.push(datum);

            if (
              !Object.keys(presentationsObject).includes(datum.resource_name) &&
              datum.resource_name
            ) {
              presentationsObject[datum.resource_name] = [datum];
            } else if (datum.resource_name) {
              presentationsObject[datum.resource_name].push(datum);
            }
          } else if (datum.resource_type === 'Exhibit') {
            exhibitors.push(datum);

            if (
              !Object.keys(exhibitsObject).includes(datum.resource_name) &&
              datum.resource_name
            ) {
              exhibitsObject[datum.resource_name] = [datum];
            } else if (datum.resource_name) {
              exhibitsObject[datum.resource_name].push(datum);
            }
          } else if (datum.resource_type === 'Poster') {
            posters.push(datum);

            if (
              !Object.keys(postersObject).includes(datum.resource_name) &&
              datum.resource_name
            ) {
              postersObject[datum.resource_name] = [datum];
            } else if (datum.resource_name) {
              postersObject[datum.resource_name].push(datum);
            }
          } else if (datum.resource_type === 'Product') {
            products.push(datum);

            if (
              !Object.keys(productsObject).includes(datum.resource_name) &&
              datum.resource_name
            ) {
              productsObject[datum.resource_name] = [datum];
            } else if (datum.resource_name) {
              productsObject[datum.resource_name].push(datum);
            }
          }

          if (!datum.visitor_site) {
            datum.visitor_site = 'No Site Given';
          }

          if (
            !times.includes(
              moment(datum.visit_time).format('MMM Do YYYY, hh:00 a')
            )
          ) {
            times.push(moment(datum.visit_time).format('MMM Do YYYY, hh:00 a'));
          }

          if (
            !timesDaily.includes(
              moment(datum.visit_time).format('dddd, MMM Do YYYY')
            )
          ) {
            timesDaily.push(
              moment(datum.visit_time).format('dddd, MMM Do YYYY')
            );
          }

          return datum;
        });

        // console.log(wholeReport)
        // console.log('resources: ', resources)
        // console.log('postersObject: ', postersObject)
        // console.log('exhibitsObject: ', exhibitsObject)
        // console.log('presentationsObject: ', presentationsObject)
        // console.log('productsObject: ', productsObject)

        // setPresentations(presentations);
        // setPosters(posters);
        // setExhibitors(exhibitors);
        // setRoomView(roomView);
        setTimes(times);
        setTimesDaily(timesDaily);
        setWholeReport(wholeReport);
        setLoading(false);

        setPresentationsObject(presentationsObject);
        setPostersObject(postersObject);
        setProductsObject(productsObject);
        setExhibitsObject(exhibitsObject);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [event.event.show_id, props.leadsReporting]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
            <TopMetrics />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Accounts loading={loading} report={wholeReport} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Location loading={loading} report={wholeReport} />
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} sm={12}>
        <Audit times={times} timesDaily={timesDaily} report={wholeReport} />
      </Grid>

      {Object.keys(presentationsObject).length ? (
        <Paper className={classes.paper}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography className={classes.center} variant="h5">
                Presentations
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <TrackPopularity
              loading={loading}
              report={presentations}
              />
        </Grid> */}
            {presentationsObject && !loading
              ? Object.keys(presentationsObject).map((e, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={Object.keys(presentationsObject).length > 1 ? 6 : 12}
                    >
                      <PresentationPopularity
                        loading={loading}
                        report={presentationsObject[e]}
                        times={times}
                        timesDaily={timesDaily}
                      />
                    </Grid>
                  );
                })
              : null}
            {/* <Grid item xs={12} sm={6}>
            <PresentationPopularity
              loading={loading}
              report={presentations}
            />
        </Grid> */}
          </Grid>
        </Paper>
      ) : null}

      {Object.keys(postersObject).length ? (
        <Paper className={classes.paper}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography className={classes.center} variant="h5">
                Posters
              </Typography>
            </Grid>
            {postersObject && !loading
              ? Object.keys(postersObject).map((e, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={Object.keys(postersObject).length > 1 ? 6 : 12}
                    >
                      <PresentationPopularity
                        loading={loading}
                        report={postersObject[e]}
                        times={times}
                        timesDaily={timesDaily}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Paper>
      ) : null}

      {Object.keys(exhibitsObject).length ? (
        <Paper className={classes.paper}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography className={classes.center} variant="h5">
                Exhibits
              </Typography>
            </Grid>
            {exhibitsObject && !loading
              ? Object.keys(exhibitsObject).map((e, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={Object.keys(exhibitsObject).length > 1 ? 6 : 12}
                    >
                      <ExhibitorPopularity
                        loading={loading}
                        report={exhibitsObject[e]}
                        times={times}
                        timesDaily={timesDaily}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Paper>
      ) : null}

      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography className={classes.center} variant="h5">
              Products
            </Typography>
          </Grid>
          {productsObject && !loading
            ? Object.keys(productsObject).map((e, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={Object.keys(productsObject).length > 1 ? 6 : 12}
                  >
                    <ProductPopularity
                      loading={loading}
                      report={productsObject[e]}
                      times={times}
                      timesDaily={timesDaily}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Paper>
    </div>
  );
}
