import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ImageMapper from 'react-image-mapper';
import Dialog from '../Components/DialogWrapper';
import LobbyImage from '../Assets/Lobby-Demo_v1.jpg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import isOnline from '../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';

// Configure Redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
  gridContainer: {
    margin: '0px -24px 0px -24px',
  },
  imageMapper: {
    display: 'block',
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'absolute',
    zIndex: '2',
    cursor: 'pointer',
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  banner: {
    width: '15vw',
    height: '5vw',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    borderRadius: '0px 0px 5px 5px',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  reference: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
});

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: window.innerWidth * 0.955,
      imageClicked: null,
      modalOpen: false,
      rotationPosition: 0,
      optOut: null,
      optOutModalOpen: false,
      welcomeVideoLink: null,
    };
  }
  MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'Bruker',
        shape: 'poly',
        coords: [
          //left
          30,
          40, //top
          30,
          273, //bottom

          // right
          340,
          273, // bottom
          340,
          40, //top
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/exhibithall/?exhibition_id=1691992000052286001',
      },
      {
        name: 'NZyme',
        shape: 'poly', // width: 305, height: left: 237  right:233
        coords: [
          //left
          30,
          535, //top
          30,
          766, //bottom 19

          // right
          340,
          766, // bottom 15
          340,
          535, //top
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/exhibithall/?exhibition_id=1691992000053684042',
      },
      {
        name: 'Waters',
        shape: 'poly', // height: 233
        coords: [
          //left
          1660,
          40, //top
          1660,
          273, //bottom 19

          // right
          1970,
          273, // bottom 15
          1970,
          40, //top
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/exhibithall/?exhibition_id=1691992000053285062',
      },
      {
        name: 'HTX',
        shape: 'poly',
        coords: [
          //left
          1660,
          535, //top
          1660,
          766, //bottom

          // right
          1970,
          766, // bottom
          1970,
          535, //top
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/exhibithall/?exhibition_id=1691992000054057271',
      },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCoordinates);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event, item) {
    switch (item) {
      case 0:
        this.setState({
          welcomeVideoLink: 'https://player.vimeo.com/video/488193049',
          modalOpen: true,
        });
        break;
      case 1:
        this.setState({
          welcomeVideoLink: 'https://player.vimeo.com/video/487342443',
          modalOpen: true,
        });
        break;
      case 2:
        this.setState({
          welcomeVideoLink: 'https://player.vimeo.com/video/487320138',
          modalOpen: true,
        });
        break;
      case 3:
        this.setState({
          welcomeVideoLink: 'https://player.vimeo.com/video/487319752',
          modalOpen: true,
        });
        break;
      default:
        console.log('default');
        // this.props.history.push(`/${event.name}`);
        break;
    }
  }

  welcomeClose(event) {
    this.setState({
      modalOpen: false,
    });
  }

  optOutClose(event) {
    this.setState({
      optOutModalOpen: false,
    });
  }

  selectOptOutStatus() {}

  handleChange = () => {
    if (this.props.online) {
      this.setState({
        optOut: !this.state.optOut,
      });
    }
  };

  handleSwitchChange = (optOut) => {
    if (this.props.online) {
      if (optOut) {
        this.setState({
          optOut: true,
        });
      } else {
        this.setState({
          optOut: false,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;

    let welcomeVideo = (
      <div
        style={{
          position: 'relative',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <iframe
          title="welcome video"
          width={this.state.imageWidth * 0.66}
          height={((this.state.imageWidth * 0.66) / 16) * 9}
          src={this.state.welcomeVideoLink}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );

    let optOutComponent = (
      <Grid container layout={'row'} spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Welcome to the Digital Venue for the IMSI. We're glad you're here!
          </Typography>
          <br />
          <br />
          <Typography variant="body1" align="justify">
            Exhibitors and Sponsors help us make this event successful!
            Exhibitors will be provided with your lead information, if you show
            interest in that Company or their products/services. Please check
            the privacy page for more information. If you would like your
            contact information to not be disseminated to exhibitors, you may
            opt-out below. You may change your status at any time by going to
            the Settings page.
          </Typography>
          <br />
          <Tooltip
            title={
              !online && (
                <Typography variant="subtitle2">
                  User is Offline. Cannot perform action.
                </Typography>
              )
            }
          >
            <Typography variant="body1" align="justify">
              {/* <Button onClick={() => this.handleSwitchChange(false)}>Opt In</Button> */}
              <Switch
                checked={this.state.optOut}
                onChange={this.handleChange}
                color="primary"
              />
              <Button onClick={() => this.handleSwitchChange(true)}>
                Opt Out
              </Button>
            </Typography>
          </Tooltip>

          <br />
          {this.state.optOut ? (
            <Typography variant="body1" align="justify">
              Your personal information will not be disseminated to exhibitors.
            </Typography>
          ) : (
            <br />
          )}
          <br />
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => this.selectOptOutStatus()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );

    let pageWidth = !this.props.sidebar.open
      ? this.state.imageWidth
      : window.innerWidth - 240;

    return (
      <Grid
        className={classes.gridContainer}
        container
        layout={'row'}
        align="center"
        justify="center"
      >
        <Grid item className={classes.imageMapper} xs={12}>
          <Dialog
            open={this.state.modalOpen}
            handleClose={this.welcomeClose.bind(this)}
          >
            {welcomeVideo}
          </Dialog>
          <Dialog
            open={this.state.optOutModalOpen}
            handleClose={this.welcomeClose.bind(this)}
          >
            {optOutComponent}
          </Dialog>
          <ImageMapper
            width={pageWidth}
            imgWidth={2000}
            src={LobbyImage}
            map={this.MAP}
            onClick={(event, item) => this.imageClick(event, item)}
          />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    sidebar: state.sidebar,
    online: isOnline(state),
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(useStyles)(Lobby)));
