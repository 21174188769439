const getIncludedText = (data, searchValue) => {
  if (data?.title) {
    let idx = data?.title?.toLowerCase()?.indexOf(searchValue);

    if (idx > -1) {
      return {
        firstHalf: data.title.substring(0, idx),
        secondHalf: data.title.substring(idx + searchValue.length),
      };
    } else {
      idx = data?.content?.toLowerCase()?.indexOf(searchValue);
      const firstArr = data.content.substring(0, idx).split(' ');
      const first = firstArr.splice(-3);

      const ellipsis = first.length < firstArr.length ? '...' : '';

      const last = data.content.substring(idx + searchValue.length);

      if (idx > -1) {
        return { firstHalf: ellipsis + first.join(' '), secondHalf: last };
      }
    }
  } else if (data?.account_name) {
    let idx = data?.account_name?.toLowerCase()?.indexOf(searchValue);
    if (idx > -1) {
      return {
        firstHalf: data.account_name.substring(0, idx),
        secondHalf: data.account_name.substring(idx + searchValue.length),
      };
    } else {
      idx = data?.main_text?.toLowerCase()?.indexOf(searchValue);
      const firstArr = data.main_text.substring(0, idx).split(' ');
      const first = firstArr.splice(-3);
      const ellipsis = first.length < firstArr.length ? '... ' : '';

      const last = data.main_text.substring(idx + searchValue.length);

      if (idx > -1) {
        return { firstHalf: ellipsis + first.join(' '), secondHalf: last };
      }
    }
  }
  return null;
};

export default getIncludedText;
