import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { useEffect, useState } from 'react';

import { useField } from 'formik';

import { keywordsSanofi } from './LookUpFields';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

// const KeywordLookupField = ({ value = '', onSelect, validate }) => {
const KeywordLookupField = ({ ...props }) => {
  const classes = useStyles();
  const [data] = useState(keywordsSanofi);
  const [term, setTerm] = useState(props.value);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [empty, setEmpty] = useState(false);

  const [field, meta] = useField(props); //eslint-disable-line

  useEffect(() => {
    if (empty) {
      setSelectedValues([]);
    }
    props.onSelect('tags', selectedValues);
    setEmpty(false);
  }, [empty, selectedValues]);

  return (
    <Autocomplete
      className={classes.root}
      autoComplete={true}
      id="data"
      multiple
      freeSolo={true}
      getOptionLabel={(data) => data}
      options={data}
      inputValue={term}
      open={open}
      onOpen={() => {
        if (term) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        setSelectedValues(value);
      }}
      onInputChange={(e, value) => {
        // setValue(name, value);
        setTerm(value);

        // only open when inputValue is not empty after the user typed something
        if (!value) {
          setOpen(false);
        }
      }}
      noOptionsText={'No Data'}
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => {
          let chipProps = { ...getTagProps({ index }) };
          return (
            <Chip
              color="primary"
              key={value}
              label={value}
              {...getTagProps({ index })}
              onDelete={() => {
                chipProps.onDelete();
                if (values?.length === 1) {
                  setEmpty(true);
                }
              }}
            />
          );
        });
      }}
      renderInput={(params) => {
        return (
          <>
            <TextField {...params} style={{ margin: 0 }} label="Keywords" />
            {meta.touched ? (
              <Typography variant="subtitle1" color="error">
                {props.validate}
              </Typography>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default KeywordLookupField;
