// import { setOpenConversationMessages } from '../Reducers/twilio';

import { setOpenConversation, storeMessage } from '../Reducers/twilio';

export default function twilioLoadPreviousMessages({ page }) {
  return async (dispatch, getState) => {
    const openConversation = getState().twilio.openConversations?.[page];
    if (openConversation?.messages?.hasPrevPage) {
      const newMessages = await openConversation.messages.prevPage();
      const { messages, ...rest } = openConversation;
      // const newOpenConversation = { messages, ...rest };

      const prevItems = [...newMessages.items];
      const existingItems = [...messages.items];
      newMessages.items = [...prevItems, ...existingItems];
      const newOpenConversation = {
        ...rest,
        messages: newMessages,
      };

      dispatch(
        setOpenConversation({ page, conversation: newOpenConversation })
      );
      return true;
    }
    return false;
  };
}
