import moment from 'moment';
import sleep from '../../Utilities/sleep';
import {
  setOpenConversation,
  setConversationMessages,
  storeMessages,
} from '../Reducers/twilio';

export default function twilioLoadOpenConversation(page, conversationSid) {
  return async (dispatch, getState) => {
    // console.log(
    //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 7 ~ twilioLoadOpenConversation ~ conversationSid',
    //   conversationSid
    // );
    try {
      // const users = getState().twilio.users || {};
      // const openConversations = getOpenConversations(getState()) || {};
      const userTwilio = getState().twilio.user?.client;
      if (!userTwilio?.identity) throw new Error('not logged into twilio');
      const loadingConversationStatus = {
        sid: conversationSid, // tell the chat to open
        conversation: null,
        // messages: null,
        // participants: null,
        loading: true, // loading
        creating: false,
        error: null,
        // myParticipant: null,
        typing: {},
      };
      // console.log('setting ', {
      //   page,
      //   conversation: loadingConversationStatus,
      // });
      dispatch(
        setOpenConversation({ page, conversation: loadingConversationStatus })
      );
      let tenSecondsFromNow = moment().add(10, 'seconds');
      let conversation = getState().twilio.conversations?.[conversationSid];
      // console.log(
      //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 23 ~ return ~ conversationSid',
      //   conversationSid
      // );
      // console.log(
      //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 30 ~ return ~ conversation',
      //   conversation
      // );
      // wait for conversation to populate in the client
      while (
        moment() < tenSecondsFromNow &&
        !conversation?.client?.getMessages
      ) {
        await sleep(200);
        console.log('waiting for conversation to populate');
        conversation = getState().twilio.conversations?.[conversationSid];
      }
      if (!conversation) {
        throw new Error(`could not load conversation sid: ${conversationSid}`);
      }

      // await conversation.client.getParticipants();
      const messages = await conversation.client.getMessages();
      dispatch(
        setConversationMessages({
          sid: conversation.client.sid,
          messages: storeMessages(messages),
        })
      );
      // console.log(
      //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 44 ~ return ~ messages',
      //   messages
      // );
      // const participants = await conversation.client.getParticipants();
      // const newConversation = {...conversation, }

      // participants.sort(sortParticipantsByLastName(users));
      // // console.log(
      // //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 48 ~ return ~ participants',
      // //   participants
      // // );
      // const myParticipant = participants.find(
      //   (part) => part.identity === userTwilio.identity
      // );
      // const myAttributes = myParticipant ? { ...myParticipant.attributes } : {};
      // console.log(
      //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 52 ~ return ~ myParticipant',
      //   myParticipant
      // );

      // DONE
      const newOpenConversation = {
        sid: conversationSid,
        conversation,
        // messages, // messages.items.map((mess) => storeMessage(mess)),
        // participants,
        loading: false, // no longer loading
        creating: false,
        // myParticipant,
        // myAttributes,
        typing: {},
      };
      // console.log(
      //   '🚀 ~ file: twilioLoadOpenConversation.js ~ line 60 ~ return ~ newOpenConversation',
      //   newOpenConversation
      // );
      dispatch(
        setOpenConversation({ page, conversation: newOpenConversation })
      );
    } catch (err) {
      // reset loading
      dispatch(
        setOpenConversation({
          page,
          conversation: {
            sid: conversationSid,
            conversation: null,
            // messages: null,
            // participants: null,
            loading: false, // loading
            creating: false,
            error: err.message,
            // myParticipant: null,
            typing: {},
          },
        })
      );
      throw new Error(err.message);
    }
  };
}
