import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUser } from '../Store/Reducers/user';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useGetPollResultsQuery } from '../Services/queryApi';

export default function PollResultsScreen() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let token;
  for (let param of query.entries()) {
    if (param[0] === 'token') {
      token = param[1];
    }
  }

  let { pollId } = useParams();
  const {
    data = [],
    error,
    isLoading,
    currentData,
  } = useGetPollResultsQuery({ pollId, token }, { pollingInterval: 30000 }); // Refresh every 30 seconds

  if (isLoading) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <br />
          <br />
          <CircularProgress color="inherit" />
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (error) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <br />
          <br />
          <Typography variant="h4" color="textPrimary">
            Error: {error}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const poll = data || currentData;
  const answers = poll?.answers ? [...poll.answers] : [];
  let totalVotes = 0;
  answers.forEach((answer) => (totalVotes += answer.votes));
  answers.sort((a, b) => b.votes - a.votes);
  return (
    <Grid container style={{ marginTop: 10 }}>
      {poll.image_url && (
        <Grid item xs={12} container justifyContent="center">
          <img src={poll.image_url} style={{ width: '60%', height: 'auto' }} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
        >
          {poll.question}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer id="leaderboard-tabpanel" component={Paper}>
          <Table stickyHeader aria-label="leaderboard table">
            <TableHead>
              <TableRow>
                <TableCell>Answer</TableCell>
                <TableCell align="right">Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answers.map((answer) => (
                <TableRow key={`poll_${pollId}_${answer.answer_id}`}>
                  <TableCell component="th" scope="row">
                    {answer.value}
                  </TableCell>
                  <TableCell align="right">{answer.votes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
