import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  debug: false,
};

export const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebug: (state, action) => {
      state.debug = action.payload;
    },
  },
});

// Selectors
export const getDebug = (state) => state.debug.debug;

// each case under reducers becomes an action
export const { setDebug } = debugSlice.actions;

export default debugSlice.reducer;
