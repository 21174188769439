import { setOpenConversation } from '../Reducers/twilio';
import getOtherChatParticipant from '../../Utilities/getOtherChatParticipant';
import trackAction from './tracker';

export default function twilioBlockUser(page, conversation) {
  return async (dispatch, getState) => {
    // const user = getState().user.user;
    // let openConversation = getState().twilio.openConversations?.[page];
    const userTwilio = getState().twilio.user?.client;

    // let newBlockList = userTwilio.attributes?.blockList
    //   ? { ...userTwilio.attributes.blockList }
    //   : {};
    let newBlockList = userTwilio.attributes?.blockList
      ? { ...userTwilio.attributes.blockList }
      : {};
    // newBlockList = {};
    console.log(
      '🚀 ~ file: twilioBlockUser.js ~ line 18 ~ return ~ conversation',
      conversation
    );

    const otherParticipant = getOtherChatParticipant(
      conversation,
      userTwilio.identity
    );

    if (otherParticipant) {
      if (!newBlockList[otherParticipant.identity]) {
        console.log(
          '🚀 ~ file: ChatConversation.js ~ line 1383 ~ onClick={ ~ newBlockList',
          newBlockList
        );

        newBlockList[otherParticipant.identity] = conversation.sid;
      }
      // else {
      //   newBlockList = newBlockList.filter(
      //     (identity) => identity !== otherParticipant.identity
      //   );
      // }
    }

    const newAttributes = {
      ...userTwilio.attributes,
      blockList: newBlockList,
    };
    await userTwilio.updateAttributes(newAttributes);
    await conversation.leave();
    dispatch(setOpenConversation({ page, conversation: null }));

    const event = {
      action: 'unFollow',
      resource_type: 'IndividualChat',
      resource_id: conversation.sid,
      url_visited: '/engagementZone',
      resource_account_id: conversation.attributes.identity,
      resource_account_name: conversation.attributes.identity,
      resource_name: conversation.friendlyName,
    };

    dispatch(trackAction(event));
  };
}
