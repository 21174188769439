import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posters: null,
};

export const posterSlice = createSlice({
  name: 'poster',
  initialState,
  reducers: {
    setPosters: (state, action) => {
      state.posters = action.payload;
    },
    resetPosters: (state, action) => {
      state.posters = null;
    },
  },
});

// Selectors
export const getPosters = (state) => state.poster.posters;

// each case under reducers becomes an action
export const { setPosters } = posterSlice.actions;

export default posterSlice.reducer;
