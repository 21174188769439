import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { eventId } from '../../config';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class VendorQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitors: [],
      category: null,
      selectedVendors: [],
      topic: this.props.challengeData.topic,
      forRegion: null,
      pipelinePhase: null,
      questionText: this.props.challengeData.content,
      submitted: false,
      validationError: false,
      error: false,
      phaseSelected: {
        Discovery: false,
        'Pre-Clinical': false,
        Clinical: false,
      },
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  getExhibits() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/exhibitions/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            order_by: 'booth_type',
            order_dir: 'asc',
            search_columns: this.state.filterCategory,
            search_value: this.state.filterString,
          },
        }).then(
          (response) => {
            let exhibitors = response.data.data;

            let authorizedExhibitors = [];

            for (let i = 0; i < exhibitors.length; i++) {
              if (exhibitors[i].type !== 'Digital Lab') {
                if (exhibitors[i].is_public) {
                  exhibitors[i].checked = false;
                  authorizedExhibitors.push(exhibitors[i]);
                } else {
                  if (
                    this.props.user.user.roles.includes('Attendee') ||
                    this.props.user.user.roles.includes('Pharma Attendee')
                  ) {
                    exhibitors[i].checked = false;
                    authorizedExhibitors.push(exhibitors[i]);
                  }
                }
              }
            }

            authorizedExhibitors.sort(function (a, b) {
              return a.sortByNumber - b.sortByNumber;
            });

            this.setState({
              exhibitors: authorizedExhibitors,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCategorySelection(event, exhibitor) {
    let checkedExhibitors = this.state.exhibitors;

    checkedExhibitors.map((e) => {
      if (e.exhibition_id === exhibitor.exhibition_id) {
        e.checked = event.target.checked;
      }
    });

    this.setState({
      exhibitors: checkedExhibitors,
    });
  }

  handlePhaseSelection(event) {
    let selectedPhases = this.state.phaseSelected;

    if (selectedPhases.includes(event.target.value)) {
      selectedPhases.pop(event.target.value);
    } else {
      selectedPhases.push(event.target.value);
    }

    this.setState({
      phaseSelected: selectedPhases,
    });
  }

  handleChipCategorySelection(event) {
    let checkedExhibitors = this.state.exhibitors;

    checkedExhibitors.map((e) => {
      if (
        e.exhibition_id === event.target.value[event.target.value.length - 1]
      ) {
        e.checked = !e.checked;
      }
    });

    this.setState({
      exhibitors: checkedExhibitors,
    });
  }

  handleChipPhaseSelection(event, index) {
    let phaseSelectedObject = {
      Discovery: true,
      'Pre-Clinical': true,
      Clinical: true,
    };

    for (let x = 0; x < event.target.value.length; x++) {
      phaseSelectedObject[event.target.value[x]] = false;
    }

    let phaseSelectedCopy = this.state.phaseSelected;

    if (phaseSelectedObject['Discovery']) {
      phaseSelectedCopy['Discovery'] = !phaseSelectedCopy['Discovery'];
    } else if (phaseSelectedObject['Pre-Clinical']) {
      phaseSelectedCopy['Pre-Clinical'] = !phaseSelectedCopy['Pre-Clinical'];
    } else if (phaseSelectedObject['Clinical']) {
      phaseSelectedCopy['Clinical'] = !phaseSelectedCopy['Clinical'];
    }

    this.setState({
      phaseSelected: phaseSelectedCopy,
    });
  }

  getChipStyles(index) {
    return {
      fontWeight: this.state.exhibitors[index].checked ? 'bold' : 'normal',
    };
  }

  getPhaseChipStyles(phase) {
    return {
      fontWeight: this.state.phaseSelected[phase] ? 'bold' : 'normal',
    };
  }

  questionTextUpdate(event) {
    this.setState({
      questionText: event.target.value,
    });
  }

  putChallenge() {
    if (!this.state.topic || !this.state.questionText) {
      this.setState({
        validationError: true,
      });
    } else {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'put',
            url: `/vendors/query/${eventId}/${this.props.challengeData.vendor_query_id}`,
            ///query/:showId/:queryId
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              topic: this.state.topic,
              content: this.state.questionText,
            },
          }).then(
            (response) => {
              this.props.getChallenges();

              this.setState({
                submitted: true,
              });
            },
            (error) => {
              this.setState({
                error: true,
              });
            }
          );
        })
        .catch((error) => {});
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.submitted) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Challenge edited successfully!
          </Typography>
        </Box>
      );
    } else if (this.state.error) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            There was an error with your update. Please try again.
          </Typography>
        </Box>
      );
    }

    return (
      <React.Fragment>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Edit Your Vendor Challenge
          </Typography>
          <br />
          <br />
          <InputLabel id="demo-multiple-chip-label">
            Challenge Statement
          </InputLabel>
          <TextField
            value={this.state.questionText}
            multiline
            rows={4}
            maxRows={10}
            id="outlined-basic"
            variant="outlined"
            onChange={(event) => this.questionTextUpdate(event)}
            error={
              this.state.validationError && !this.state.questionText
                ? true
                : false
            }
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.topic}
              label="Age"
              onChange={(event) =>
                this.handleChange(event.target.value, 'topic')
              }
              error={
                this.state.validationError && !this.state.topic ? true : false
              }
            >
              {Object.keys(JSON.parse(this.props.event.event.topic_map)).map(
                (topic) => (
                  <MenuItem value={topic}>{topic}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <br />
          <br />
          <br />
          <br />
          <Button
            className={classes.buttonLearn}
            variant="contained"
            size="medium"
            onClick={() => this.putChallenge()}
          >
            Edit Challenge
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //   getChatMessages: getChatMessages,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(VendorQuery));
