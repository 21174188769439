import axios from 'axios';
import { Auth } from 'aws-amplify';

export default function twilioJoin({
  eventId,
  type,
  friendlyName,
  uniqueName,
  abstractId,
  exhibitionId,
  logo,
}) {
  return async (dispatch, getState) => {
    const items = getState().debug;
    const event = getState().event;
    let conversationsServiceSid = event.event.twilio_conversation_id;

    // console.log('🚀 ~ file: twilioJoin.js ~ line 32 ~ return ~ logo', logo);

    // Ask the backend to join a group conversation
    const data = await Auth.currentSession();
    const response = await axios({
      method: 'post',
      url: `/twilio/join`,
      headers: { idtoken: data.idToken.jwtToken },
      data: {
        conversationsServiceSid,
        eventId,
        type,
        friendlyName,
        uniqueName,
        abstractId,
        exhibitionId,
        logo,
      },
    });

    // console.log(
    //   '🚀 ~ file: twilioJoin.js ~ line 27 ~ .then ~ response',
    //   response
    // );

    return response.data;

    // const event = getState().event;
  };
}
