const parseSearchUrl = (searchText) => {
  const search = searchText.replace('?', '');
  if (!search) {
    return {};
  }
  const pairs = search.split('&');
  const obj = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');

    obj[key] = decodeURIComponent(value);
  });

  return obj;
};
export default parseSearchUrl;
