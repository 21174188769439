import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { getUser } from '../Store/Reducers/user';
import LeaderboardTable from '../Components/LeaderboardTable';
import MyPointsTable from '../Components/MyPointsTable';

export default function LeaderboardScreen() {
  const user = useSelector(getUser);
  const [currentTab, setCurrentTab] = React.useState(0);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let token;
  for (let param of query.entries()) {
    if (param[0] === 'token') {
      token = param[1];
    }
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (token)
    return (
      <Grid container style={{ marginTop: 50 }}>
        <Grid item xs={12}>
          <LeaderboardTable />
        </Grid>
      </Grid>
    );

  return (
    <Grid container style={{ marginTop: 50 }}>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="Leaderboard Tabs"
          >
            <Tab
              label="Leaderboard"
              id={`leaderboard-tab`}
              aria-controls={`leaderboard-tabpanel`}
            />
            <Tab
              label="My Points"
              id={`my-points-tab`}
              aria-controls={`my-points-tabpanel`}
            />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        {currentTab == 0 && <LeaderboardTable />}
        {currentTab == 1 && <MyPointsTable />}
      </Grid>
    </Grid>
  );
}
