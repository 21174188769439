import { setConversation, storeParticipant } from '../Reducers/twilio';

export default function twilioParticipantJoined(participant) {
  return (dispatch, getState) => {
    console.log(
      '🚀 ~ file: twilioParticipantJoined.js ~ line 11 ~ return ~ participant?.conversation?.sid'
    );
    const conversationSid = participant?.conversation?.sid;
    if (conversationSid) {
      const conversation = getState().twilio.conversations?.[conversationSid];
      if (conversation) {
        const newParticipants = { ...conversation.participants };
        newParticipants[participant.identity] = storeParticipant(participant);
        const newConversation = {
          ...conversation,
          participantCount: Object.keys(newParticipants).length,
          participants: newParticipants,
        };
        dispatch(setConversation(newConversation));
      }
    }
  };
}
