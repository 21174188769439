const sortParticipantsByLastName = (users) => (a, b) => {
  const a_last_name = users[a.identity]?.attributes?.last_name || a.last_name;
  const a_first_name =
    users[a.identity]?.attributes?.first_name || a.first_name;
  const b_last_name = users[b.identity]?.attributes?.last_name || b.last_name;
  const b_first_name =
    users[b.identity]?.attributes?.first_name || b.first_name;

  return a_last_name > b_last_name
    ? 1
    : b_last_name > a_last_name
    ? -1
    : a_first_name > b_first_name
    ? 1
    : b_first_name > a_first_name
    ? -1
    : 0;
};

export default sortParticipantsByLastName;
