import { eventId } from '../../config';
import { queryApi } from '../../Services/queryApi';

export default function setBriefcase() {
  return (dispatch, getState) => {
    const items = getState().debug;

    dispatch(queryApi.endpoints.getBriefcase.initiate(eventId)).catch(
      (error) => {
        if (items.debug) {
          alert(`Error: Could not get the briefcase information. ${error}`);
        }
      }
    );
  };
}
