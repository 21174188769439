import { setConversation } from '../Reducers/twilio';

export default function twilioParticipantLeft(participant) {
  return (dispatch, getState) => {
    const conversationSid = participant?.conversation?.sid;
    if (conversationSid) {
      const conversation = getState().twilio.conversations?.[conversationSid];
      if (conversation) {
        const newParticipants = { ...conversation.participants };
        delete newParticipants[participant.identity];
        const newConversation = {
          ...conversation,
          participantCount: Object.keys(newParticipants).length,
          participants: newParticipants,
        };
        dispatch(setConversation(newConversation));
      }
    }
  };
}
