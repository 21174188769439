import { createSlice } from '@reduxjs/toolkit';
import {
  SET_CHAT_MEMBERSHIP,
  NEW_CHAT_MESSAGE,
  SELECT_ACTIVE_PRIVATE_CHAT,
  SELECT_ACTIVE_GROUP_CHAT,
  GET_PRIVATE_CHAT_MESSAGES,
  NEW_CHAT_MEMBERSHIP,
  VIEW_PRIVATE_CHAT,
  REMOVE_ALL_CHAT,
} from '../Actions/types';

const initialState = {
  memberships: [],
  subscriptions: [],
  users: [],
  activePrivateChat: null,
  activePrivateMember: null,
  activePrivateChatMessages: [],
  activeGroupChat: null,
  activeGroupChatMessages: [],
  alert: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMembership: (state, action) => {
      action.payload.memberships.map(
        (membership) => (membership.newMessage = 0)
      );
      state.memberships = action.payload.memberships;
      state.subscriptions = action.payload.subscriptions;
      state.users = action.payload.users;
    },
    getPrivateChatMessages: (state, action) => {
      state.activePrivateChatMessages = action.payload.messages;
    },
    getPrivateChat: (state, action) => {
      const membershipIndex = state.memberships.findIndex(
        (membership) => membership.channel.id === action.payload
      );

      if (membershipIndex > -1) {
        let newMemberships = [...state.memberships];
        newMemberships[membershipIndex].newMessage = 0;
        state.memberships = [...newMemberships];
      }
    },
    newChatMembership: (state, action) => {
      state.activeGroupChatMessages = [
        ...state.memberships,
        action.payload.membership,
      ];
    },
    newChatMessage: (state, action) => {
      if (action.payload.message.channel === state.activePrivateChat) {
        state.activePrivateChatMessages = [
          ...state.activePrivateChatMessages,
          action.payload.message,
        ];
        state.alert = action.payload.message;
      } else {
        //this is where messages from other channels should modify the membership objects

        const membershipIndex = state.memberships.findIndex(
          (membership) =>
            membership.channel.id === action.payload.message.channel
        );

        if (membershipIndex > -1) {
          let newMemberships = [...state.memberships];
          newMemberships[membershipIndex].newMessage++;
          state.memberships = [...newMemberships];

          state.alert = action.payload.message;
        }
      }
    },
    selectActivePrivateChat: (state, action) => {
      state.activePrivateChat = action.payload.channel;
      state.activePrivateMember = action.payload.member;
    },
    selectActiveGroupChat: (state, action) => {
      state.activeGroupChat = action.payload.message;
    },
    resetChat: (state, action) => {
      state.memberships = [];
      state.subscriptions = [];
      state.users = [];
      state.activePrivateChat = null;
      state.activePrivateMember = null;
      state.activePrivateChatMessages = [];
      state.activeGroupChat = null;
      state.activeGroupChatMessages = [];
      state.alert = null;
    },
  },
});

// Selector
// export const getChat = (state) => state.chat.chat;

// each case under reducers becomes an action
export const {
  setChatMembership,
  getPrivateChatMessages,
  getPrivateChat,
  newChatMembership,
  newChatMessage,
  selectActivePrivateChat,
  selectActiveGroupChat,
  resetChat,
} = chatSlice.actions;

export default chatSlice.reducer;
