import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import enterListener from '../../Utilities/listeners';

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    overflow: 'hidden',
  },
  filter: {
    margin: 0,
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  radio: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
});

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: this.props.filterString,
      filterCategory: this.props.filterCategory,
      filtering: this.props.filtering,
    };
  }

  listener = (event) => {
    enterListener(event, this.handleFilterClick);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.listener);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listener);
  }

  onFilterInputChange = (event) => {
    if (event.target.value) {
      this.setState({ filterString: event.target.value });
    } else {
      this.setState({ filterString: '' });
    }
  };

  handleFilterCategorySelect = (event) => {
    this.setState({
      filterCategory: event.target.value,
    });
  };

  handleFilterClick = () => {
    this.props.handleFilterClick(
      this.state.filterString,
      this.state.filterCategory
    );
    this.setState({
      filtering: true,
    });
  };

  handleRemoveFilter = () => {
    this.props.handleRemoveFilter();
    this.setState({
      filtering: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justify="space-between"
        xs={12}
      >
        <Grid item xs={9}>
          <TextField
            id="filterInput"
            className={classes.filter}
            placeholder="Filter for subject"
            margin="normal"
            variant="outlined"
            color="primary"
            onChange={this.onFilterInputChange}
          />
          <FormControl component="fieldset" className={classes.radio}>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="start"
            >
              <FormControlLabel
                value="presenter_first_name"
                control={
                  <Radio
                    color="primary"
                    checked={
                      this.state.filterCategory === 'presenter_first_name'
                    }
                    onChange={this.handleFilterCategorySelect}
                  />
                }
                label="First Name"
              />
              <FormControlLabel
                value="presenter_last_name"
                control={
                  <Radio
                    color="primary"
                    checked={
                      this.state.filterCategory === 'presenter_last_name'
                    }
                    onChange={this.handleFilterCategorySelect}
                  />
                }
                label="Last Name"
              />
              <FormControlLabel
                value="title"
                control={
                  <Radio
                    checked={this.state.filterCategory === 'title'}
                    onChange={this.handleFilterCategorySelect}
                    color="primary"
                  />
                }
                label="Title"
              />
            </RadioGroup>
          </FormControl>
          {this.state.filtering ? (
            <Button
              variant="contained"
              size="small"
              onClick={this.handleRemoveFilter}
              className={classes.button}
            >
              Remove Filter
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={this.handleFilterClick}
              className={classes.button}
            >
              Filter
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Filter);
