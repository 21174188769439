// obj is a full robust object with key value pairs.
// arr is an array of strings. the strings are keys you would like to search for and match to create a new object.

// const startDatetime = moment().utc().add(2, 'days');
// const endDatetime = startDatetime.clone().add(2, 'hours');
// const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
// const event = {
//   description: 'Description of event. Going to have a lot of fun doing things that we scheduled ahead of time.',
//   duration,
//   endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
//   location: 'NYC',
//   startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
//   title: 'Super Fun Event',
// }
import { endpoint } from '../config';
import moment from 'moment';

let siteURL = endpoint.slice(0, -3);
let posterHallURL = siteURL + 'posterhall';

const prepareData = (obj, arr, type) => {
  let data = {};
  let startTime = moment(obj.start_time).format('YYYYMMDDTHHmmss');
  let endTime = moment(obj.end_time).format('YYYYMMDDTHHmmss');
  // let startTime = moment(obj['start_time']).format('YYYYMMDD') + 'T' + moment(obj['start_time'], 'HH:mm:ss').format('HHmmss')
  // let endTime = moment(obj['start_time']).format('YYYYMMDD') + 'T' + moment(obj['end_time'], 'HH:mm:ss').format('HHmmss')

  let a = moment(startTime);
  let b = moment(endTime);
  let duration = b.diff(a, 'hours', true);
  // let timezone = moment.tz.guess();
  // console.log(moment(startTime).tz(timezone).format('YYYYMMDDTHHmmss'));
  // console.log(startTime)
  // console.log(obj)
  for (let field of arr) {
    if (field in obj) {
      data[field] = obj[field];
    } else if (!(field in obj)) {
      switch (field) {
        case 'location':
          if (obj['presentation_link'] === null) {
            data[field] = posterHallURL;
          } else {
            data[field] = obj['presentation_link'];
          }
          break;
        case 'description':
          data[field] =
            'Presentation Link: ' +
            obj['presentation_link'] +
            '<br/><br/>Topic: ' +
            obj['topic'] +
            '<br/><br/>' +
            obj['content'];
          break;
        case 'startDatetime':
          data[field] = startTime;
          break;
        case 'endDatetime':
          data[field] = endTime;
          break;
        case 'duration':
          data[field] = duration;
          break;
        // case ('timezone'):
        //     data[field] = timezone;
        //     break;
        default:
          data[field] = null;
      }
    }
  }
  return data;
};

export default prepareData;
