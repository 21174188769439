import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  strapi: null,
};
export const strapiSlice = createSlice({
  name: 'strapi',
  initialState,
  reducers: {
    setStrapi: (state, action) => {
      state.strapi = action.payload;
    },
  },
});

// Selectors
export const getStrapi = (state) => state.strapi.strapi;

// each case under reducers becomes an action
export const { setStrapi } = strapiSlice.actions;

export default strapiSlice.reducer;
