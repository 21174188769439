/**
 *
 * Make sure this matches the backend
 *
 * @param {*} user
 * @returns the twilio identity
 */
const getTwilioUserIdentity = (user) => user.email;

export default getTwilioUserIdentity;
