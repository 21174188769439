import { getPrivateChat } from '../Reducers/chat';
import { VIEW_PRIVATE_CHAT } from './types';

export default function viewPrivateChat(channel) {
  return (dispatch) => {
    dispatch(getPrivateChat(channel));
  };
}

// function viewPrivateChatAsync(channel) {
//   return {
//     type: VIEW_PRIVATE_CHAT,
//     payload: channel,
//   };
// }
