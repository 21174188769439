import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';
import { setAuthenticatedUserState } from '../Reducers/user';

export default function setAuthenticatedUser() {
  return (dispatch, getState) => {
    const items = getState().debug;
    // console.log('🚀 ~ file: setUser.js ~ line 9 ~ return ~ items', items);

    return Auth.currentSession()
      .then((data) =>
        axios({
          method: 'get',
          url: `/show-attendees/${eventId}/${data.idToken.payload.email}`,
          headers: { idtoken: data.idToken.jwtToken },
        })
      )
      .then((response) => {
        // console.log(
        //   '🚀 ~ file: setUser.js ~ line 18 ~ .then ~ response',
        //   response
        // );
        if (response.data.length) {
          dispatch(setAuthenticatedUserState(response.data[0]));
          return response.data[0];
        } else {
          dispatch(setAuthenticatedUserState('unregistered'));
          return 'unregistered';
        }
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not get the user information. ${error}`);
        }
      });
  };
}
