import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ChatProfileRow from './ChatProfileRow';
import sortParticipantsByLastName from '../Utilities/sortParticipantsByLastName';
import { getUsers } from '../Store/Reducers/twilio';
import { getSyncClient } from '../Store/Reducers/twilio';

import { colors } from '../config';
import { SyncClient, SyncDocument } from 'twilio-sync';

const ChatConversationHereNow = forwardRef(
  ({ conversation }: { conversation: any }, ref: any) => {
    // const dispatch = useDispatch();
    // const user = useSelector((state) => state.user.user);
    // console.log('🚀 ~ file: ChatConversationHereNow.js ~ line 43 ~ user', user);
    // const userTwilio = useSelector((state) => state.twilio.user?.client);
    const syncClient: SyncClient = useSelector(getSyncClient);
    const users: any[] = useSelector(getUsers);
    const [hereNow, setHereNow] = useState({});

    const exhibitionId = conversation?.client?.attributes?.exhibitionId;

    const uniqueName = conversation?.uniqueName;

    useEffect(() => {
      let hereNowClient: SyncDocument;
      (async () => {
        if (uniqueName) {
          hereNowClient = await syncClient.document(`HERE_NOW_${uniqueName}`);
          setHereNow(hereNowClient.data);
          hereNowClient.on('updated', function (args) {
            // console.log(
            //   `HERE_NOW_${uniqueName}`,
            //   JSON.stringify(args.data, null, 2)
            // );
            setHereNow(args.data);
          });
        } else {
          setHereNow({});
        }
      })();
      return () => {
        if (hereNowClient?.close) hereNowClient.close();
      };
    }, [uniqueName]);

    // console.log(
    //   '🚀 ~ file: ChatConversationHereNow.js ~ line 44 ~ ChatConversationHereNow ~ openConversation',
    //   openConversation
    // );

    const admins: Array<any> = [];
    const participants: Array<any> = [];

    // console.log(
    //   '🚀 ~ file: ChatConversationHereNow.jsx ~ line 32 ~ Object.keys ~ hereNow',
    //   hereNow
    // );
    Object.keys(hereNow).forEach((identity) => {
      if (users[identity] && users[identity].isOnline) {
        const isAdmin = conversation?.attributes?.admins?.find(
          (admin) => admin.email === identity
        );
        if (isAdmin) {
          admins.push(users[identity]);
        } else {
          participants.push(users[identity]);
        }
      }
    });
    admins.sort(sortParticipantsByLastName(users));
    participants.sort(sortParticipantsByLastName(users));

    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          // height: 500,
          width: '100%',
          height: '100%',

          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            // height: 500,
            width: '100%',
            height: '100%',
            // border: '1px solid',
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            // backgroundColor: 'purple',
          }}
        >
          {admins && (
            <div
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                color: colors.main,
              }}
            >
              {exhibitionId ? 'Booth Staff' : 'Poster Presenter'}
            </div>
          )}
          {admins &&
            admins.map((participant, index) => (
              <div
                key={`admins_content_${conversation.sid}_${participant.sid}`}
              >
                <ChatProfileRow profileUser={participant} page={undefined} />
              </div>
            ))}
          {participants && (
            <div
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                color: colors.main,
              }}
            >
              Participants
            </div>
          )}
          {participants &&
            participants.map((participant, index) => (
              <div
                key={`participants_content_${conversation.sid}_${participant.sid}`}
              >
                <ChatProfileRow profileUser={participant} page={undefined} />
              </div>
            ))}
        </div>
      </div>
    );
  }
);

export default ChatConversationHereNow;
