import React from 'react';
import './ReloadPrompt.css';
import { BUILD_DATE } from './config';

// @ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/react';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = BUILD_DATE;
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__';

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r &&
          setInterval(() => {
            // eslint-disable-next-line no-console
            console.log('Checking for sw update');
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        // eslint-disable-next-line prefer-template,no-console
        console.log('SW Registered: ', r);
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  const action = (
    <React.Fragment>
      {!offlineReady && (
        <Button
          className="ReloadPrompt-toast-button"
          color="primary"
          size="small"
          variant="contained"
          onClick={() => updateServiceWorker(true)}
          style={{ marginRight: 10 }}
        >
          Reload
        </Button>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
        className="ReloadPrompt-toast-button"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="ReloadPrompt-container">
      <Snackbar
        open={needRefresh}
        // autoHideDuration={6000}
        onClose={close}
        message={
          offlineReady
            ? 'App ready to work offline'
            : 'New content available, click on reload button to update.'
        }
        action={action}
      />
      <div className="ReloadPrompt-date">{buildDate}</div>
    </div>
  );
}

export default ReloadPrompt;
