import twilioLeave from './twilioLeave';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
export default function twilioLeaveIfNotFollowing(conversationSid) {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    const conversation = getState().twilio.conversations?.[conversationSid];
    if (conversation?.client?.participants) {
      conversation.client.participants.forEach((participant) => {
        if (participant.identity === getTwilioUserIdentity(user)) {
          if (!participant.attributes.following) {
            dispatch(twilioLeave(conversation.client.sid, participant.sid));
          }
        }
      });
    }
  };
}
