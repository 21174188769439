import { eventId } from '../../config';
import trackAction from './tracker';
import { queryApi } from '../../Services/queryApi';
import { addBriefcaseEle } from '../Reducers/briefcase';

export default function addBriefcaseElement(
  resourceType,
  resourceId,
  resourceTitle,
  resourceOwner,
  accountId,
  accountName,
  presenter,
  startTime
) {
  let accountIdOptional = null;
  let accountNameOptional = null;
  let presenterOptional = null;
  let startTimeOptional = null;
  if (accountId) {
    accountIdOptional = accountId;
  }
  if (accountName) {
    accountNameOptional = accountName;
  }
  if (presenter) {
    presenterOptional = presenter;
  }
  if (startTime) {
    startTimeOptional = startTime;
  }
  return (dispatch, getState) => {
    const items = getState().debug;
    const event = {
      action: 'Added',
      resource_type: 'Briefcase',
      resource_id: resourceId,
      resource_account_id: resourceOwner,
      resource_name: resourceTitle,
      url_visited: `/${resourceType}`,
    };

    dispatch(trackAction(event));

    const payload = {
      eventId: eventId,
      data: {
        resource_type: resourceType,
        resource_id: resourceId,
        resource_title: resourceTitle,
        resource_owner: resourceOwner,
        account_id: accountIdOptional,
        company: accountNameOptional,
        presenter: presenterOptional,
        start_time: startTimeOptional,
      },
    };
    dispatch(queryApi.endpoints.addBriefcase.initiate(payload))
      .then((response) => {
        dispatch(addBriefcaseEle(response.data));
      })
      .catch((error) => {
        if (items.debug) {
          alert(`Error: Could not add to the briefcase. ${error}`);
        }
      });
  };
}
