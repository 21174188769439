import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SyncClient, SyncDocument } from 'twilio-sync';
import { getConversations, getUsers } from '../Store/Reducers/twilio';
import twilioAddHereNow from '../Store/Actions/twilioAddHereNow';
import twilioRemoveHereNow from '../Store/Actions/twilioRemoveHereNow';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import usersIcon from '../Assets/Icons/users.png';
import twilioSubscribeToIdentity from '../Store/Actions/twilioSubscribeToIdentity';
import getTwilioSyncClient from '../Utilities/getTwilioSyncClient';
import { getUser } from '../Store/Reducers/user';
import { getSyncClient } from '../Store/Reducers/twilio';

export default function ActiveUsers({
  uniqueName,
  friendlyName,
  embedded,
  onClick,
}) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const syncClient: SyncClient = useSelector(getSyncClient);

  const conversations: any[] = useSelector(getConversations);
  const conversationSid: string | undefined = Object.keys(conversations).find(
    (sid) => conversations[sid].uniqueName === uniqueName
  );
  const conversation = conversations?.[conversationSid as string];

  let followingCount = 0;
  if (conversation) {
    // console.log(
    //   '🚀 ~ file: ActiveUsers.tsx ~ line 33 ~ Object.keys ~ conversation.participants',
    //   Object.keys(conversation.participants).length
    // );
    Object.keys(conversation.participants).forEach((identity) => {
      if (conversation.participants[identity].attributes?.following) {
        // console.log(
        //   '🚀 ~ file: ActiveUsers.tsx ~ line 33 ~ Object.keys ~ conversation.participants[identity].attributes',
        //   typeof conversation.participants[identity].attributes,
        //   conversation.participants[identity].attributes
        // );
        // console.log(
        //   `${identity} following: ${conversation.participants[identity].attributes?.following}`
        // );
        followingCount++;
      }
    });
  }

  const users: any[] = useSelector(getUsers);
  const [hereNow, setHereNow] = useState({});

  useEffect(() => {
    let hereNowClient: SyncDocument;
    (async () => {
      hereNowClient = await syncClient.document(`HERE_NOW_${uniqueName}`);
      setHereNow(hereNowClient.data);
      hereNowClient.on('updated', function (args) {
        // console.log(
        //   `HERE_NOW_${uniqueName}`,
        //   JSON.stringify(args.data, null, 2)
        // );
        setHereNow(args.data);
      });
    })();
    return () => {
      if (hereNowClient?.close) hereNowClient.close();
    };
  }, [uniqueName]);

  useEffect(() => {
    (async () => {
      if (embedded) {
        dispatch(twilioAddHereNow(uniqueName));
      }
    })();
    return () => {
      if (embedded) {
        dispatch(twilioRemoveHereNow(uniqueName));
      }
    };
  }, [user, uniqueName, embedded]);

  useEffect(() => {
    (async () => {
      // Ensure we are following online status of everyone in the room
      if (hereNow && Object.keys(hereNow).length > 0 && users) {
        Object.keys(hereNow).forEach((identity) => {
          if (!users[identity]) {
            // console.log(`following ${identity} for online status`);
            dispatch(twilioSubscribeToIdentity(identity));
          }
        });
      }
    })();
  }, [hereNow, users]);

  const activeConnectedUsers = Object.keys(hereNow)?.filter((key) => {
    // console.log('key.isOnline', key, users?.[key]?.isOnline);
    return users?.[key]?.isOnline;
  });

  return (
    <div
      style={{
        fontSize: 14,
        color: '#757575',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 15,
        cursor: onClick && 'pointer',
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <img
        src={usersIcon}
        style={{ width: 15, height: 9, margin: 5 }}
        alt="users icon"
      />{' '}
      Visitors: {Object.keys(activeConnectedUsers).length} here now
      {followingCount > 0 && `, ${followingCount} following`}
    </div>
  );
}
