import { ADD_CHAT_SUBSCRIPTION } from './types';
import PubNub from 'pubnub';

export default function addChatSubscription(user, channel) {
  return async (dispatch, getState) => {
    const event = getState().event;

    const pubnub = new PubNub({
      publishKey: event.event.publish_key,
      subscribeKey: event.event.subscribe_key,
      uuid: user.email,
      autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
      restore: true, // enable catchup on missed messages
    });

    await pubnub.subscribe({
      channels: [channel],
      withPresence: true,
    });

    dispatch(addChatSubscriptionAsync(channel));
  };
}

function addChatSubscriptionAsync(channel) {
  return {
    type: ADD_CHAT_SUBSCRIPTION,
    payload: channel,
  };
}
