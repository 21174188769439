import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { eventId } from '../../config';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class VendorQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionText: this.props.response.content,
      submitted: false,
      validationError: false,
      error: false,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  questionTextUpdate(event) {
    this.setState({
      questionText: event.target.value,
    });
  }

  putResponse() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'put',
          url: `/vendors/response/${eventId}/${this.props.response.vendor_query_id}/${this.props.response.vendor_response_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            content: this.state.questionText,
          },
        }).then(
          (response) => {
            this.props.getResponses();

            this.setState({
              submitted: true,
            });
          },
          (error) => {
            this.setState({
              error: true,
            });
          }
        );
      })
      .catch((error) => {});
  }

  render() {
    const { classes } = this.props;

    if (this.state.submitted) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Response edited successfully!
          </Typography>
        </Box>
      );
    } else if (this.state.error) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            There was an error with your update. Please try again.
          </Typography>
        </Box>
      );
    }

    return (
      <React.Fragment>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Edit Your Response
          </Typography>
          <br />
          <br />
          <InputLabel id="demo-multiple-chip-label">
            Response Statement
          </InputLabel>
          <TextField
            value={this.state.questionText}
            multiline
            rows={4}
            maxRows={10}
            id="outlined-basic"
            variant="outlined"
            onChange={(event) => this.questionTextUpdate(event)}
            error={
              this.state.validationError && !this.state.questionText
                ? true
                : false
            }
          />
          <br />
          <br />
          <br />
          <br />
          <Button
            className={classes.buttonLearn}
            variant="contained"
            size="medium"
            onClick={() => this.putResponse()}
          >
            Edit Response
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //   getChatMessages: getChatMessages,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(VendorQuery));
