import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Auth } from 'aws-amplify';
import ExhibitCard from './ExhibitCard';
import ExhibitBooth from './ExhibitBooth';
import { withRouter } from 'react-router';
import ExhibitListItem from './ExhibitListItem';
import Filter from './ExhibitFilter';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  gridContainer: {
    marginTop: theme.spacing(2),
  },
});

class ExhibitionHall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitors: [],
      boothSelected: false,
      boothId: null,
      listFormat: false,
      filterString: '',
      filterCategory: 'account_name',
      pageCountStart: 1,
      perPage: 10,
      totalPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'exhibition_id') {
          this.setState({
            boothSelected: true,
            boothId: param[1],
          });
        }
        if (param[0] === 'product_id') {
          this.props
            .getProductBoothId(param[1])
            .then((response) => {
              //do nothing
            })
            .catch((error) => {
              if (this.props.debug.debug) {
                alert(`Error: Could not display Digital Lab. ${error}`);
              }
            });
        }
      }
    }
    this.getExhibits();
    this.props.setBriefcase();
  }

  getExhibits() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: '/exhibitions',
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            order_by: 'booth_type',
            order_dir: 'asc',
            search_columns: this.state.filterCategory,
            search_value: this.state.filterString,
          },
        }).then(
          (response) => {
            let authorizedExhibitors = response.data.data.filter(
              (exhibit) => exhibit.type === 'Digital Lab'
            );

            if (this.props.user.user.roles.includes('Booth Staff')) {
              authorizedExhibitors = response.data.data.filter(
                (exhibit) =>
                  exhibit.account_id === this.props.user.user.account_id ||
                  exhibit.is_public
              );
            }
            this.setState({
              exhibitors: authorizedExhibitors,
              totalPages: Math.ceil(
                response.data.recordsFiltered / this.state.perPage
              ),
            });
          },
          (error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Digital Lab. ${error}`);
            }
          }
        );
      })
      .catch((error) => {});
  }

  toggleBoothFullscreenOn = (event, id, company) => {
    this.setState({
      boothSelected: true,
      boothId: id,
      filterString: '',
      filterCategory: '',
    });
  };

  toggleBoothFullscreenOff = () => {
    this.setState({
      boothSelected: false,
      boothId: null,
    });
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - 9,
    });

    this.getExhibits();
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (string, category) => {
    this.setState({
      filterString: string,
      filterCategory: category,
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
    });
  };

  render() {
    const { classes } = this.props;
    let pageBody = null;

    let exhibitors;
    if (this.state.filterString && this.state.filterCategory) {
      exhibitors = this.state.exhibitors.filter((exhibit) => {
        return exhibit[this.state.filterCategory]
          .toLowerCase()
          .includes(this.state.filterString.toLowerCase());
      });
    } else {
      exhibitors = this.state.exhibitors;
    }

    if (this.state.boothSelected) {
      pageBody = (
        <ExhibitBooth
          boothId={this.state.boothId}
          closeBooth={this.toggleBoothFullscreenOff.bind(this)}
        />
      );
    } else {
      if (this.state.listFormat) {
        pageBody = (
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            spacing={2}
          >
            <Filter
              handleGridListToggle={this.handleGridListToggle.bind(this)}
              handleRemoveFilter={this.handleRemoveFilter.bind(this)}
              handleFilterClick={this.handleFilterClick.bind(this)}
              listFormat={this.state.listFormat}
              filterString={this.state.filterString}
              filterCategory={this.state.filterCategory}
              filtering={this.state.filtering}
            />
            <Grid item xs={12}>
              {exhibitors.map((exhibit, index) => {
                return (
                  <ExhibitListItem
                    key={index}
                    index={index}
                    exhibit={exhibit}
                    toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                      this
                    )}
                  />
                );
              })}
            </Grid>
          </Grid>
        );
      } else {
        pageBody = (
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            alignItems="stretch"
            spacing={2}
          >
            <Filter
              handleGridListToggle={this.handleGridListToggle.bind(this)}
              handleRemoveFilter={this.handleRemoveFilter.bind(this)}
              handleFilterClick={this.handleFilterClick.bind(this)}
              listFormat={this.state.listFormat}
              filterString={this.state.filterString}
              filterCategory={this.state.filterCategory}
              filtering={this.state.filtering}
            />
            {exhibitors.map((exhibit, index) => {
              switch (exhibit.booth_type) {
                case '1':
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                      <ExhibitCard
                        exhibit={exhibit}
                        index={index}
                        toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                          this
                        )}
                      />
                    </Grid>
                  );
                case '2':
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                      <ExhibitCard
                        exhibit={exhibit}
                        index={index}
                        toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                          this
                        )}
                      />
                    </Grid>
                  );
                case '3':
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                      <ExhibitCard
                        exhibit={exhibit}
                        index={index}
                        toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                          this
                        )}
                      />
                    </Grid>
                  );
                default:
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                      <ExhibitCard
                        exhibit={exhibit}
                        index={index}
                        toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                          this
                        )}
                      />
                    </Grid>
                  );
              }
            })}
          </Grid>
        );
      }
    }
    return pageBody;
  }
}

function mapStateToProps(state, props) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    debug: state.debug,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
      getProductBoothId: (productId) =>
        queryApi.endpoints.getProductBoothId.initiate(productId),
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ExhibitionHall)));
