import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  agendas: null,
  networking: null,
};

export const agendaSlice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    setAgendas: (state, action) => {
      state.agendas = action.payload;
    },
    resetAgendas: (state, action) => {
      state.agendas = null;
    },
    setNetworking: (state, action) => {
      state.networking = action.payload;
    },
  },
});

// Selectors
export const getAgendas = (state) => state.agenda.agendas;
export const getNetworking = (state) => state.agenda.networking;

// each case under reducers becomes an action
export const { setAgendas, setNetworking } = agendaSlice.actions;

export default agendaSlice.reducer;
