import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import trackAction from '../../Store/Actions/tracker';
import isOnline from '../../Utilities/isOnline';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
      // textAlign: 'center',
    },
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },

  buttonLearn: {
    minWidth: '80%',
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
});

class PosterListItem extends Component {
  componentDidMount() {
    this.props.getBriefcase(eventId);
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  groupAdd() {
    try {
      if (!this.props.poster.groupInterested) {
        this.props.addPosterGroup({
          eventId: eventId,
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type,
          },
        });

        this.props.trackAction(event);

        this.props.handleGroupAdd();
      } else {
        this.props.deletePosterGroup({
          eventId: eventId,
          abstractId: this.props.poster.abstract_id,
          data: {
            resource_id: this.props.poster.abstract_id,
          },
        });
        this.props.handleGroupAdd();
      }
    } catch (error) {
      console.log(error);
    }
  }

  upvotePoster() {
    try {
      if (!this.props.poster.liked) {
        this.props.likePoster({
          eventId: eventId,
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type,
          },
        });
        const event = {
          action: 'Liked',
          resource_type: this.props.poster.accepted_type,
          resource_id: this.props.poster.abstract_id,
          url_visited: '/posterhall',
          resource_account_id: this.props.poster.account_id,
          resource_account_name: null,
          resource_name: this.props.poster.title,
        };

        this.props.trackAction(event);

        this.props.handleUpvote();
      } else {
        this.props.deleteLikePoster({
          eventId: eventId,
          abstractId: this.props.poster.abstract_id,
          data: {
            resource_id: this.props.poster.abstract_id,
          },
        });

        this.props.handleUpvote();
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const poster = this.props.poster;
    const online = this.props.online;

    let favorited = false;
    if (this.props.briefcase.data) {
      for (let n = 0; n < this.props.briefcase.data.length; n++) {
        if (
          this.props.briefcase.data[n].resource_id ===
          this.props.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let firstPresenter = null;

    let contactsSection = null;
    if (this.props.poster?.contacts) {
      this.props.poster.contacts.forEach((contact) => {
        if (
          contact.roles.includes('presenter') ||
          contact.roles.includes('Presenter')
        ) {
          if (!firstPresenter) {
            firstPresenter = {
              first_name: contact.first_name,
              last_name: contact.last_name,
              account_name: contact.account_name
                ? contact.account_name
                : contact.department,
            };
          }
        }
      });
    }

    contactsSection = this.props.poster?.contacts?.map((contact, index) => {
      if (
        contact.roles.includes('presenter') ||
        contact.roles.includes('Presenter')
      ) {
        return (
          // <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography className={classes.heading}>
            <b>{contact.first_name + ' ' + contact.last_name}</b>{' '}
            {contact.account_name
              ? ` - ${contact.account_name}`
              : ` - ${contact.department}`}
          </Typography>
          // </Grid>
        );
      }
      return null;
    });

    return (
      <Accordion key={`PosterListItem${poster.index}`} className={classes.root}>
        <AccordionSummary
          className={classes.expansionPanelMain}
          expandIcon={<ExpandMoreIcon />}
          id={poster.abstract_id}
        >
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Tooltip
                title={
                  online ? (
                    <Typography variant="subtitle2">
                      I want to save this poster to my briefcase to access later
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
                aria-label="add"
              >
                {favorited ? (
                  <IconButton
                    aria-label="remove from briefcase"
                    onClick={(event) => {
                      if (online) {
                        event.stopPropagation();
                        this.props.deleteBriefcaseElement(
                          this.props.poster.abstract_id
                        );
                      }
                    }}
                  >
                    <WorkIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="add to briefcase"
                    onClick={(event) => {
                      if (online) {
                        event.stopPropagation();
                        this.props.addBriefcaseElement(
                          'Poster',
                          this.props.poster.abstract_id,
                          this.props.poster.title,
                          this.props.poster.account_id,
                          this.props.user.user.account_id,
                          this.props.poster.account_name,
                          firstPresenter
                        );
                      }
                    }}
                  >
                    <WorkOutlineIcon />
                  </IconButton>
                )}
              </Tooltip>
              {/* </Grid>
            <Grid item xs={1}> */}
              <Tooltip
                title={
                  online ? (
                    <Typography variant="subtitle2">
                      I like this poster
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
                aria-label="add"
              >
                <IconButton
                  aria-label="upvote"
                  onClick={(event) => {
                    if (online) {
                      event.stopPropagation();
                      this.upvotePoster();
                    }
                  }}
                  onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                  onMouseLeave={() => this.handleChange(false, 'buttonHover')}
                >
                  {this.props.poster.liked ? (
                    <ThumbUpIcon />
                  ) : (
                    <ThumbUpIconOutlined />
                  )}
                  <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                    {this.props.poster.count}
                  </Typography>
                </IconButton>
              </Tooltip>
              {/* </Grid>
            <Grid item xs={1}> */}
              <Tooltip
                title={
                  online ? (
                    <Typography variant="subtitle2">
                      I am interested in potential uses of this
                      technology/process in my group
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
                aria-label="add"
              >
                <IconButton
                  aria-label="group interest"
                  onClick={(event) => {
                    if (online) {
                      event.stopPropagation();
                      this.groupAdd();
                    }
                  }}
                  onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                  onMouseLeave={() => this.handleChange(false, 'buttonHover')}
                >
                  {this.props.poster.groupInterested ? (
                    <GroupIcon />
                  ) : (
                    <GroupAddOutlinedIcon />
                  )}
                  <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                    {this.props.poster.groupCount}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography className={classes.heading}>
                {poster.title ? poster.title : ''}
              </Typography>
              {contactsSection}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography className={classes.heading}>
              {poster.presenter_first_name && poster.presenter_last_name ? poster.presenter_first_name + " " + poster.presenter_last_name : ''}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Typography className={classes.heading}>
              {poster.presenter_title ? poster.presenter_title : ''}
            </Typography>
          </Grid> */}
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Typography className={classes.heading}>
                {poster.account_name ? poster.account_name : ''}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} sm={12} md={7} lg={10}>
              <Typography className={classes.textBody}>
                <b>Topic:</b> {poster.topic.split(';').join(', ')}
              </Typography>
              <Typography className={classes.textBody}>
                {poster.account_name ? (
                  <>
                    <b>Company:</b> {poster.account_name}
                  </>
                ) : null}
              </Typography>
              <Typography className={classes.textBody}>
                {poster.cleared_for_public_view === 'Yes - Approved' ? (
                  <React.Fragment>
                    <b>Access: </b>Public
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>Access: </b>Private
                  </React.Fragment>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} align="right">
              <Button
                className={classes.buttonLearn}
                variant="contained"
                size="medium"
                onClick={(event) =>
                  this.props.toggleFullscreenHandlerOn(
                    event,
                    this.props.poster.abstract_id
                  )
                }
              >
                Poster Details
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    user: state.user,
    online: isOnline(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      getBriefcase: () => queryApi.endpoints.getBriefcase.initiate(eventId),
      likePoster: (payload) => queryApi.endpoints.likePoster.initiate(payload),
      deleteLikePoster: (payload) =>
        queryApi.endpoints.deleteLikePoster.initiate(payload),
      addPosterGroup: (payload) =>
        queryApi.endpoints.addPosterGroup.initiate(payload),
      deletePosterGroup: (payload) =>
        queryApi.endpoints.deletePosterGroup.initiate(payload),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterListItem));
