import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  briefcase: [],
};

export const briefcaseSlice = createSlice({
  name: 'briefcase',
  initialState,
  reducers: {
    setBriefcase: (state, action) => {
      state.briefcase = action.payload;
    },
    resetBriefcase: (state, action) => {
      state.briefcase = null;
    },
    addBriefcaseEle: (state, action) => {
      state.briefcase = [...state.briefcase, action.payload];
    },
    deleteBriefcaseEle: (state, action) => {
      state.briefcase = state.briefcase.filter((element) => {
        return element.resource_id !== action.payload;
      });
    },
  },
});

// Selectors
export const getBriefcase = (state) => state.briefcase.briefcase;

// each case under reducers becomes an action
export const {
  setBriefcase,
  resetBriefcase,
  addBriefcaseEle,
  deleteBriefcaseEle,
} = briefcaseSlice.actions;

export default briefcaseSlice.reducer;
