import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ImageMapper from 'react-image-mapper';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import trackAction from '../Store/Actions/tracker';
import Hidden from '@material-ui/core/Hidden';
import {
  eventId,
  lobbyImage,
  lobbyImageMap,
  drawerWidth,
  landingPage,
} from '../config';

// Configure Redux
import { connect } from 'react-redux';
import ChatScreen from './RootScreen';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import startupAsync from '../Thunks/StartupAsync';
import { bindActionCreators } from 'redux';
import isOnline from '../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = (theme) => ({
  gridContainer: {
    margin: '0px -24px 0px -24px',
  },
  imageMapper: {
    display: 'block',
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    position: 'absolute',
    zIndex: '2',
    cursor: 'pointer',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperMobile: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '120px',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  banner: {
    width: '13vw',
    minHeight: '5vw',
  },
  sponsorLogo: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
  },
  sponsorLogoMobile: {
    width: '90%',
    height: '100%',
    margin: theme.spacing(1),
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    borderRadius: '0px 0px 5px 5px',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  reference: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  mobileNav: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  mobileButtons: {
    width: '100%',
    height: '100%',
  },
  videoDialog: {
    height: 'auto',
    padding: theme.spacing(2),
  },
});

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: window.innerWidth * 0.955,
      imageHeight: window.innerHeight * 0.955,
      imageClicked: null,
      modalOpen: false,
      rotationPosition: 1,
      optOut: null,
      optOutModalOpen: false,
      tooltipText: null,
      tooltipCoords: [],
    };
  }

  componentDidMount() {
    let optOutRequired = false;
    JSON.parse(this.props.event.event.available_routes).routes.forEach(
      (route) => {
        if (route.container === 'ExhibitHall') {
          optOutRequired = true;
        }
      }
    );
    window.addEventListener('resize', this.updateCoordinates);
    this.startSponsorChange();

    if (optOutRequired) {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'get',
            url: `/optout/${eventId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
          }).then((response) => {
            if (response.data.length) {
              if (!response.data[0].opted_out) {
                this.setState({
                  optOut: false,
                });
              } else {
                this.setState({
                  optOut: true,
                });
              }
            } else {
              this.setState({
                optOut: false,
                optOutModalOpen: true,
              });
            }
          });
        })
        .catch((error) => {
          if (this.props.debug.debug) {
            alert(`Error: Could not process submission. ${error}`);
          }
        });
    }

    const event = {
      action: 'Viewed',
      resource_type: 'Lobby',
      resource_id: null,
      url_visited: '/lobby',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  changeSponsor = () => {
    if (
      this.state.rotationPosition <
      JSON.parse(this.props.event.event.rotating_sponsors).sponsors.length - 1
    ) {
      this.setState({
        rotationPosition: this.state.rotationPosition + 1,
      });
    } else {
      this.setState({
        rotationPosition: 1,
      });
    }
  };

  startSponsorChange = () => {
    this.intervalID = setInterval(this.changeSponsor, 2000);
  };

  stopSponsorChange = () => {
    clearInterval(this.intervalID);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
    this.stopSponsorChange();
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;
    let height = window.innerHeight * 0.955;

    this.setState({
      imageWidth: width,
      imageHeight: height,
    });
  };

  imageClick(event, item) {
    if (event.name === 'welcomeVideo') {
      this.setState({
        modalOpen: true,
      });

      const event = {
        action: 'Viewed',
        resource_type: 'Welcome Video',
        resource_id: null,
        url_visited: '/lobby',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      this.props.trackAction(event);
    } else if (event.name === 'planetconnect') {
      const event = {
        action: 'Clicked',
        resource_type: 'Planetconnect',
        resource_id: null,
        url_visited: '/lobby',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      this.props.trackAction(event);
      window.open('https://www.planetconnect.com/', '_blank');
    } else if (event.name === 'caricatures') {
      const event = {
        action: 'Clicked',
        resource_type: 'Caricature',
        resource_id: null,
        url_visited: '/lobby',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      this.props.trackAction(event);
      window.open('https://ocmvirtual.com/PerkinElmer', '_blank');
    } else if (event.name === 'mmiposterhall') {
      const event = {
        action: 'Clicked',
        resource_type: 'Merck Missions Imaging Posters',
        resource_id: null,
        url_visited: '/lobby',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      this.props.trackAction(event);
      window.open('https://mmivirtual.planetconnect.com/posterhall', '_blank');
    } else if (event.name === 'signature') {
      const signatureSponsor = item;
      const event = {
        action: 'Clicked',
        resource_type: 'Signature Lobby Banner',
        resource_id: null,
        url_visited: '/lobby',
        resource_account_id: signatureSponsor.id,
        resource_account_name: signatureSponsor.title,
        resource_name: 'Signature Lobby Banner',
      };

      this.props.trackAction(event);

      this.props.history.push(signatureSponsor.name);
    } else {
      this.props.history.push(`/${event.name}`);
    }
  }

  rotatingBannerImageClick(event, item) {
    const trackEvent = {
      action: 'Clicked',
      resource_type: 'Lobby Rotating Sponsorship',
      resource_id: event.name.split('=')[1],
      url_visited: '/lobby',
      resource_account_id: null,
      resource_account_name: event.title,
      resource_name: null,
    };

    this.props.trackAction(trackEvent);

    this.props.history.push(`/${event.name}`);
  }

  welcomeClose(event) {
    this.setState({
      modalOpen: false,
    });
  }

  optOutClose(event) {
    this.setState({
      optOutModalOpen: false,
    });
  }

  selectOptOutStatus() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            opted_out: this.state.optOut,
          },
        }).then((response) => {
          this.setState({
            optOutModalOpen: false,
          });
        });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`);
        }
      });
  }

  imageMapperMouseEnter(event, item) {
    let tooltipText;

    switch (item) {
      case 1:
        tooltipText =
          'Browse the daily schedule, review abstracts, read speaker bios and add events to your calendar.';
        break;
      case 2:
        tooltipText =
          'View various opportunities to gather with friends and colleagues for casual conversation.';
        break;
      case 3:
        tooltipText =
          'Discover new scientific content presented in over 50 posters and introductory videos.';
        break;
      case 4:
        tooltipText =
          'Find presentations organized by track or topic.  An easy way to navigate to sessions of interest.';
        break;
      case 5:
        tooltipText =
          'Check out the schedule and descriptions of over 20 interactive sessions.  Join us for this new feature of the Summit!';
        break;
      default:
    }

    this.setState({
      tooltipText,
      tooltipCoords: [event.scaledCoords[0], event.scaledCoords[1]],
    });
  }

  imageMapperMouseLeave() {
    this.setState({
      tooltipText: null,
      tooltipCoords: [],
    });
  }

  handleChange = () => {
    if (this.props.online) {
      this.setState({
        optOut: !this.state.optOut,
      });
    }
  };

  handleSwitchChange = (optOut) => {
    if (optOut) {
      this.setState({
        optOut: true,
      });
    } else {
      this.setState({
        optOut: false,
      });
    }
  };
  render() {
    // console.log('Rendering Lobby');

    const { classes } = this.props;
    const online = this.props.online;

    let color;
    let lightColor;
    let level;
    let lobbyRotatingSponsors = null;
    let allRotatingSponsors = JSON.parse(
      this.props.event.event.rotating_sponsors
    ).sponsors;
    let signatureSponsor;

    if (
      this.props.event?.event?.rotating_sponsors &&
      allRotatingSponsors.length > 0
    ) {
      signatureSponsor = JSON.parse(
        this.props.event.event.rotating_sponsors
      ).sponsors?.filter((sponsor) => sponsor.level === 'Signature');
      if (signatureSponsor.length) {
        signatureSponsor = signatureSponsor[0];
        signatureSponsor.id = signatureSponsor.name.substring(
          signatureSponsor.name.length,
          signatureSponsor.name.indexOf('=') + 1
        );
      }
    }
    // console.log(this.state.rotationPosition);
    // console.log(allRotatingSponsors[this.state.rotationPosition]);
    if (
      this.props.event?.event?.rotating_sponsors &&
      allRotatingSponsors.length > 0
    ) {
      switch (allRotatingSponsors[this.state.rotationPosition].level) {
        // case 'Signature':
        //   color = '#0600b1';
        //   lightColor = '#554fff';
        //   level = 'Signature';
        //   break;
        case 'Platinum':
          color = '#996ae6';
          lightColor = '#c2a7f0';
          level = 'Platinum';
          break;
        case 'Gold':
          color = '#f2cd05';
          lightColor = '#f7e372';
          level = 'Gold';
          break;
        default:
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Silver';
          break;
      }

      lobbyRotatingSponsors = (
        <Grid
          item
          xs={12}
          align="left"
          className={classes.header}
          onClick={() =>
            this.rotatingBannerImageClick(
              allRotatingSponsors[this.state.rotationPosition]
            )
          }
        >
          {/* <Paper className={classes.paper + ' ' + classes.banner}> */}
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              align="center"
              justifyContent="space-between"
            >
              <Grid
                item
                style={{ height: '8vw', width: '12vw', margin: '8px' }}
              >
                <div
                  className={classes.sponsorLogo}
                  style={{
                    backgroundImage: `url('${
                      JSON.parse(this.props.event.event.rotating_sponsors)
                        .sponsors[this.state.rotationPosition].logo
                    }')`,
                    display: 'inline-flex',
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.sponsorLevel}
                  align="center"
                  color="textPrimary"
                  style={{
                    backgroundImage: `linear-gradient(${color}, ${lightColor})`,
                  }}
                >
                  {level}
                  {/* <Hidden xsDown> Sponsor</Hidden> */}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }

    let mobileRotatingSponsors;
    if (
      this.props.event?.event?.rotating_sponsors &&
      allRotatingSponsors.length > 0
    ) {
      let signatureColor = '#0600b1';
      let signatureLightColor = '#554fff';
      // let signatureObject = {};

      mobileRotatingSponsors = (
        <>
          <Grid
            item
            xs={6}
            onClick={() => this.rotatingBannerImageClick(signatureSponsor)}
          >
            <Paper className={classes.paperMobile}>
              {/* <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '60%',
                  padding: '0px 8px',
                }}
                src={signatureSponsorLogo}
                alt="logo"
              /> */}
              <div
                className={classes.sponsorLogoMobile}
                style={{
                  backgroundImage: `url('${signatureSponsor.logo}')`,
                  display: 'inline-flex',
                }}
              />
              <Typography
                variant="h6"
                className={classes.sponsorLevel}
                align="center"
                color="textPrimary"
                style={{
                  backgroundImage: `linear-gradient(${signatureColor}, ${signatureLightColor})`,
                }}
              >
                Signature
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() =>
              this.rotatingBannerImageClick(
                allRotatingSponsors[this.state.rotationPosition]
              )
            }
          >
            <Paper className={classes.paperMobile}>
              {allRotatingSponsors.length > 0 ? (
                <div
                  className={classes.sponsorLogoMobile}
                  style={{
                    backgroundImage: `url('${
                      JSON.parse(this.props.event.event.rotating_sponsors)
                        .sponsors[this.state.rotationPosition].logo
                    }')`,
                    display: 'inline-flex',
                  }}
                />
              ) : null}
              <Typography
                variant="h6"
                className={classes.sponsorLevel}
                align="center"
                color="textPrimary"
                style={{
                  backgroundImage: `linear-gradient(${color}, ${lightColor})`,
                }}
              >
                {level}
              </Typography>
            </Paper>
          </Grid>
        </>
      );
    }

    let welcomeVideo = (
      <div
        style={{
          position: 'relative',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <Hidden mdUp>
          <iframe
            title="welcome video"
            width={this.state.imageWidth * 0.66}
            height={((this.state.imageWidth * 0.66) / 16) * 9}
            // width={this.state.imageHeight * 0.66}
            // height={((this.state.imageHeight * 0.66) / 16) * 9}
            src={this.props.event.event.welcome_video_location}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Hidden>
        <Hidden smDown>
          <iframe
            title="welcome video"
            // width={this.state.imageWidth * 0.66}
            // height={((this.state.imageWidth * 0.66) / 16) * 9}
            width={this.state.imageHeight * 0.8}
            height={((this.state.imageHeight * 0.8) / 16) * 9}
            src={this.props.event.event.welcome_video_location}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Hidden>
      </div>
    );

    let optOutComponent = (
      <Grid container layout={'row'} spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Welcome to the Digital Venue for the {this.props.event.event.title}.
            We're glad you're here!
          </Typography>
          <br />
          <br />
          <Typography variant="body1" align="justify">
            <b>If your Event has Supplier Exhibitors:</b>
            <br />
            Supplier Exhibitors and Sponsors help us make events successful!
            Exhibitors will be provided with your lead information, if you show
            interest in that Company or their products/services. Please check
            the privacy page for more information. If you would like your
            contact information to not be disseminated to exhibitors, you may
            opt-out below. You may change your status at any time by going to
            the Settings page.
          </Typography>
          <br />
          <Tooltip
            title={
              !online && (
                <Typography variant="subtitle2">
                  User is Offline. Cannot perform action.
                </Typography>
              )
            }
          >
            <Button onClick={() => this.handleChange()}>
              <Typography variant="body1" align="justify">
                Opt In
              </Typography>
              <Switch
                checked={this.state.optOut}
                onChange={this.handleChange}
                color="primary"
              />
              <Typography variant="body1" align="justify">
                Opt Out
              </Typography>
            </Button>
          </Tooltip>
          <br />
          {this.state.optOut ? (
            <Typography variant="body1" align="justify">
              Your personal information will not be disseminated to exhibitors.
            </Typography>
          ) : (
            <br />
          )}
          <br />
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              if (online) {
                this.selectOptOutStatus();
              }
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );

    let pageWidth = !this.props.sidebar.open
      ? this.state.imageWidth
      : window.innerWidth - drawerWidth;
    const mobileNavButtons = [];
    lobbyImageMap.areas.forEach((item, index) => {
      if (item.formalName !== 'Signature') {
        let formalName = '';
        JSON.parse(
          this.props.event?.event?.available_routes || '{}'
        )?.routes?.forEach((route) => {
          if (item.href === route.route) {
            formalName = route.title;
          }
        });
        if (item.formalName !== 'PlanetConnect') {
          mobileNavButtons.push(
            <Grid item xs={12} style={{ minHeight: '50px' }} key={index}>
              <Button
                variant="contained"
                color="primary"
                className={classes.mobileButtons}
                onClick={() => this.imageClick(item)}
              >
                <Typography variant="h6" style={{ padding: '8px 0px' }}>
                  {formalName ? formalName : item.formalName}
                </Typography>
              </Button>
            </Grid>
          );
        }
      }
    });
    let extraPages = [
      { formalName: 'Engagement Zone', name: 'engagementzone' },
      { formalName: 'QR Scanner', name: 'scan' },
      { formalName: 'Briefcase', name: 'briefcase' },
      { formalName: 'My Personal Agenda', name: 'mypersonalagenda' },
      { formalName: 'Resources', name: 'event' },
    ];
    extraPages.forEach((item, index) => {
      mobileNavButtons.push(
        <Grid
          item
          xs={12}
          style={{ minHeight: '50px' }}
          key={item.formalName + index}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.mobileButtons}
            onClick={() => this.imageClick(item)}
          >
            <Typography variant="h6" style={{ padding: '8px 0px' }}>
              {item.formalName}
            </Typography>
          </Button>
        </Grid>
      );
    });
    let mobileNavMenu = (
      <Grid container spacing={1} className={classes.mobileNav}>
        <img
          src={
            this.props.event
              ? this.props.event.event.banner_location
              : landingPage.banner
          }
          alt="Lobby"
          style={{
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            position: 'relative',
            padding: 0,
            margin: 0,
            top: '-4px',
          }}
        />
        {mobileNavButtons}
        {mobileRotatingSponsors}
      </Grid>
    );

    return (
      <Grid
        className={classes.gridContainer}
        container
        layout={'row'}
        align="center"
        justifyContent="center"
      >
        <Grid item className={classes.imageMapper} xs={12}>
          <Dialog
            open={this.state.modalOpen}
            maxWidth={'lg'}
            onClose={(event) => this.welcomeClose()}
            // PaperProps={{ style: { padding: 10} }}
            classes={{ paper: classes.videoDialog }}
          >
            {welcomeVideo}
          </Dialog>
          <Dialog
            open={this.state.optOutModalOpen}
            onClose={(event) => this.welcomeClose()}
            PaperProps={{ style: { padding: 20 } }}
          >
            {optOutComponent}
          </Dialog>
          <Hidden xsDown>
            {lobbyRotatingSponsors}
            <ImageMapper
              // onMouseEnter={(event, item) => this.imageMapperMouseEnter(event, item)}
              // onMouseLeave={(event, item) => this.imageMapperMouseLeave(event, item)}
              width={pageWidth}
              imgWidth={2000}
              src={lobbyImage}
              map={lobbyImageMap}
              onClick={(event) => this.imageClick(event, signatureSponsor)}
            />
          </Hidden>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12}>
            {mobileNavMenu}
          </Grid>
        </Hidden>
        {this.state.tooltipText ? (
          <Typography
            variant="h6"
            align="center"
            color="textPrimary"
            style={{
              zIndex: '99999999999999',
              position: 'absolute',
              left: `${this.state.tooltipCoords[0]}px`,
              top: `${this.state.tooltipCoords[1] - 100}px`,
              backgroundColor: 'white',
              // border: '1px black solid',
              borderRadius: '15px',
              padding: '15px',
              // height: '100px',
              // width: '100px'
            }}
          >
            {this.state.tooltipText}
          </Typography>
        ) : null}
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startupAsync: startupAsync,
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
    online: isOnline(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withRouter(withStyles(useStyles)(Lobby)));
