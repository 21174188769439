import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../config';

const getTwilioToken = async (conversationsServiceSid, syncServiceSid) => {
  const data = await Auth.currentSession();

  const response = await axios({
    method: 'get',
    url: `/twilio/token?conversationsServiceSid=${conversationsServiceSid}&syncServiceSid=${syncServiceSid}&eventId=${eventId}`,
    headers: { idtoken: data.idToken.jwtToken },
  });

  return response.data.token;
};

export default getTwilioToken;
