import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
// import trackAction from '../../Utilities/tracker';
import Dialog from '../../Components/DialogWrapper';
import CreateResponse from './CreateResponse';
import EditResponse from './EditResponse';
import DeleteResponse from './DeleteResponse';

//Chat Functions
import ChatProfile from '../../Components/ChatProfile';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'initial',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
    textTransform: 'initial',
  },
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
      // textAlign: 'center',
    },
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },

  buttonLearn: {
    minWidth: '80%',
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
});

class ChallengeListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      expanded: false,
      responses: [],
      selected: false,
      createResponseModalOpen: false,
      editResponseModalOpen: false,
      deleteResponseModalOpen: false,
      modalIndex: null,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  toggleAccordian() {
    if (!this.state.expanded && !this.state.responses.length) {
      this.getResponses();
    }

    this.setState({
      expanded: !this.state.expanded,
    });
  }

  async getResponses() {
    try {
      const data = await Auth.currentSession();

      let responses = await axios({
        method: 'GET',
        url: `/vendors/response/${eventId}/${this.props.challenge.vendor_query_id}`,
        headers: {
          idtoken: data.idToken.jwtToken,
        },
      });

      this.setState({
        responses: responses.data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async upvoteChallenge() {
    try {
      const data = await Auth.currentSession();

      if (!this.props.challenge.liked) {
        await axios({
          method: 'POST',
          url: `/likes/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.challenge.vendor_query_id,
            resource_type: 'Vendor Query',
          },
        });

        this.props.handleUpvote();
      } else {
        await axios({
          method: 'DELETE',
          url: `/likes/${eventId}/${this.props.challenge.vendor_query_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.challenge.vendor_query_id,
          },
        });
        this.props.handleUpvote();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async groupAdd() {
    try {
      const data = await Auth.currentSession();

      if (!this.props.challenge.groupInterested) {
        await axios({
          method: 'POST',
          url: `/group/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.challenge.vendor_query_id,
            resource_type: 'Vendor Query',
          },
        });

        this.props.handleGroupAdd();
      } else {
        await axios({
          method: 'DELETE',
          url: `/group/${eventId}/${this.props.challenge.vendor_query_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.challenge.vendor_query_id,
          },
        });

        this.props.handleGroupAdd();
      }
    } catch (error) {
      console.log(error);
    }
  }

  createResponseModalOpen() {
    this.setState({
      createResponseModalOpen: true,
    });
  }

  createResponseModalClose() {
    this.setState({
      createResponseModalOpen: false,
    });
  }

  editResponseModalClose(index) {
    this.setState({
      editResponseModalOpen: false,
      modalIndex: null,
    });
  }

  editResponseModalOpen(index) {
    this.setState({
      modalIndex: index,
      editResponseModalOpen: true,
    });
  }

  deleteResponseModalClose(index) {
    this.setState({
      deleteResponseModalOpen: false,
      modalIndex: null,
    });
  }

  deleteResponseModalOpen(index) {
    this.setState({
      modalIndex: index,
      deleteResponseModalOpen: true,
    });
  }

  render() {
    const { classes } = this.props;
    const challenge = this.props.challenge;

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.challenge.vendor_query_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    const queryCreator = (
      <Grid item xs={6}>
        <Button
          onClick={async (event) => {
            event.stopPropagation();
            const me = this.props.user.user;

            const isMe = getTwilioUserIdentity(me) === challenge?.email;

            if (!isMe && challenge?.email) {
              this.props
                .twilioCreateJoinOneOnOneConversation(challenge?.email)
                .catch((error) => {
                  console.log(
                    '🚀 ~ file: AgendaRowItem.js ~ line 622 ~ onClick={ ~ User not registered',
                    error.message
                  );
                });
            }
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <ChatProfile
                  identity={challenge?.email}
                  size={'4rem'}
                  photo_url={challenge?.photo_url}
                  directory={undefined}
                  onClick={undefined}
                />
              </div>
              <Typography variant="body1" className={classes.lineSpeakerName}>
                {`${challenge.first_name} ${challenge.last_name}`}
              </Typography>
              <Typography variant="body1" className={classes.lineSpeakerTitle}>
                {challenge.title ? challenge.title : ''}
              </Typography>
              <Typography
                variant="body1"
                className={classes.lineSpeakerCompany}
              >
                {challenge.account_name ? challenge.account_name : ''}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );

    let createResponseModal = (
      <CreateResponse
        getResponses={() => this.getResponses()}
        challenge={challenge}
      />
    );

    let editResponseModal = (
      <EditResponse
        getResponses={() => this.getResponses()}
        response={this.state.responses[this.state.modalIndex]}
      />
    );

    let deleteResponseModal = (
      <DeleteResponse
        getResponses={() => this.getResponses()}
        response={this.state.responses[this.state.modalIndex]}
      />
    );

    let controlPanel;

    if (challenge.ownerPresent) {
      controlPanel = (
        <>
          <Grid item center xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={async (event) => {
                  event.stopPropagation();
                  this.props.editQueryModalOpen();
                }}
              >
                Edit Challenge
              </Button>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={async (event) => {
                  event.stopPropagation();
                  this.props.deleteQueryModalOpen();
                }}
              >
                Delete Challenge
              </Button>
            </div>
          </Grid>
        </>
      );
    } else if (
      this.state.responses.filter(
        (response) => response.author_id === this.props.user.user.attendee_id
      ).length
    ) {
      controlPanel = <></>;
    } else {
      controlPanel = (
        <>
          <Grid item center xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={async (event) => {
                  event.stopPropagation();
                  this.createResponseModalOpen();
                }}
              >
                Respond to Challenge
              </Button>
            </div>
          </Grid>
        </>
      );
    }

    return (
      <Accordion
        elevation={this.state.isHovering ? 8 : 1}
        key={`ChallengeListItem${challenge.index}`}
        className={classes.root}
        onMouseEnter={() => {
          this.handleChange(true, 'isHovering');
        }}
        onMouseLeave={() => this.handleChange(false, 'isHovering')}
      >
        <AccordionSummary
          className={classes.expansionPanelMain}
          expanded={this.state.expanded}
          expandIcon={<ExpandMoreIcon />}
          id={challenge.challenge_id}
          onClick={() => this.toggleAccordian()}
        >
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    I want to save this challenge to my briefcase to access
                    later
                  </Typography>
                }
                aria-label="add"
              >
                {favorited ? (
                  <IconButton
                    aria-label="remove from briefcase"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.deleteBriefcaseElement(
                        this.props.challenge.vendor_query_id
                      );
                    }}
                  >
                    <WorkIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="add to briefcase"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.addBriefcaseElement(
                        'Vendor Query',
                        this.props.challenge.vendor_query_id,
                        this.props.challenge.content,
                        this.props.challenge.account_id,
                        this.props.user.user.account_id
                      );
                    }}
                  >
                    <WorkOutlineIcon />
                  </IconButton>
                )}
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    I like this challenge
                  </Typography>
                }
                aria-label="add"
              >
                <IconButton
                  aria-label="upvote"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.upvoteChallenge();
                  }}
                  onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                  onMouseLeave={() => this.handleChange(false, 'buttonHover')}
                >
                  {this.props.challenge.liked ? (
                    <ThumbUpIcon />
                  ) : (
                    <ThumbUpIconOutlined />
                  )}
                  <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                    {this.props.challenge.count}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    I am interested in potential uses of this technology/process
                    in my group
                  </Typography>
                }
                aria-label="add"
              >
                <IconButton
                  aria-label="group interest"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.groupAdd();
                  }}
                  onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                  onMouseLeave={() => this.handleChange(false, 'buttonHover')}
                >
                  {this.props.challenge.groupInterested ? (
                    <GroupIcon />
                  ) : (
                    <GroupAddOutlinedIcon />
                  )}
                  <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                    {this.props.challenge.groupCount}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              {queryCreator}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography className={classes.heading}>
                {challenge.content ? challenge.content : ''}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            {controlPanel}
            {this.state.responses.map((response, index) => {
              let responseControls;
              if (response.email === this.props.user.user.email) {
                responseControls = (
                  <>
                    <Button
                      className={classes.buttonLearn}
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={async (event) => {
                        event.stopPropagation();
                        this.editResponseModalOpen(index);
                      }}
                    >
                      Edit Response
                    </Button>
                    <br />
                    <br />
                    <Button
                      color="primary"
                      className={classes.buttonLearn}
                      variant="contained"
                      size="medium"
                      onClick={async (event) => {
                        event.stopPropagation();
                        this.deleteResponseModalOpen(index);
                      }}
                    >
                      Delete Response
                    </Button>
                  </>
                );
              } else {
                responseControls = (
                  <Button
                    className={classes.buttonLearn}
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      this.props.history.push(
                        `/exhibithall?exhibition_id=${this.props.challenge.exhibition_id}`
                      );
                    }}
                  >
                    Go to Exhibitor
                  </Button>
                );
              }

              const queryResponder = (
                <Grid item xs={12}>
                  <Button
                    onClick={async (event) => {
                      event.stopPropagation();
                      const me = this.props.user.user;

                      const isMe =
                        getTwilioUserIdentity(me) === response?.email;

                      if (!isMe && response?.email) {
                        this.props
                          .twilioCreateJoinOneOnOneConversation(response?.email)
                          .catch((error) => {
                            console.log(
                              '🚀 ~ file: AgendaRowItem.js ~ line 622 ~ onClick={ ~ User not registered',
                              error.message
                            );
                          });
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <ChatProfile
                            identity={response?.email}
                            size={'4rem'}
                            photo_url={response?.photo_url}
                            directory={undefined}
                            onClick={undefined}
                          />
                        </div>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerName}
                        >
                          {`${response.first_name} ${response.last_name}`}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerTitle}
                        >
                          {response.title ? response.title : ''}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerCompany}
                        >
                          {response.account_name ? response.account_name : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              );

              return (
                <>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    {queryResponder}
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={7}>
                    {response.content}
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={3} align="right">
                    {responseControls}
                  </Grid>
                </>
              );
            })}
            {/* {this.props.challenge.vendorResponses.map((response) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={7} lg={10}>
                    {response.text}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} align="right">
                    <Button
                      className={classes.buttonLearn}
                      variant="contained"
                      size="medium"
                      // onClick={(event) =>
                      //   this.props.toggleFullscreenHandlerOn(
                      //     event,
                      //     this.props.poster.abstract_id
                      //   )
                      // }
                    >
                      Go to Exhibitor
                    </Button>
                    <Button
                      className={classes.buttonLearn}
                      variant="contained"
                      size="medium"
                      // onClick={(event) =>
                      //   this.props.toggleFullscreenHandlerOn(
                      //     event,
                      //     this.props.poster.abstract_id
                      //   )
                      // }
                    >
                      Chat with Responder
                    </Button>
                  </Grid>
                </>
              );
            })} */}

            {/* <Typography className={classes.textBody}>
                {poster.account_name ? (
                  <>
                    <b>Company:</b> {poster.account_name}
                  </>
                ) : null}
              </Typography> */}
            {/* <Typography className={classes.textBody}>
                {poster.cleared_for_public_view === 'Yes - Approved' ? (
                  <React.Fragment>
                    <b>Access: </b>Public
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>Access: </b>Private
                  </React.Fragment>
                )}
              </Typography> */}

            {/* <Button
                className={classes.buttonLearn}
                variant="contained"
                size="medium"
                // onClick={(event) =>
                //   this.props.toggleFullscreenHandlerOn(
                //     event,
                //     this.props.poster.abstract_id
                //   )
                // }
              >
                Poster Details
              </Button> */}
          </Grid>
        </AccordionDetails>
        <Dialog
          open={this.state.createResponseModalOpen}
          handleClose={this.createResponseModalClose.bind(this)}
        >
          {createResponseModal}
        </Dialog>
        <Dialog
          open={this.state.editResponseModalOpen}
          handleClose={this.editResponseModalClose.bind(this)}
        >
          {editResponseModal}
        </Dialog>
        <Dialog
          open={this.state.deleteResponseModalOpen}
          handleClose={this.deleteResponseModalClose.bind(this)}
        >
          {deleteResponseModal}
        </Dialog>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      twilioCreateJoinOneOnOneConversation:
        twilioCreateJoinOneOnOneConversation,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ChallengeListItem)));
