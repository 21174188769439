import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import trackAction from '../../Utilities/tracker';
import ChallengeListItem from './ChallengeListItem';
import { eventId } from '../../config';
import Dialog from '../../Components/DialogWrapper';
import VendorQuery from './VendorQuery';
import EditVendorQuery from './EditVendorQuery';
import DeleteVendorQuery from './RemoveVendorQuery';
import Button from '@material-ui/core/Button';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
  },
});

class VendorChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createQueryModalOpen: false,
      editQueryModalOpen: false,
      deleteQueryModalOpen: false,
      preSelectedChallenge: null,
      challenges: [],
      modalIndex: null,
    };
  }

  componentDidMount() {
    this.getChallenges();
  }

  getChallenges() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/vendors/query/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            response.data.map((challenge) => {
              if (challenge.email === this.props.user.user.email) {
                challenge.ownerPresent = true;
              }
            });

            if (this.props.location.search) {
              const query = new URLSearchParams(this.props.location.search);
              for (let param of query.entries()) {
                if (param[0] === 'vendor_query_id') {
                  this.setState({
                    preSelectedChallenge: param[1],
                    challenges: response.data,
                  });
                } else {
                  this.setState({
                    challenges: response.data,
                  });
                }
              }
            } else {
              this.setState({
                challenges: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpvote = (index) => {
    let items = [...this.state.challenges];
    let item = { ...items[index] };
    if (item.liked === true) {
      item.count = item.count - 1;
      item.liked = false;
    } else {
      item.count = item.count + 1;
      item.liked = true;
    }
    items[index] = item;
    this.setState({ challenges: items });
  };

  handleGroupAdd = (index) => {
    let items = [...this.state.challenges];
    let item = { ...items[index] };
    if (item.groupInterested === true) {
      item.groupInterested = false;
      item.groupCount = item.groupCount - 1;
    } else {
      item.groupInterested = true;
      item.groupCount = item.groupCount + 1;
    }
    items[index] = item;
    this.setState({ challenges: items });
  };

  vendorQueryModalClose(event) {
    this.setState({
      createQueryModalOpen: false,
    });
  }

  editQueryModalClose() {
    this.setState({
      editQueryModalOpen: false,
      modalIndex: null,
    });
  }

  editQueryModalOpen(index) {
    this.setState({
      modalIndex: index,
      editQueryModalOpen: true,
    });
  }

  deleteQueryModalClose() {
    this.setState({
      deleteQueryModalOpen: false,
      modalIndex: null,
    });
  }

  deleteQueryModalOpen(index) {
    this.setState({
      modalIndex: index,
      deleteQueryModalOpen: true,
    });
  }

  render() {
    console.log('rendering the challenge dashboard');
    const { classes } = this.props;

    const createQueryModal = (
      <VendorQuery getChallenges={() => this.getChallenges()} />
    );

    const editQueryModal = (
      <EditVendorQuery
        getChallenges={() => this.getChallenges()}
        challengeData={this.state.challenges[this.state.modalIndex]}
      />
    );

    const deleteQueryModal = (
      <DeleteVendorQuery
        getChallenges={() => this.getChallenges()}
        challengeData={this.state.challenges[this.state.modalIndex]}
      />
    );

    let createNewChallengeButton;

    const userRoles = this.props.user.user.roles.split(', ');

    if (
      userRoles.includes('Attendee') ||
      userRoles.includes('Pharma Attendee') ||
      userRoles.includes('Attendee Only')
    ) {
      createNewChallengeButton = (
        <Typography
          style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}
          variant="subtitle1"
          color="textPrimary"
        >
          <br />
          <Button
            className={classes.buttonLearn}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() =>
              this.setState({
                createQueryModalOpen: true,
              })
            }
          >
            Create New Challenge
          </Button>
          <br />
          <br />
        </Typography>
      );
    }

    return (
      <Grid
        container
        layout={'row'}
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              Vendor Challenge
            </Typography>
            <Typography
              style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}
              variant="subtitle1"
              color="textPrimary"
            >
              Discover and Engage Exhibitors
            </Typography>
            {createNewChallengeButton}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {this.state.challenges.map((challenge, index) => {
            if (this.state.preSelectedChallenge === challenge.vendor_query_id) {
              return (
                <ChallengeListItem
                  selected
                  challenge={challenge}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                  editQueryModalOpen={() => this.editQueryModalOpen(index)}
                  deleteQueryModalOpen={() => this.deleteQueryModalOpen(index)}
                />
              );
            } else {
              return (
                <ChallengeListItem
                  challenge={challenge}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                  editQueryModalOpen={() => this.editQueryModalOpen(index)}
                  deleteQueryModalOpen={() => this.deleteQueryModalOpen(index)}
                />
              );
            }
          })}
        </Grid>
        <Dialog
          open={this.state.createQueryModalOpen}
          handleClose={this.vendorQueryModalClose.bind(this)}
        >
          {createQueryModal}
        </Dialog>
        <Dialog
          open={this.state.editQueryModalOpen}
          handleClose={this.editQueryModalClose.bind(this)}
        >
          {editQueryModal}
        </Dialog>
        <Dialog
          open={this.state.deleteQueryModalOpen}
          handleClose={this.deleteQueryModalClose.bind(this)}
        >
          {deleteQueryModal}
        </Dialog>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    debug: state.debug,
    user: state.user,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(VendorChallenge));
