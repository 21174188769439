/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:2c99a103-2764-4e0d-a66c-327f185dd841', //prod: us-east-1:2c99a103-2764-4e0d-a66c-327f185dd841 test: us-east-1:cf1e5325-552c-4000-b508-8b8dc5729bd1
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_8rTzkYNvd', // prod: us-east-1_8rTzkYNvd test: us-east-1_rQyVfoO6K
  aws_user_pools_web_client_id: '1mtk3e59ak3l4j9n6fg9e285nd', //prod: 1mtk3e59ak3l4j9n6fg9e285nd  test: 1oruqpda9u0bs86gvbgs1entsu
  oauth: {
    domain: 'planetconnect.auth.us-east-1.amazoncognito.com',
    scope: [
      // "phone",
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://mts2022.planetconnect.com/registration', // https://mts2022.planetconnect.com/registration http://localhost:3000/registration/
    redirectSignOut: 'https://mts2022.planetconnect.com/registration', // https://mts2022.planetconnect.com/registration http://localhost:3000/registration/
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_login_mechanisms: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'planetconnectadminstorage104656-local', //prod: planetconnectadminstorage104656-local test: planetconnectadminstorage131452-ssotest
  aws_user_files_s3_bucket_region: 'us-east-1',
};

export default awsmobile;
