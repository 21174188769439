import { setOpenConversation } from '../Reducers/twilio';

export default function twilioTypingEnded(participant) {
  return (dispatch, getState) => {
    const openConversations = getState().twilio.openConversations;

    Object.keys(openConversations).forEach((page) => {
      if (
        openConversations[page]?.conversation?.client?.sid &&
        openConversations[page]?.conversation?.client?.sid ===
          participant?.conversation?.sid
      ) {
        const newTyping = { ...openConversations[page].typing };
        delete newTyping[participant.identity];
        const newOpenConversation = {
          ...openConversations[page],
          typing: newTyping,
        };
        console.log(
          '🚀 ~ file: twilioParticipantUpdated.js ~ line 21 ~ Object.keys ~ newOpenConversation',
          newOpenConversation
        );
        // const { messages, ...rest } = openConversations[page];
        // const items = [...messages.items];
        // const newOpenConversation = {
        //   ...rest,
        //   messages: { ...messages, items: [...items] },
        // };
        // newOpenConversation.messages.items.push(message);
        dispatch(
          setOpenConversation({ page, conversation: newOpenConversation })
        );
      }
    });
  };
}
