import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';
import isOnline from '../../Utilities/isOnline';
import { addTaskQueue } from '../Reducers/taskQueue';
import { queryApi } from '../../Services/queryApi';

export default function trackAction(event) {
  // console.log('🚀 ~ file: tracker.js ~ line 9 ~ trackAction ~ event', event);
  return async (dispatch, getState) => {
    const online = isOnline(getState());

    if (online) {
      const actionResult = await dispatch(
        queryApi.endpoints.trackAction.initiate({
          eventId: eventId,
          data: event,
        })
      );

      if (actionResult?.error?.data) {
        throw new Error(
          `${actionResult?.error?.data?.code}: ${actionResult?.error?.data?.message}`
        );
      }
    } else {
      dispatch(
        addTaskQueue({
          task: 'trackAction',
          payload: {
            eventId: eventId,
            data: event,
          },
          timeCreated: new Date(),
        })
      );
    }
  };
}

// const trackAction = (event) => {
//   Auth.currentSession()
//     .then((data) => {
//       axios({
//         method: 'post',
//         url: `/audit/${eventId}`,
//         headers: {
//           idtoken: data.idToken.jwtToken,
//         },
//         data: event,
//       })
//         .then((response) => {})
//         .catch((error) => {});
//     })
//     .catch((error) => {});
// };

// export default trackAction;
