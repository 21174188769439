import { getUsers, getConversationsClient } from '../Reducers/twilio';
import trackAction from './tracker';

export default function twilioSubscribeToIdentity(identity) {
  return async (dispatch, getState) => {
    const conversationsClient = getConversationsClient(getState());
    const users = getUsers(getState());

    if (
      conversationsClient &&
      (!users?.[identity] || !users[identity].isSubscribed)
    ) {
      const subscribedUser = await conversationsClient.getUser(identity);
      const event = {
        action: 'Added',
        resource_type: 'Connection',
        resource_id: subscribedUser?.attributes?.attendee_id,
        url_visited: null,
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };
      dispatch(trackAction(event));
      return subscribedUser;
    }
  };
}
