import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routeQRCode from '../Store/Actions/routeQRCode';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
} from '@material-ui/core';
import { Formik } from 'formik';
import { getUser, getAnonymous, setAnonymous } from '../Store/Reducers/user';

export default function ActionScreen() {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const anonymous = useSelector(getAnonymous);

  useEffect(() => {
    const redirectFunction = async () => {
      try {
        console.log('location', window.location);
        const successText = await dispatch(
          routeQRCode(history, window.location.href)
        );
        if (successText && successText !== 'LOGIN_ANONYMOUS') {
          history.push(`/scan?success_text=${encodeURIComponent(successText)}`);
        }
      } catch (err) {
        console.log(
          '🚀 ~ file: ActionScreen.js ~ line 18 ~ useEffect ~ err',
          err
        );
        history.push(`/scan?error_text=${encodeURIComponent(err.message)}`);
      }
    };
    redirectFunction();
  }, [anonymous]);
  const onSubmit = (values, { setSubmitting }) => {
    dispatch(setAnonymous(values));
  };
  const handleClose = () => {};

  return (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <br />
      <br />
      <CircularProgress color="inherit" />
      <Typography variant="h4" color="textPrimary">
        Loading...
      </Typography>
      {!anonymous && !user && (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown
          onBackdropClick={(reason) => {
            console.log(
              '🚀 ~ file: QRScanScreen.jsx ~ line 256 ~ QRScanScreen ~ reason',
              reason
            );
            return false;
          }}
        >
          <DialogTitle id="alert-dialog-title">Anonymous Voting</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please Enter the following information, this will be used to
              contact you if you win, but will not be shown publicly.
            </DialogContentText>
            <Formik
              initialValues={{ email: '', firstName: '', lastName: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                return errors;
              }}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      name="firstName"
                      onBlur={handleBlur}
                      value={values.firstName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.firstName}
                      label="First Name"
                      error={touched.firstName && errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                    />
                    <br />
                  </FormControl>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      name="lastName"
                      onBlur={handleBlur}
                      value={values.lastName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.lastName}
                      label="Last Name"
                      error={touched.lastName && errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                    />
                    <br />
                  </FormControl>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      type="email"
                      name="email"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.email}
                      label="Email"
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                  <DialogActions>
                    <FormControl style={styles.formControlButton}>
                      <Button
                        onClick={handleSubmit}
                        color="primary"
                        autoFocus
                        disabled={isSubmitting}
                      >
                        OK
                      </Button>
                    </FormControl>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
}

const styles = {
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    // margin: theme.spacing(2),
  },
};
