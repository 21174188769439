import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { eventId } from '../../config';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class CreateResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionText: '',
      submitted: false,
      validationError: false,
      error: false,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  questionTextUpdate(event) {
    this.setState({
      questionText: event.target.value,
    });
  }

  postResponse() {
    if (!this.state.questionText) {
      this.setState({
        validationError: true,
      });
    } else {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'post',
            url: `/vendors/response/${eventId}/${this.props.challenge.vendor_query_id}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              content: this.state.questionText,
            },
          }).then(
            (response) => {
              this.setState({
                submitted: true,
              });

              this.props.getResponses();
            },
            (error) => {
              this.setState({
                error: true,
              });
            }
          );
        })
        .catch((error) => {});
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.submitted) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Response submitted successfully!
          </Typography>
        </Box>
      );
    } else if (this.state.error) {
      return (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            There was an error with your submission. Please try again.
          </Typography>
        </Box>
      );
    }

    return (
      <React.Fragment>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Submit Challenge Response
          </Typography>
          <br />
          <br />
          <InputLabel id="demo-multiple-chip-label">
            Response Statement
          </InputLabel>
          <TextField
            placeholder="Example..."
            multiline
            rows={4}
            maxRows={10}
            id="outlined-basic"
            variant="outlined"
            onChange={(event) => this.questionTextUpdate(event)}
            error={
              this.state.validationError && !this.state.questionText
                ? true
                : false
            }
          />
          <br />
          <br />
          <br />
          <br />
          <Button
            className={classes.buttonLearn}
            variant="contained"
            size="medium"
            onClick={() => this.postResponse()}
          >
            Submit Response
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //   getChatMessages: getChatMessages,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CreateResponse));
