import { eventId } from '../../config';
import { queryApi } from '../../Services/queryApi';
import { setConversation, setOpenConversation } from '../Reducers/twilio';
import addBriefcaseElement from './addBriefcaseElement';
import trackAction from './tracker';
import { getUser, getAnonymous } from '../Reducers/user';
import isValidEmail from '../../Utilities/isValidEmail';

export default function actionScanPoll(pollId, answer, name, email) {
  return async (dispatch, getState) => {
    const user = getUser(getState());
    const anonymous = getAnonymous(getState());
    let email;

    const poll = await dispatch(queryApi.endpoints.getPoll.initiate(pollId));

    if (poll.data.allow_anonymous) {
      if (!anonymous?.email && !user?.email) {
        return 'LOGIN_ANONYMOUS';
      }
      email = user?.email || anonymous?.email;
    } else {
      if (!user?.email) {
        throw new Error('Error: Must be logged in to vote in this poll.');
      }
      email = user?.email;
    }

    if (!isValidEmail(email)) {
      throw new Error(`Error: User Email is not a valid email address.`);
    }

    const voteResults = await dispatch(
      queryApi.endpoints.voteInPoll.initiate({
        pollId: pollId,
        email: email,
        answer_id: answer,
        source: '',
      })
    );
    if (voteResults?.error?.data) {
      throw new Error(
        `${voteResults?.error?.data?.code}: ${voteResults?.error?.data?.message}`
      );
    }

    // const me = getState()?.user?.user;

    const event = {
      action: 'Voted',
      resource_type: 'Poll',
      resource_id: pollId,
      url_visited: '/scan',
      resource_account_id: user?.account_id || email,
      resource_account_name: user?.account_name || email,
      resource_name: email,
    };
    dispatch(trackAction(event));

    return 'Thank you for your vote.';
  };
}
