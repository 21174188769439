import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
// import momentTimezone from 'moment-timezone';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import trackAction from '../Store/Actions/tracker';
import prepareObject from '../Utilities/newObjectFromArrayFieldsSnackbar';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import removeSnackbar from '../Store/Actions/removeSnackbar';
import setSnackbar from '../Store/Actions/setSnackbar';
import { eventId } from '../config';
import { queryApi } from '../Services/queryApi';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    zIndex: '12',
    position: 'absolute',
  },
  snackbar: {
    backgroundColor: theme.palette.primary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
});

class CustomizedSnackbars extends Component {
  briefcaseSubscription;
  constructor(props) {
    super(props);
    this.state = {
      sponsorMessages: [],
      alreadyViewedMessages: [],
      open: false,
      id: null,
    };
  }

  componentDidMount() {
    this.startSnackbarCheck();
    this.briefcaseSubscription = this.props.getBriefcase(eventId);

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/banner/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {
            this.setState({
              sponsorMessages: [
                ...this.state.sponsorMessages,
                ...response.data,
              ],
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(
                `Error: Could not get the sponsor messages information. ${error}`
              );
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(
            `Error: Could not update the sponsor messages information. ${error}`
          );
        }
      });

    // this.getBriefcaseMessages()
  }

  componentWillUnmount() {
    if (this.briefcaseSubscription) this.briefcaseSubscription.unsubscribe();
  }

  navigate() {
    if (this.state.sponsorMessages[this.state.id].resource_id) {
      const event = {
        action: 'Clicked',
        resource_type: 'Notification',
        resource_id: this.state.sponsorMessages[this.state.id].resource_id,
        url_visited: '/',
        // user_account_id: this.props.user.user.account_id,
        // new fields
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
        // user_account_name: this.props.user.user.account_name,
        // user_email: this.props.user.user.email,
        // user_first_name: this.props.user.user.first_name,
        // user_last_name: this.props.user.user.last_name,
        // user_title: this.props.user.user.title,
        // user_department: this.props.user.user.department,
        // user_site: this.props.user.user.site
      };

      this.props.trackAction(event);
    }

    // presentation = presentation_id
    // exhibit = exhibition_id
    // poster = poster_id
    // product = product_id

    let route;
    let execute = true;
    let custom = false;

    switch (this.state.sponsorMessages[this.state.id].resource_type) {
      case 'custom':
        route = `${this.state.sponsorMessages[this.state.id].resource_id}`;
        execute = false;
        custom = true;
        break;
      case 'none':
        route = ``;
        execute = false;
        break;
      case 'agenda':
        route = `agenda/`;
        break;
      case 'presentation':
        route = `agenda/?presentation_id=${
          this.state.sponsorMessages[this.state.id].resource_id
        }`;
        break;
      case 'workshophall':
        route = `workshop/`;
        break;
      case 'workshop':
        route = `workshop/?workshop_id=${
          this.state.sponsorMessages[this.state.id].resource_id
        }`;
        break;
      case 'discussionforum':
        route = `discussionforum/`;
        break;
      case 'posterhall':
        route = `posterhall/`;
        break;
      case 'poster':
        route = `posterhall/?poster_id=${
          this.state.sponsorMessages[this.state.id].resource_id
        }`;
        break;
      case 'exhibithall':
        route = `exhibithall/`;
        break;
      case 'exhibit':
        route = `exhibithall/?exhibition_id=${
          this.state.sponsorMessages[this.state.id].resource_id
        }`;
        break;
      case 'product':
        route = `exhibithall/?product_id=${
          this.state.sponsorMessages[this.state.id].resource_id
        }`;
        break;
      case 'auditorium':
        route = `auditorium/`;
        break;
      case 'survey':
        route = `survey/`;
        break;
      default:
        route = `lobby/`;
    }

    if (custom) {
      window.open(route, '_blank');
    } else if (execute) {
      this.props.history.push(`/${route}`);
    }
    this.close();
  }

  changeSnackbar = () => {
    let now = moment().format();
    // now = '2020-12-16T09:05:00-05:00'
    let messageId = -1;

    //  if any of the banners are a manual override, show the first one returned
    for (let n = 0; n < this.state.sponsorMessages.length; n++) {
      if (this.state.sponsorMessages[n].manual_override) {
        messageId = n;
        break;
      }
    }

    // 2021-06-14T22:12:09-04:00
    // 2021-09-21T00:00:00.000Z

    // if there aren't and banners with a manual override, then show a banner if it's between its start and end time
    if (messageId === -1) {
      for (let n = 0; n < this.state.sponsorMessages.length; n++) {
        if (
          now > this.state.sponsorMessages[n].start_time &&
          now < this.state.sponsorMessages[n].end_time
        ) {
          messageId = n;
          break;
        }
      }
    }

    // if there is a banner where the current time is between its start and end time
    if (messageId > -1) {
      if (
        this.props.snackbar.id ===
          this.state.sponsorMessages[messageId].banner_id &&
        this.state.open
      ) {
        this.setState({
          id: messageId,
          open: false,
        });
      } else if (
        this.props.snackbar.id ===
        this.state.sponsorMessages[messageId].banner_id
      ) {
      } else if (!this.state.open) {
        this.setState({
          id: messageId,
          open: true,
        });
      } else if (this.state.id !== messageId) {
        this.setState({
          id: messageId,
          open: true,
        });
      }

      // if there is not a banner where the current time is between its start and end time
    } else {
      if (this.state.id) {
        this.setState({
          id: null,
          open: false,
        });
      }
    }
  };

  close() {
    this.props.removeSnackbar(
      this.state.sponsorMessages[this.state.id].banner_id
    );
    let clickedId = this.state.sponsorMessages[this.state.id].banner_id;
    let potentialMessages = this.state.sponsorMessages.filter(
      (message) => message.banner_id !== clickedId
    );
    this.setState(
      {
        id: null,
        open: false,
        sponsorMessages: [...potentialMessages],
      },
      () => this.changeSnackbar()
    );
  }

  startSnackbarCheck = () => {
    this.intervalID = setInterval(this.changeSnackbar, 60000); //check once a minute
  };

  stopSnackbarCheck = () => {
    clearInterval(this.intervalID);
  };

  componentWillUnmount() {
    this.stopSnackbarCheck();
  }

  getBriefcaseMessages = () => {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {
            let abstracts = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(abstract.date, 'YYYY/MM/DD').format();
              if (abstract.start_time) {
                let mdy = abstract.date.substr(0, abstract.date.indexOf('T'));
                let mdyStart = mdy + 'T' + abstract.start_time + '.000Z';
                let mdyEnd = mdy + 'T' + abstract.end_time + '.000Z';
                abstract.start_time = mdyStart;
                abstract.end_time = mdyEnd;
                abstracts.push(abstract);
              }
            }

            abstracts.sort((a, b) => {
              return (
                moment.duration(a.start_time).asMinutes() -
                moment.duration(b.start_time).asMinutes()
              );
            });

            let idArray = this.props.briefcase.data
              .filter((item) => {
                if (item.resource_type === 'Presentation') {
                  return true;
                } else {
                  return false;
                }
              })
              .map((item) => item.resource_id);

            let briefcaseElements = abstracts.filter((abstract) => {
              let briefcaseElement = null;
              idArray.map((id) => {
                if (id === abstract.abstract_id) {
                  briefcaseElement = abstract;
                  return abstract;
                }
                return abstract;
              });
              if (briefcaseElement) {
                return true;
              } else {
                return false;
              }
            });

            let comparisonArray = [
              'banner_id',
              'color',
              'end_time',
              'event_id',
              'manual_override',
              'resource_id',
              'resource_type',
              'start_time',
              'text_body',
            ];
            let bannerBriefcaseElements = briefcaseElements.map((element) => {
              return prepareObject(element, comparisonArray, 'presentation');
            });
            this.setState({
              sponsorMessages: [
                ...this.state.sponsorMessages,
                ...bannerBriefcaseElements,
              ],
            });
            this.changeSnackbar();
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(
                `Error: Agenda could not be displayed. Please reload the page and check your connection. If you continue to have problems, please reach out the the technical support team using the chat in the bottom right corner of the virtual environment.`
              );
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(
            `Error: Agenda could not be displayed. Please reload the page and check your connection. If you continue to have problems, please reach out the the technical support team using the chat in the bottom right corner of the virtual environment.`
          );
        }
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.open ? (
          <Snackbar open={this.state.open}>
            <MuiAlert
              className={classes.snackbar}
              elevation={6}
              variant="filled"
              style={{
                backgroundColor:
                  this.state.sponsorMessages[this.state.id].color,
              }}
            >
              <Grid
                container
                layout={'row'}
                spacing={3}
                className={classes.gridContainer}
              >
                <Grid item xs={10} className={classes.pointer}>
                  <Typography
                    variant="body1"
                    component="div"
                    onClick={() => this.navigate()}
                  >
                    {this.state.sponsorMessages[this.state.id].text_body}
                  </Typography>
                </Grid>
                <Grid item align="right" xs={2}>
                  <CloseIcon
                    onClick={() => this.close()}
                    className={classes.pointer}
                  />
                </Grid>
              </Grid>
            </MuiAlert>
          </Snackbar>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    snackbar: state.snackbar,
    user: state.user,
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    debug: state.debug,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSnackbar: setSnackbar,
      removeSnackbar: removeSnackbar,
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      trackAction: trackAction,

      // setDebug: setDebug,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(CustomizedSnackbars)));
