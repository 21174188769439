import {
  getConversationsClient,
  setCreatingConversation,
  setEngagement,
  setChatWindow,
  setOpenProfile,
  setConversation,
  storeConversation,
} from '../Reducers/twilio';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import getTwilioOneOnOneConversation from '../../Utilities/getTwilioOneOnOneConversation';
import twilioSubscribeToIdentity from '../Actions/twilioSubscribeToIdentity';
import { setOpenConversation } from '../Reducers/twilio';
import trackAction from './tracker';

/**
 *
 * @param {*} identityToInvite - twilio user identity
 * @returns
 */
export default function twilioCreateJoinOneOnOneConversation(identityToInvite) {
  return async (dispatch, getState) => {
    // Search Conversations to see if we already have a one on one conversation going

    const page = 'sidebar';

    const user = getState().user.user;

    const userTwilio = getState().twilio.user?.client;
    let otherUser = getState().twilio?.users?.[identityToInvite];

    if (!otherUser) {
      otherUser = await dispatch(twilioSubscribeToIdentity(identityToInvite));
    }
    // This user does not have a twilio id
    if (!otherUser) return null;

    // can't start a chat with ourselves
    if (userTwilio.identity === identityToInvite) return null;

    const haveIBlockedThisUser =
      userTwilio?.attributes?.blockList?.[identityToInvite];

    const haveIBeenBlocked =
      otherUser?.attributes?.blockList?.[userTwilio.identity];

    if (haveIBlockedThisUser || haveIBeenBlocked) {
      // console.log('opening profile');
      dispatch(setEngagement('profile'));
      dispatch(setOpenProfile(otherUser.client));
      return true;
    }

    const creatingConversation = getState().twilio.creatingConversation;
    const userIdentity = getTwilioUserIdentity(user);

    const client = getConversationsClient(getState());
    const inviteUser = await client?.getUser(identityToInvite);
    // console.log(
    //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 23 ~ return ~ inviteUser',
    //   inviteUser,
    //   inviteUser.attributes
    // );

    const conversations = getState().twilio.conversations;
    // console.log(
    //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 35 ~ return ~ conversations',
    //   conversations
    // );
    dispatch(setOpenConversation({ page, conversation: null }));

    let conversation = getTwilioOneOnOneConversation(
      conversations,
      userIdentity,
      identityToInvite
    );

    if (conversation) {
      dispatch(
        setOpenConversation({
          page,
          conversation: { sid: conversation['sid'] },
        })
      );
      // dispatch(twilioLoadOpenConversation({ page, conversation }));
      dispatch(setOpenProfile(null));
      dispatch(setEngagement('chat'));
      dispatch(setChatWindow(true));
      logJoinConversationEvent(conversation, otherUser, dispatch);
      return conversation;
    }
    if (!creatingConversation) {
      console.log('creating conversation');
      dispatch(setCreatingConversation(true));
      dispatch(setOpenProfile(null));
      dispatch(setEngagement('chat'));
      dispatch(setChatWindow(true));
      // create conversation
      const conversationClient = await client.createConversation({
        attributes: {
          identity: identityToInvite,
        },
        friendlyName: `${inviteUser?.attributes?.first_name} ${inviteUser?.attributes?.last_name}`,
      });

      // Add myself
      await conversationClient.add(userIdentity);
      // Add desired user
      await conversationClient.add(identityToInvite);
      const createdConversation = storeConversation(conversationClient);

      dispatch(setConversation(createdConversation));
      dispatch(setCreatingConversation(false));
      dispatch(
        setOpenConversation({
          page,
          conversation: { sid: createdConversation.sid },
        })
      );
      // dispatch(twilioLoadOpenConversation(conversation));
      // console.log(
      //   '🚀 ~ file: twilioCreateJoinOneOnOneConversation.js ~ line 94 ~ return ~ conversation',
      //   conversation
      // );
      // console.log('NEW CONVERSATION CREATED');
      logJoinConversationEvent(createdConversation, user, dispatch);

      return createdConversation;
    }
    console.log('cannot create more than one conversation at a time');
    return false;
  };
}

function logJoinConversationEvent(conversation, user, dispatch) {
  let event = {
    action: 'Follow',
    resource_type: 'IndividualChat',
    resource_id: conversation.sid,
    url_visited: '/engagementZone',
    resource_account_id: conversation.attributes.identity,
    resource_account_name: conversation.attributes.identity,
    resource_name: conversation.friendlyName,
  };

  dispatch(trackAction(event));

  event = {
    action: 'Added',
    resource_type: 'Contact',
    resource_id: user?.attendee_id || user?.attributes?.attendee_id,
    url_visited: null,
    resource_account_id: null,
    resource_account_name: null,
    resource_name: null,
  };
  dispatch(trackAction(event));
}
