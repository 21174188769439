export default function twilioLeave(conversationSid, participantSid) {
  return async (dispatch, getState) => {
    const conversation = getState().twilio.conversations[conversationSid];
    if (conversation) {
      console.log(`leaving ${conversation.friendlyName} (${conversation.sid})`);
      return conversation.client.leave();
    }

    // // Ask the backend for a twilio token
    // const data = await Auth.currentSession();
    // const response = await axios({
    //   method: 'post',
    //   url: `/twilio/leave`,
    //   headers: { idtoken: data.idToken.jwtToken },
    //   data: { conversationSid, participantSid },
    // });
    // console.log(
    //   '🚀 ~ file: twilioLeave.js ~ line 27 ~ .then ~ response',
    //   response
    // );
    // return response;

    // const event = getState().event;
  };
}
