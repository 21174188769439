import { createSlice } from '@reduxjs/toolkit';
import SavedTask from '../../Types/SavedTask';

interface TaskQueueProps {
  tasks: SavedTask[];
}
const initialState: TaskQueueProps = { tasks: [] };

export const taskQueueSlice = createSlice({
  name: 'taskQueue',
  initialState,
  reducers: {
    setTaskQueue: (state, action) => {
      state.tasks = action.payload;
    },
    addTaskQueue: (state, action) => {
      state.tasks = [...state.tasks, action.payload];
    },
    resetTaskQueue: (state, action) => {
      state.tasks = [];
    },
  },
});

// Selectors
export const getTaskQueue = (state) => state.taskQueue.tasks;

// each case under reducers becomes an action
export const { setTaskQueue, addTaskQueue, resetTaskQueue } =
  taskQueueSlice.actions;

export default taskQueueSlice.reducer;
