import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Auth } from 'aws-amplify';

import { eventId } from '../config';
import isAttendee from '../Utilities/isAttendee';
import backArrow from '../Assets/Icons/backArrow.png';
import ChatProfileRow from '../Components/ChatProfileRow';
import getLeadsArray from '../Store/Actions/getLeadsArray';
import sortParticipantsByLastName from '../Utilities/sortParticipantsByLastName';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setEngagement } from '../Store/Reducers/twilio';
// Redux

const useStyles = (theme) => ({});

const SidebarNewMessage = ({ setIsCreateNewMessage, page }) => {
  const dispatch = useDispatch();

  const event = useSelector((state) => state.event.event);
  let syncServiceSid = event.twilio_sync_sid;

  const user = useSelector((state) => state.user.user);
  const twilioUser = useSelector((state) => state.twilio.user);
  const attendee = isAttendee(twilioUser);

  const [text, setText] = useState('');
  const [users, setUsers] = useState(null);

  useEffect(() => {
    (async () => {
      if (isAttendee(twilioUser)) {
        const data = await Auth.currentSession();
        const response = await axios({
          method: 'get',
          url: `/show-attendees/directory?exhibitionId=${eventId}&syncServiceSid=${syncServiceSid}`,
          headers: { idtoken: data.idToken.jwtToken },
        });
        if (response.data.error) {
          throw new Error(response.data.error);
        }
        if (!response.data) {
          throw new Error('no directory');
        }

        let attendeesList = [];
        response.data.filter(function (item) {
          var i = attendeesList.findIndex((x) => x.email === item.email);
          if (i <= -1) {
            if (item.email !== user?.email) {
              attendeesList.push(item);
            }
          }
          return null;
        });
        attendeesList.sort(sortParticipantsByLastName({}));
        setUsers(attendeesList);
      } else {
        const { leadsArrayObjects } = await dispatch(getLeadsArray(user));
        // console.log(
        //   '🚀 ~ file: SidebarNewMessage.js ~ line 40 ~ leadsArrayObjects',
        //   leadsArrayObjects
        // );
        // normalize user objects
        const newUsers =
          leadsArrayObjects?.map(
            ({
              company,
              department,
              email,
              firstName,
              lastName,
              site,
              title,
            }) => ({
              company,
              department,
              email,
              first_name: firstName,
              last_name: lastName,
              site,
              title,
            })
          ) || [];
        newUsers.sort(sortParticipantsByLastName({}));
        setUsers(newUsers);
      }

      // attendees can access the directory
      // non attendees can access leads
    })();
  }, [twilioUser, page]);

  //   const openProfile = useSelector(getOpenProfile);
  const lowerCaseText = text.toLowerCase().trim();
  const filteredUsers = text
    ? users?.filter(
        (contact) =>
          user.email !== contact.email &&
          ((contact.first_name &&
            contact.first_name.toLowerCase().indexOf(lowerCaseText) !== -1) ||
            (contact.last_name &&
              contact.last_name.toLowerCase().indexOf(lowerCaseText) !== -1) ||
            (contact.email &&
              contact.email.toLowerCase().indexOf(lowerCaseText) !== -1))
      )
    : users?.filter((contact) => user.email !== contact.email);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        flex: 1,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        // backgroundColor: 'green',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          position: 'relative',
          padding: '10px 0px ',
          backgroundColor: 'white',
          boxShadow: '0 2px 15px -7px #000',
          // zIndex: 200000,
        }}
      >
        {page === 'sidebar' && (
          <a
            title={'Back'}
            onClick={() => dispatch(setEngagement('chat'))}
            style={{ position: 'absolute', left: 10, cursor: 'pointer' }}
          >
            <img src={backArrow} style={{ width: 12, height: 20 }} />
          </a>
        )}

        <div
          style={{
            width: '75%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 18,
          }}
        >
          New Message
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          position: 'relative',
          padding: '10px 0px ',
          backgroundColor: 'white',
          boxShadow: '0 4px 15px -7px #000',
          // zIndex: 100000,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            paddingLeft: 10,
            fontSize: 16,
          }}
        >
          To:
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <input
            type="text"
            placeholder="Write a contact name"
            onChange={(e) => {
              // tell the client the user is typing
              // it is debounded within the client
              // conversation.typing();
              // updateText(e.target.value);
              setText(e.target.value);
            }}
            value={text}
            style={{
              width: '90%',
              padding: 10,
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              fontSize: 16,
              border: '1px solid grey',
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          // height: 500,
          width: '100%',
          height: '100%',
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
          // backgroundColor: 'purple',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            padding: 5,
            paddingLeft: 20,
            fontWeight: 'bold',
            fontSize: 14,
            color: '#757575',
          }}
        >
          {attendee ? 'Directory' : 'Your Leads'}
        </div>
        <div
          style={{
            minHeight: 380,
            //  overflowY: 'auto', overflowX: 'hidden'
          }}
        >
          <div
            style={{
              minHeight: 380,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              // paddingLeft: 20,
            }}
          >
            {users === null && (
              <div
                style={{
                  minHeight: 380,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}
            {filteredUsers &&
              filteredUsers.map((contact) => {
                return (
                  <ChatProfileRow profileUser={contact} page={undefined} />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    position: 'fixed',
    right: 10,
    bottom: 60,
    height: 50,
    width: 300,
    borderRadius: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#122857',
    color: 'white',
    padding: 10,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  chatRoot: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    height: '100%',
  },
  header: {
    width: '100%',
    height: 50,
    backgroundColor: '#122857',
    color: 'white',
    display: 'flex',
    // justifyContent: 'center',
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'relative',
  },
  tabs: {
    margin: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginRight: 15,
    marginBottom: 0,
    borderColor: '#122857',
    cursor: 'pointer',
  },
  roomBanner: {
    width: '100%',
    // backgroundColor: 'gray',
    // color: 'white',
    padding: 5,
    paddingLeft: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatRoom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0 0 15px -7px #000',
  },
  circle: {
    borderRadius: 50,
    width: 40,
    height: 40,
    backgroundColor: '#122857',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
};

export default SidebarNewMessage;
