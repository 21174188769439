import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: true,
  chatOpen: false,
};
export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.open = action.payload;
    },
    setChatbarOpen: (state, action) => {
      state.chatOpen = action.payload;
    },
  },
});

// Selectors
export const getSidebar = (state) => state.sidebar.open;
export const getChatbar = (state) => state.sidebar.chatOpen;

// each case under reducers becomes an action
export const { setSidebarOpen, setChatbarOpen } = sidebarSlice.actions;

export default sidebarSlice.reducer;
