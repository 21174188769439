import React, { useState, useEffect } from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

export default function Reporting(props) {
  const event = useSelector((state) => state.event);
  const [attendeesCount, setAttendeesCount] = useState([]);
  const [registrantsCount, setRegistrantsCount] = useState([]);
  const [loading, setLoading] = useState([false]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const user = await Auth.currentSession();

        const metrics = await axios({
          method: 'GET',
          url: 'reporting/metrics/' + event.event.show_id,
          headers: {
            idtoken: user.idToken.jwtToken,
          },
        });

        setAttendeesCount([metrics.data.attendeesCount]);
        setRegistrantsCount([metrics.data.registrantsCount]);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    })();
  }, [event.event.show_id]);

  return loading ? (
    <CircularProgress />
  ) : (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Total Attendees: ${attendeesCount}`} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AccountBoxIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Total Registrants: ${registrantsCount}`} />
      </ListItem>
    </List>
  );
}
