import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { getChatWindow } from '../Store/Reducers/twilio';
import { useSelector } from 'react-redux';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { getUser } from '../Store/Reducers/user';

import './QRCodeScanner.css';
import { Button, Grid } from '@material-ui/core';

let proScanner;

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const QRCodeScanner = (props) => {
  const [state, setState] = useState(''); // can be '', 'granted', 'denied', or 'ask'
  const user = useSelector(getUser);
  const classes = useStyles();

  function createConfig() {
    var config = {};
    if (props.fps) {
      config.fps = props.fps;
    }
    if (props.qrbox) {
      config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
      config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
      config.disableFlip = props.disableFlip;
    }

    return config;
  }

  useEffect(() => {
    try {
      const qrCodeSuccessCallback = props.qrCodeSuccessCallback;

      const errorCallBack = (error) => {};
      if (state === '') {
        if (navigator?.permissions?.query) {
          navigator.permissions
            .query({ name: 'camera' })
            .then(function (permissionStatus) {
              setState(permissionStatus.state);
            });
        } else {
          setState('ask'); // safari does not have a navigator.permissions.query, so we always try and access the camera
        }
      } else if (state === 'ask' || state === 'granted') {
        Html5Qrcode.getCameras()
          .then(async (response) => {
            proScanner = new Html5Qrcode('reader');
            console.log(
              '🚀 ~ file: QRCodeScanner.js ~ line 56 ~ .then ~ proScanner',
              proScanner
            );

            await proScanner.start(
              { facingMode: 'environment' },
              createConfig(),
              qrCodeSuccessCallback,
              errorCallBack
            );

            // proScanner.videoElement.style['-webkit-transform'] = 'scaleX(-1)';
            // proScanner.videoElement.style['transform'] = 'scaleX(-1)';
          })
          .catch((err) => {
            // Permission Denied ErrorMessage
            console.log(
              '🚀 ~ file: QRCodeScanner.js ~ line 44 ~ Html5Qrcode.getCameras ~ err',
              err
            );
            if (err.includes('denied')) {
              // console.log('permission denied, redirect to error page');
              setState('denied');
            }
          });
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: QRCodeScanner.js ~ line 74 ~ useEffect create scanner ~ error',
        error
      );
    }
    return () => {
      try {
        if (proScanner) {
          // stop scanner on unmount
          return proScanner.stop();
        }
      } catch (e) {
        console.log('🚀 ~ file: QRCodeScanner.jsx ~ line 96 ~ return ~ e', e);
      }
    };
  }, [state]);

  const chatopen = useSelector(getChatWindow);
  const paused = chatopen || props.paused;

  useEffect(() => {
    try {
      if (proScanner) {
        if (paused) {
          proScanner.pause(true);
        } else {
          proScanner.resume();
        }
      }
    } catch (e) {
      console.log(
        '🚀 ~ file: QRCodeScanner.js ~ line 84 ~ useEffect proScanner Pausing ~ e',
        e
      );
    }
  }, [paused]);

  const handleAskForPermissions = () => {
    setState('ask');
  };

  if (state === 'prompt') {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h6" color="textPrimary">
          We would like to use your camera to scan QR Codes, please grant
          permission below.
        </Typography>
        <Button
          size="large"
          className={classes.button}
          onClick={handleAskForPermissions}
        >
          grant permission
        </Button>
      </div>
    );
  }
  if (state === 'denied') {
    return (
      <Grid item xs={12}>
        <Typography style={styles.instructionsContainer}>
          <Typography variant="h4" color="textPrimary">
            Camera Permission Denied
          </Typography>
          <Typography variant="h6" color="textPrimary">
            To turn on camera permission:
          </Typography>
          <ul>
            <li>Go to settings.</li>
            <li>
              Click on Privacy and security &gt; Site settings &gt; Camera
            </li>
            <li>
              Select the option you want as your default setting:
              <ul>
                <li>Review your blocked and allowed sites.</li>
                <li>
                  To remove an existing exception or permission: To the right of
                  the site, click Delete.
                </li>
                <li>
                  To allow a site that you already blocked: Under "Not allowed,"
                  select the site's name and change the camera or microphone
                  permission to "Allow."
                </li>
              </ul>
            </li>
            <li>Refresh Page.</li>
          </ul>
        </Typography>
      </Grid>
    );
  }
  return (
    <div>
      <div style={styles.scannerWrapper}>
        <div id="reader" style={{}} />
        <div
          id="my-shaded-region"
          style={{
            width: !user && 250,
            height: !user && 250,
          }}
        >
          <div
            id="qr-scanning-border"
            className={paused ? 'paused' : 'un-paused'}
          ></div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  scannerWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
  },
};

export default QRCodeScanner;
