import { eventId } from '../../config';
import { queryApi } from '../../Services/queryApi';
import { setEventElementDetails } from '../Reducers/event';

export default function setEventDetails() {
  return (dispatch) => {
    dispatch(queryApi.endpoints.getEventDetails.initiate(eventId)).then(
      (response) => {
        // console.log(
        //   '🚀 ~ file: setEventDetails.js ~ line 17 ~ dispatch ~ response',
        //   response
        // );
        dispatch(setEventElementDetails(response.data));
      }
    );
  };
}
