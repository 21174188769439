import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  exhibits: [],
  selectedExhibitId: null,
};

export const exhibitSlice = createSlice({
  name: 'exhibit',
  initialState,
  reducers: {
    setExhibits: (state, action) => {
      state.exhibits = action.payload;
    },
    resetExhibits: (state, action) => {
      state.exhibits = [];
      state.selectedExhibitId = null;
    },
    setSelectedExhibit: (state, action) => {
      state.selectedExhibitId = action.payload;
    },
  },
});

// Selectors
export const getExhibits = (state) => state.exhibit.exhibits;
export const getSelectedExhibit = (state) => state.exhibit.selectedExhibitId;

// each case under reducers becomes an action
export const { setExhibits, setSelectedExhibit } = exhibitSlice.actions;

export default exhibitSlice.reducer;
