import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Img } from 'react-image';

import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  dropZone: {
    overflow: 'auto',
    padding: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderStyle: 'dotted',
    borderColor: 'grey',
  },
  dropButton: {
    // color: 'blue',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export function FileUploader(props) {
  const classes = useStyles();

  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        // console.log(binaryStr);
        // setPreview(binaryStr);

        acceptedFiles[0].preview = binaryStr;
        setUploadedFile(acceptedFiles[0]);
        props.handleFileUpload(acceptedFiles[0]);
        // const results = await dispatch(
        //   uploadImageAsync({
        //     companyId,
        //     id,
        //     type,
        //     file: acceptedFiles[0],
        //   })
        // );
        // onSave(results);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      {/* <input type="file" name="file" onChange={handleChangeStatus}/> */}

      <Dropzone
        onDrop={onDrop}
        maxFiles={1}
        accept={{
          'image/*': ['.png', '.jpg'],
        }}
      >
        {({
          getRootProps,
          getInputProps,
          isFocused,
          isDragAccept,
          isDragReject,
        }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{ position: 'relative' }}>
                <div
                  className={classes.dropZone}
                  style={{
                    backgroundColor: isDragAccept
                      ? 'rgba(0,255,0,0.1)'
                      : isDragReject
                      ? 'rgba(255,0,0,0.1)'
                      : isFocused
                      ? 'rgba(0,0,255,0.1)'
                      : 'white',
                  }}
                >
                  {uploadedFile?.preview ? (
                    <Img src={uploadedFile?.preview} width="100%" />
                  ) : (
                    <Button
                      className={classes.dropButton}
                      color="primary"
                      // variant="link"
                    >
                      Drag and Drop File
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}
