import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { GridList, GridListTile } from '@material-ui/core/';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import ArtFull from './ArtFull';
import Filter from './ArtFilter';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router';
import trackAction from '../../Store/Actions/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },

  modalImage: {
    position: 'relative',
    maxWidth: '58vw',
    maxHeight: '52vh',
    margin: 'auto',
  },
});

class ScienceAsArt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageWidth: window.innerWidth,
      imagesArray: [],
      fullScreen: false,
      selectedArt: null,
      filterString: '',
      filterCategory: 'presenter_first_name',
      pageCountStart: 1,
      perPage: 10,
      totalPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'art_id') {
          this.setState({
            fullScreen: true,
            selectedArt: param[1],
          });
        }
      }
    }

    this.getArt();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'Art',
      resource_id: null,
      url_visited: '/art',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  getArt() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: '/arts',
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            first_row: this.state.pageCountStart,
            rows_per_page: this.state.perPage,
            search_columns: this.state.filterCategory,
            search_value: this.state.filterString,
          },
        })
          .then(
            (response) => {
              this.setState({
                imagesArray: response.data.data,
                totalPages: Math.ceil(
                  response.data.recordsFiltered / this.state.perPage
                ),
              });
            },
            (error) => {
              if (this.props.debug.debug) {
                alert(`Error: Could not display Science as Art. ${error}`);
              }
            }
          )
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Science as Art. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Science as Art. ${error}`);
        }
      });

    window.addEventListener('resize', this.resizeWindow);
  }

  toggleImageFullscreenOn(abstract_id) {
    this.setState({
      fullScreen: true,
      selectedArt: abstract_id,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  resizeWindow = () => {
    this.setState({
      pageWidth: window.innerWidth,
    });
  };

  toggleImageFullscreenOff = () => {
    this.setState({
      fullScreen: false,
      selectedArt: null,
    });
  };

  handleFilterClick = (string, category) => {
    this.setState({
      filterString: string,
      filterCategory: category,
      pageCountStart: 1,
      page: 1,
    });
    this.getArt();
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
      pageCountStart: 1,
      page: 1,
    });
    this.getArt();
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - 9,
    });

    this.getArt();
  };

  render() {
    const { classes } = this.props;

    let pageBody;

    // For Filtering Later

    // let imagesArray;
    // if (this.state.filterString && this.state.filterCategory) {
    //   imagesArray = this.state.imagesArray.filter(image => {
    //     return (image[this.state.filterCategory].toLowerCase().includes(this.state.filterString.toLowerCase()))
    //   })
    // } else {
    //   imagesArray = this.state.imagesArray
    // }

    if (this.state.fullScreen) {
      pageBody = (
        <ArtFull
          abstractId={this.state.selectedArt}
          toggleImageFullscreenOff={this.toggleImageFullscreenOff.bind(this)}
        />
      );
    } else {
      pageBody = (
        <Grid className={classes.root} container spacing={2}>
          <Filter
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
          />
          <Grid item xs={12}>
            <GridList
              cellHeight={this.state.pageWidth / 4}
              cols={2}
              spacing={2}
            >
              {this.state.imagesArray.map((image, index) => (
                <GridListTile
                  key={index}
                  cols={image.featured ? 2 : 1}
                  rows={1}
                  onClick={(event) =>
                    this.toggleImageFullscreenOn(image.abstract_id)
                  }
                >
                  <img src={image.supporting_file_url} alt="art" />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
          <Grid key="pagination" item xs={12}>
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
            />
          </Grid>
        </Grid>
      );
    }

    return pageBody;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    debug: state.debug,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ScienceAsArt)));
