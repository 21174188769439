import {
  storeConversation,
  setConversation,
  setConversationMessages,
  storeMessages,
} from '../Reducers/twilio';

export default function twilioPreloadConversation(conversation) {
  return async (dispatch, getState) => {
    const user = getState().twilio.user;
    // make sure participants are loaded so we can save them in redux
    await conversation.getParticipants();

    if (!conversation.uniqueName?.startsWith('GROUP_')) {
      // Load the last message for non GROUP conversations
      const lastMessages = await conversation.getMessages(1);
      dispatch(
        setConversationMessages({
          sid: conversation.sid,
          messages: storeMessages(lastMessages),
        })
      );
    }
    const newConversation = storeConversation(conversation, user);
    dispatch(setConversation(newConversation));
  };
}
