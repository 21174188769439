import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  event: {},
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEventElementDetails: (state, action) => {
      state.event = action.payload;
    },
    removeEvent: (state, action) => {
      const newEvent = {
        main_color: state.event?.main_color,
        light_color: state.event?.light_color,
        favicon_location: state.event?.favicon_location,
        banner_location: state.event?.banner_location,
        landing_page_content: state.event?.landing_page_content,
        title: state.event?.title,
        under_construction: state.event?.under_construction,
        allowed_registration_domains: state.event?.allowed_registration_domains,
        allowed_signin_domains: state.event?.allowed_signin_domains,
      };
      state.event = newEvent;
    },
  },
});

// Selectors
export const getEvent = (state) => state.event.event;

// each case under reducers becomes an action
export const { setEvent, removeEvent, setEventElementDetails } =
  eventSlice.actions;

export default eventSlice.reducer;
