const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  // newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }
  return newUrl;
};

export default getValidUrl;
