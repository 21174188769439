import { getUsers } from '../Reducers/twilio';
import { SyncClient, SyncDocument } from 'twilio-sync';
import { getSyncClient } from '../Reducers/twilio';

export default function twilioRemoveHereNow(uniqueName) {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    const users = getUsers(getState());

    const syncClient: SyncClient = getSyncClient(getState());
    const hereNowClient: SyncDocument = await syncClient.document(
      `HERE_NOW_${uniqueName}`
    );
    const hereNow = hereNowClient.data || {};

    if (user?.email && hereNowClient) {
      const newHereNow = { ...hereNow };
      // loop over everyone here
      Object.keys(newHereNow).forEach((key, value) => {
        // offline must be false
        if (users?.[key]?.isOnline === false) {
          console.log(`found an offline user ${key}`);
          delete newHereNow[key];
        }
      });

      delete newHereNow[user.email];

      console.log('REMOVED newHereNowRoom', newHereNow);
      await hereNowClient.set(newHereNow);
    }
  };
}
