import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import { eventId } from '../../config';
import isOnline from '../../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';
import trackAction from '../../Store/Actions/tracker';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  grid: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '50%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  own: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '5px',
    marginBottom: '3px',
    borderRadius: '15px',
    maxWidth: '100%',
  },
  img: {
    borderRadius: '50%',
    maxWidth: '100%',
  },
  other: {},
  expansionPanelMain: { padding: '0px 8px 0px 8px' },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerCompany: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  selected: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
  },
  selectedText: {
    color: '#FFF',
  },
});

class BoothAbstracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
    };
  }

  componentDidMount = () => {
    this.props.getExhibitActivites();
  };

  navigate(route, type, resourceId) {
    this.props.history.push(`/${route}?${type}=${resourceId}`);
  }

  recordEvent = () => {
    const event = {
      action: 'Clicked',
      resource_type: 'Activities / Exhibit',
      resource_id: this.props.exhibit.exhibition_id,
      url_visited: '/exhibithall',
      resource_account_id: this.props.exhibit.account_id,
      resource_account_name: this.props.exhibit.name,
      resource_name: this.props.exhibit.account_alias
        ? this.props.exhibit.account_alias
        : this.props.exhibit.title,
    };
    this.props.trackAction(event);
  };

  render() {
    const { classes } = this.props;
    const online = this.props.online;

    const abstracts = this.props.abstracts?.data?.map((abstract, index) => {
      let favorited = false;

      if (this.props.briefcase.data) {
        for (let n = 0; n < this.props.briefcase.data.length; n++) {
          if (
            this.props.briefcase.data[n].resource_id === abstract.abstract_id
          ) {
            favorited = true;
            break;
          }
        }
      }

      let selectedClassCheck =
        this.props.selectedProductId === abstract.abstract_id
          ? classes.selected
          : null;
      let selectedClassCheckText =
        this.props.selectedProductId === abstract.abstract_id
          ? classes.selectedText
          : null;

      let listAbstract;

      switch (abstract.accepted_type) {
        case 'Poster':
          listAbstract = (
            <Grid item xs={12} key={index} className={classes.grid}>
              <Accordion key={index} className={classes.root}>
                <AccordionSummary
                  className={
                    classes.expansionPanelMain + ' ' + selectedClassCheck
                  }
                  expandIcon={<ExpandMoreIcon />}
                  id={index}
                >
                  <Grid
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      <Tooltip
                        title={
                          !online && (
                            <Typography variant="subtitle2">
                              User is Offline. Cannot perform action.
                            </Typography>
                          )
                        }
                      >
                        {favorited ? (
                          <IconButton
                            aria-label="remove from briefcase"
                            onClick={(event) => {
                              if (online) {
                                event.stopPropagation();

                                this.props.deleteBriefcaseElement(
                                  abstract.abstract_id
                                );
                              }
                            }}
                          >
                            <WorkIcon className={selectedClassCheckText} />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="add to briefcase"
                            onClick={(event) => {
                              if (online) {
                                event.stopPropagation();

                                this.props.addBriefcaseElement(
                                  'Poster',
                                  abstract.abstract_id,
                                  abstract.account_name
                                );
                              }
                            }}
                          >
                            <WorkOutlineIcon
                              className={selectedClassCheckText}
                            />
                          </IconButton>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid item xs={11} sm={12} md={3} lg={3}>
                      <Typography
                        className={
                          classes.secondaryHeading +
                          ' ' +
                          selectedClassCheckText
                        }
                      >
                        {abstract.accepted_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={11} md={8} lg={8}>
                      <Typography className={classes.heading}>
                        {abstract.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} className={classes.grid}>
                      {/* <Typography variant="h6" align='center' color='textPrimary'>
                           {abstract.title}
                        </Typography> */}
                      <Divider
                        className={classes.divider}
                        variant="fullWidth"
                      />
                      <br />
                      {/* all of this typography below is linked to presenter data that isn't present */}
                      <Typography
                        className={classes.lineSpeakerName}
                        variant="h6"
                      >
                        {abstract.presenter_first_name ? (
                          <React.Fragment>
                            {abstract.presenter_first_name +
                              ' ' +
                              abstract.presenter_last_name}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_title
                          ? abstract.presenter_title
                          : ''}
                      </Typography>
                      <Typography className={classes.lineSpeakerCompany}>
                        {abstract.presenter_company
                          ? abstract.presenter_company
                          : ''}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_email ? (
                          <>
                            <b>Email:</b> {abstract.presenter_email}
                          </>
                        ) : (
                          ''
                        )}
                      </Typography>
                      {abstract.start_time && abstract.date ? (
                        <Typography variant="body1" align="center">
                          {`Starts at ${moment(abstract.start_time).format(
                            'h:mm a'
                          )} on  ${moment(
                            abstract.date.substring(0, 10)
                          ).format('dddd, MMMM Do')}`}
                        </Typography>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <br />
                      <FormControl className={classes.formControlButton}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => {
                            this.recordEvent();
                            this.navigate(
                              'posterhall/',
                              'poster_id',
                              abstract.abstract_id
                            );
                          }}
                        >
                          Go To Poster
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
          break;
        default:
          listAbstract = (
            <Grid item xs={12} key={index} className={classes.grid}>
              <Accordion key={index} className={classes.root}>
                <AccordionSummary
                  className={
                    classes.expansionPanelMain + ' ' + selectedClassCheck
                  }
                  expandIcon={<ExpandMoreIcon />}
                  id={index}
                >
                  <Grid
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      <Tooltip
                        title={
                          !online && (
                            <Typography variant="subtitle2">
                              User is Offline. Cannot perform action.
                            </Typography>
                          )
                        }
                      >
                        {favorited ? (
                          <IconButton
                            aria-label="remove from briefcase"
                            onClick={(event) => {
                              if (online) {
                                event.stopPropagation();

                                this.props.deleteBriefcaseElement(
                                  abstract.abstract_id
                                );
                              }
                            }}
                          >
                            <WorkIcon className={selectedClassCheckText} />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="add to briefcase"
                            onClick={(event) => {
                              if (online) {
                                event.stopPropagation();

                                this.props.addBriefcaseElement(
                                  'Presentation',
                                  abstract.abstract_id,
                                  abstract.account_name
                                );
                              }
                            }}
                          >
                            <WorkOutlineIcon
                              className={selectedClassCheckText}
                            />
                          </IconButton>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid item xs={11} sm={12} md={3} lg={3}>
                      <Typography
                        className={
                          classes.secondaryHeading +
                          ' ' +
                          selectedClassCheckText
                        }
                      >
                        {abstract.accepted_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={11} md={8} lg={8}>
                      <Typography className={classes.heading}>
                        {abstract.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} className={classes.grid}>
                      {/* <Typography variant="h6" align='center' color='textPrimary'>
                             {abstract.title}
                          </Typography> */}
                      <Divider
                        className={classes.divider}
                        variant="fullWidth"
                      />
                      <br />
                      {/* all of this typography below is linked to presenter data that isn't present */}
                      <Typography
                        className={classes.lineSpeakerName}
                        variant="h6"
                      >
                        {abstract.presenter_first_name ? (
                          <React.Fragment>
                            {abstract.presenter_first_name +
                              ' ' +
                              abstract.presenter_last_name}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_title
                          ? abstract.presenter_title
                          : ''}
                      </Typography>
                      <Typography className={classes.lineSpeakerCompany}>
                        {abstract.presenter_company
                          ? abstract.presenter_company
                          : ''}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_email ? (
                          <>
                            <b>Email:</b> {abstract.presenter_email}
                          </>
                        ) : (
                          ''
                        )}
                      </Typography>
                      <br />
                      {abstract.start_time && abstract.date ? (
                        <Typography variant="body1" align="center">
                          {`Starts at ${moment(abstract.start_time).format(
                            'h:mm a'
                          )} on  ${moment(
                            abstract.date.substring(0, 10)
                          ).format('dddd, MMMM Do')}`}
                        </Typography>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <br />
                      <FormControl className={classes.formControlButton}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => {
                            this.recordEvent();
                            this.navigate(
                              'agenda/',
                              'presentation_id',
                              abstract.abstract_id
                            );
                          }}
                        >
                          Go to Presentation
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
      }

      return listAbstract;
    });

    return (
      <div className={classes.grid}>
        <Grid container layout={'row'} justifyContent="center" spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div" align="center">
              Come See Us Throughout the Event!
            </Typography>
            <br />
          </Grid>
          {abstracts}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.user,
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    debug: state.debug,
    // abstracts: queryApi.endpoints.getExhibitActivites.select({
    //   eventId: eventId,
    //   exhibitionId: props.exhibit.exhibition_id,
    // })(state),
    abstracts:
      state.queryApi.queries?.[
        `getExhibitActivites({"eventId":"${eventId}","exhibitionId":"${props.exhibit.exhibition_id}"})`
      ],
    online: isOnline(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      getExhibitActivites: () =>
        queryApi.endpoints.getExhibitActivites.initiate({
          eventId: eventId,
          exhibitionId: props.exhibit.exhibition_id,
        }),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(BoothAbstracts)));
