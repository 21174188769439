import axios from 'axios';
import { eventId } from '../../config';
import { setStrapi } from '../Reducers/strapi';

export default function setStrapiElements() {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `https://cms.planetconnect.com/api/shows?filters[show_id][$eq]=${eventId}`,
      headers: { Authorization: '' },
    }).then((response) => {
      let strapiMenu = response.data.data[0].attributes.menu.data.attributes;

      strapiMenu.menu_items.map((item) => {
        item.pages = item.pages.data;
        item.pages = item.pages.map((page) => {
          page = page.attributes;
          page.visitor_roles = page.visitor_roles.data;
          page.visitor_roles = page.visitor_roles.map((role) => {
            role = role.attributes.Role;
            return role;
          });
          return page;
        });
        return item;
      });
      // console.log(strapiMenu);
      dispatch(setStrapi(strapiMenu));
    });
  };
}
