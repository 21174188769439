import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({});

class SimpleButton extends Component {
  render() {
    return (
      <Button {...this.props} className={this.props.className}>
        {this.props.children}
      </Button>
    );
  }
}

export default withStyles(useStyles)(SimpleButton);
